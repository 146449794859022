import {
  Certificate,
  Result,
  ReconsentResult,
  Terms,
  TermsDetail,
  Intro,
} from "@pages/hanaCapitalTerms";

export const hanacapitalRouters = [
  {
    path: "intro",
    Component: Intro,
  },
  {
    path: "terms",
    Component: Terms,
  },
  {
    path: "terms-detail",
    Component: TermsDetail,
  },
  {
    path: "certificate",
    Component: Certificate,
  },
  {
    path: "reconsent-result",
    Component: ReconsentResult,
  },
  {
    path: "result",
    Component: Result,
  },
];
