export const BankCode = {
  KB: "004",
  WOORI: "020",
  KEB: "081",
  SHINHAN: "088",
  SC: "023",
  NH: "011",
  KAKAO: "090",
  TOSS: "092",
  IBK: "003",
  KFCC: "045",
  BNK_BUSAN: "032",
  DGB: "031",
  KBANK: "089",
  CU: "048",
  EPOST: "071",
  BNK_KN: "039",
  KJ: "034",
  SH: "007",
  JB: "037",
  FSB: "050",
  JEJU: "035",
  CITI: "027",
  KDB: "002",
  SJ: "064",
  BOA: "060",
  HSBC: "054",
  DOICH: "055",
  JP: "057",
  // BOK: "001",
  // KEB_2: "005",
  // KEXIM: "008",
  // LNH: "012",
};
export type BankCodeTypes = (typeof BankCode)[keyof typeof BankCode];

export const BankNames: { [key in BankCodeTypes]: string } = {
  [BankCode.KB]: "KB국민은행",
  [BankCode.WOORI]: "우리은행",
  [BankCode.KEB]: "하나은행",
  [BankCode.SHINHAN]: "신한은행",
  [BankCode.SC]: "SC제일은행",
  [BankCode.NH]: "NH농협은행",
  [BankCode.KAKAO]: "카카오뱅크",
  [BankCode.TOSS]: "토스뱅크",
  [BankCode.IBK]: "IBK기업은행",
  [BankCode.KFCC]: "새마을금고",
  [BankCode.BNK_BUSAN]: "부산은행",
  [BankCode.DGB]: "대구은행",
  [BankCode.KBANK]: "케이뱅크",
  [BankCode.CU]: "신협",
  [BankCode.EPOST]: "우체국",
  [BankCode.BNK_KN]: "경남은행",
  [BankCode.KJ]: "광주은행",
  [BankCode.SH]: "수협은행",
  [BankCode.JB]: "전북은행",
  [BankCode.FSB]: "저축은행",
  [BankCode.JEJU]: "제주은행",
  [BankCode.CITI]: "씨티은행",
  [BankCode.KDB]: "KDB산업은행",
  [BankCode.SJ]: "산림조합",
  [BankCode.BOA]: "BOA",
  // +) 중국은행 063
  [BankCode.HSBC]: "HSBC",
  // +) 중국공상은행 062
  [BankCode.DOICH]: "도이치은행",
  [BankCode.JP]: "JP모건",
  // +) BNP파리바
  // +) 중국건설은행

  // [BankCodeTypes.KEB_2]: "외환은행", // 하나 은행과 합병되어 더 이상 사용하지 않음. 이전에 계좌를 등록한 유저는 존재.
  // [BankCodeTypes.BOK]: "한국은행", // 아예 사용하지 않음.
  // [BankCodeTypes.KEXIM]: "한국수출입은행", // 아예 사용하지 않음.
  // [BankCodeTypes.LNH]: "지역농축협", // 이전에 계좌를 등록한 유저가 있어서 삭제할 수 없음. 지금 보여주고 있지는 않음.
};
export type BankNamesTypes = (typeof BankNames)[keyof typeof BankNames];
