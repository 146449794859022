import GuestRouteGuard from "../guards/GuestRouteGuard";
import React from "react";
import { SystemError } from "@pages/errors";
import Login from "@pages/Login";

export const guestRouters = {
  path: "/",
  errorElement: <SystemError />,
  element: <GuestRouteGuard />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
  ],
};
