import { useState } from "react";

import { ImageDocumentsTypes } from "@earlypay/shared";
import styled from "styled-components";

import { CameraIcon, XIcon } from "@earlybird/icons";
import { Box, Icon, Image, ImageUploader, Text, VStack } from "@earlybird/ui";

interface CardTerminalUploaderProps {
  cardTerminalImages: ImageDocumentsTypes[];
  handleSaveImage: (image: File) => void;
  handleDeleteImage: (id: number) => void;
}

export const CardTerminalUploader = ({
  cardTerminalImages,
  handleDeleteImage,
  handleSaveImage,
}: CardTerminalUploaderProps) => {
  const [visibleImageUploader, setVisibleImageUploader] = useState(false);

  return (
    <VStack>
      <GridContainer>
        <UploadImageBox onClick={() => setVisibleImageUploader(true)}>
          <Icon size="xl" icon={CameraIcon} color={"content-secondary"} />
          <Text typo={"body-3"} bold color={"content-secondary"}>
            사진 첨부하기
          </Text>
        </UploadImageBox>

        {cardTerminalImages?.map(
          ({ id, image }: ImageDocumentsTypes, index: number) => {
            return (
              <ImageWrapper key={index} radius={"lg"}>
                <Image
                  width={"100%"}
                  height={"100%"}
                  url={image}
                  objectFit={"cover"}
                />
                <DeleteButton onClick={() => handleDeleteImage(id)}>
                  <Icon size="xs" icon={XIcon} color={"content-on-color"} />
                </DeleteButton>
              </ImageWrapper>
            );
          },
        )}
      </GridContainer>

      <ImageUploader
        visible={visibleImageUploader}
        onClose={() => setVisibleImageUploader(false)}
        guide={"card-terminal"}
        handleSaveImage={handleSaveImage}
      />
    </VStack>
  );
};

export default CardTerminalUploader;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--earlybird-spacing-4);
  box-sizing: border-box;
  overflow: hidden;
`;

const UploadImageBox = styled.button`
  border-radius: var(--earlybird-radius-2xl);
  border: 1px solid var(--earlybird-color-border-primary);
  background-color: var(--earlybird-color-bg-primary);
  aspect-ratio: 1;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled(Box)`
  aspect-ratio: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
`;

const DeleteButton = styled.button`
  border-radius: var(--earlybird-radius-circle);
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--earlybird-color-bg-negative);
  width: 24px;
  height: 24px;
  z-index: 10;
`;
