import { A4_PER, MAX_CONTENT_WIDTH } from "@earlypay/shared/common";
import { useWindowSize } from "@earlypay/shared/hooks";
import { VStack } from "@ui/components/atoms";
import {
  addUserInfoToTerms,
  getS3TermsFile,
} from "@ui/components/organisms/TermsPage/utils";
import "@ui/styles/index.scss";
import { generateArray } from "@ui/utils/array";
import classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import { Document, Page, pdfjs } from "react-pdf";
import styles from "./TermsPage.module.scss";
import { TermsPageProps } from "./TermsPage.types";

import "react-loading-skeleton/dist/skeleton.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

/**
 * `TermsPage` 는 은행을 선택하는 데에 사용하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <TermsPage
 *   filename={"EL01"}
 * />
 * ```
 */
export const TermsPage = forwardRef<HTMLElement, TermsPageProps>(
  function TermsPage(
    {
      className,
      as,
      sum,
      filename,
      signature,
      username,
      businessLicense,
      date,
      ...rest
    }: TermsPageProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const windowSize = useWindowSize();
    const [htmlContent, setHtmlContent] = useState("");
    const [numPages, setNumPages] = useState(1);
    const BaseComponent = as ?? "div";

    const pdfUrl = getS3TermsFile(`${filename}.pdf`);
    const htmlUrl = getS3TermsFile(`${sum ? sum : filename}.html`);

    const file = useMemo(() => ({ pdfUrl }), [pdfUrl]);
    const updatedHtmlContent = addUserInfoToTerms(
      username,
      date,
      signature,
      businessLicense,
      htmlContent,
    );

    if (typeof Promise.withResolvers === "undefined") {
      if (window) {
        window.Promise.withResolvers = function <T>() {
          let resolve: (value: T | PromiseLike<T>) => void;
          let reject: (reason?: any) => void;
          const promise = new Promise<T>((res, rej) => {
            resolve = res;
            reject = rej;
          });
          return { promise, resolve, reject };
        };
      } else {
        global.Promise.withResolvers = function <T>() {
          let resolve: (value: T | PromiseLike<T>) => void;
          let reject: (reason?: any) => void;
          const promise = new Promise<T>((res, rej) => {
            resolve = res;
            reject = rej;
          });
          return { promise, resolve, reject };
        };
      }
    }

    const PAGE_MARGIN = 8; // 페이지 여백
    const screenWidth =
      windowSize.width > MAX_CONTENT_WIDTH
        ? MAX_CONTENT_WIDTH
        : windowSize.width;
    const contentWidth = screenWidth - PAGE_MARGIN * 2;
    const contentHeight = contentWidth * A4_PER;

    const fetchHtml = useCallback(async () => {
      try {
        const response = await fetch(htmlUrl);
        const text = await response.text();
        setHtmlContent(text);
      } catch (error) {
        console.error("Error fetching HTML:", error);
      }
    }, [htmlUrl]);

    /** S3 버킷에서 약관 .html 파일을 가져옵니다. */
    useEffect(() => {
      fetchHtml();
    }, [fetchHtml, filename]);

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        id={"html-content"}
        className={classNames(
          styles.TermsPage,
          "earlybird-terms-page",
          className,
        )}
      >
        <Document
          file={file.pdfUrl}
          noData={<></>}
          loading={
            <div
              className={classNames(styles.LoadingTerms)}
              style={{ width: contentWidth, height: contentHeight }}
            >
              <div className={classNames(styles.LoadingSection)}>
                <Skeleton
                  width={"100%"}
                  height={36}
                  containerClassName="flex-1"
                />
                {generateArray(3).map((number: number) => (
                  <Skeleton
                    key={number}
                    width={"100%"}
                    height={14}
                    containerClassName="flex-1"
                  />
                ))}
              </div>
              <div className={classNames(styles.LoadingSection)}>
                <Skeleton
                  width={"100%"}
                  height={14}
                  containerClassName="flex-1"
                />
                <Skeleton
                  width={"70%"}
                  height={14}
                  containerClassName="flex-1"
                />
              </div>
            </div>
          }
          onLoadSuccess={({ numPages: numPagesInPdf }) => {
            setNumPages(numPagesInPdf);
          }}
          onError={(error) => console.error("error", error)}
          className={classNames(styles["Document"])}
        >
          <VStack spacing={4} width={"100%"}>
            {generateArray(numPages).map((page, index) => (
              <div key={index} className={classNames(styles.A4Page, "a4-page")}>
                <Page
                  className="pdf__page"
                  pageNumber={page}
                  width={contentWidth}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </div>
            ))}
          </VStack>
        </Document>

        {/** html2canvas 를 통해 html 파일을 pdf 로 변환 */}
        <div className={classNames(styles.Hidden)}>
          <div
            id={sum ? `terms-page-${sum}` : `terms-page-${filename}`}
            dangerouslySetInnerHTML={{ __html: updatedHtmlContent }}
          />
        </div>
      </BaseComponent>
    );
  },
);

export default TermsPage;
