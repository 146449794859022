import React from "react";

import ReactDOM from "react-dom/client";
import { pdfjs } from "react-pdf";

import App from "./App";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
