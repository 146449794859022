export const guideData = {
  "business-license": [
    {
      title: "접히거나 훼손된 경우",
      image: "/service/business-registration-guide-1.png",
    },
    {
      title: "직사광선 또는\n빛 반사가 심한 경우",
      image: "/service/business-registration-guide-2.png",
    },
    {
      title: "모니터 혹은 모바일 화면\n촬영하는 경우",
      image: "/service/business-registration-guide-3.png",
    },
    {
      title: "문서와 구분되는\n어두운 배경에서 촬영",
      image: "/service/business-registration-guide-4.png",
    },
  ],
  identification: [
    {
      title: "대각선으로 촬영된 경우",
      image: "/service/identification-guide-1.png",
    },
    {
      title: "직사광선 또는 빛 반사가 심한 경우",
      image: "/service/identification-guide-2.png",
    },
    {
      title: "모니터 혹은 모바일 화면\n촬영하는 경우",
      image: "/service/identification-guide-3.png",
    },
    {
      title: "어두운 배경에서\n가로로 촬영된 신분증 사진",
      image: "/service/identification-guide-4.png",
    },
  ],
  "card-terminal": [
    {
      title: "사진과 같이 VAN사 로고가 보이도록 찍어주세요",
      image: "/service/card-terminal-guide.png",
    },
  ],
};
