import React, { useEffect } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

interface MetaPropsType {
  title?: string;
  name?: string;
}

const Meta = ({ title, name }: MetaPropsType) => {
  const location = useLocation();

  useEffect(() => {
    trackPageViewMixpanel(name);
  }, [location]);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default Meta;
