/**
 * 기기별 현재 유저 에이전트를 불러옵니다.
 * @returns {string} android | iphone | window
 */
export const checkMobile = (): "android" | "iphone" | "window" => {
  const device = navigator.userAgent.toLowerCase();

  if (device.indexOf("android") > -1) {
    return "android";
  } else if (
    device.indexOf("iphone") > -1 ||
    device.indexOf("ipad") > -1 ||
    device.indexOf("ipod") > -1
  ) {
    return "iphone";
  } else {
    return "window";
  }
};

/**
 * 브라우저별 유저 에이전트 분기점
 * @returns  {string} chrome | safari
 */
export const checkBrowser = (): string => {
  const browser = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

  if (browser.indexOf("chrome") > -1 || browser.indexOf("crios") > -1) {
    return "chrome";
  } else {
    return "safari";
  }
};
