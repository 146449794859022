import { ServerError } from "@earlypay/shared";
import { sentryHook } from "@earlypay/shared/configs";
import { useMutation } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";

import { usersQueryKeys } from "@apis/hooks/users/queryKeys";

import { getUsersToken } from "@/apis";
import { applicationState } from "@/recoil/applications/atoms";

export const useRedirectUsers = (userId: number) => {
  const application = useRecoilValue(applicationState);

  return useMutation({
    mutationKey: usersQueryKeys.fetchUsersToken().queryKey,
    mutationFn: async (pathname: string) => await getUsersToken(userId),
    onSuccess: (
      data: { ok: boolean; accessToken: string },
      variables: string,
    ) => {
      const pathname = `${
        import.meta.env.VITE_EARLYPAY_API_URL
      }/v2/users/redirect`;
      const params = {
        url: `${import.meta.env.VITE_EARLYPAY_BASE_URL}${variables}`,
        token: data.accessToken,
      };
      const queryString = new URLSearchParams(params).toString();

      window.location.href = `${pathname}?${queryString}`;
    },
    onError: (error: ServerError) => {
      sentryHook({
        application: application,
        title: "리디렉트에 필요한 토큰 발급에 실패하였습니다.",
        error: error,
      });
    },
    retry: 0,
  });
};
