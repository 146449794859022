import { ChevronRightIcon } from "@earlybird/icons";
import { DeliveryNames } from "@earlypay/shared/typings";
import { Box, HStack, Icon, Text, VStack } from "@ui/components/atoms";
import "@ui/styles/index.scss";
import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";
import styles from "./DeliveryAccountViewList.module.scss";
import {
  DeliveryAccountViewListProps,
  PlatformListTypes,
} from "./DeliveryAccountViewList.types";

/**
 * `DeliveryAccountViewList` 는 등록된 배달앱 계정을 보여주는 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <DeliveryAccountViewList
 * />
 * ```
 */
export const DeliveryAccountViewList = forwardRef<
  HTMLElement,
  DeliveryAccountViewListProps
>(function DeliveryAccountViewList(
  {
    className,
    as,
    platformList,
    handleClickPlatform,
    ...rest
  }: DeliveryAccountViewListProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "button";

  return (
    <VStack spacing={4}>
      {platformList.map((item: PlatformListTypes) => (
        <VStack key={item.code}>
          <BaseComponent
            className={classNames(
              styles.DeliveryAccountViewList,
              styles[`status-${item.status}`],
              "earlybird-delivery-account-view-list",
            )}
            disabled={item.status === "DISABLED" || item.status === "SUSPENDED"}
            onClick={() => handleClickPlatform(item.code)}
            type={"button"}
          >
            <Text
              color={
                item.status === "DISABLED" || item.status === "SUSPENDED"
                  ? "content-disabled"
                  : "content-primary"
              }
              bold
            >
              {DeliveryNames[item.code]}
            </Text>

            <HStack align={"center"} width={"fit-content"}>
              <Text
                color={
                  item.status === "DISABLED" || item.status === "SUSPENDED"
                    ? "content-disabled"
                    : "content-highlight"
                }
                bold
              >
                {item.value}
              </Text>
              <Icon
                size={"lg"}
                color={"content-tertiary"}
                icon={ChevronRightIcon}
              />
            </HStack>
          </BaseComponent>

          {item.status !== "VALID" && (
            <Box padding={"0 0 0 12px"}>
              {item.status === "DISABLED" || item.status === "SUSPENDED" ? (
                <Text type={"body-3"} color={"content-tertiary"} bold>
                  요청에 의해 비활성화되어 선정산이 중단된 상태예요.
                </Text>
              ) : (
                <Text type={"body-3"} color={"content-negative"} bold>
                  바뀐 비밀번호를 업데이트해 주세요.
                </Text>
              )}
            </Box>
          )}
        </VStack>
      ))}
    </VStack>
  );
});

export default DeliveryAccountViewList;
