import React, { useRef } from "react";
import useIsVisibleElement from "./useIsVisibleElement";

export const useLazyImageLoader = <T extends HTMLElement>(
  elementRef: React.RefObject<T>,
  options?: IntersectionObserverInit
) => {
  const isLoaded = useRef(false);
  const isVisible = useIsVisibleElement(elementRef, options);

  if (isVisible) isLoaded.current = true;
  return isLoaded.current;
};
