import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { modalCode } from "@earlypay/shared/typings";

import {
  ActionButton,
  Box,
  LottieAnimation,
  PageContainer,
  Text,
  useConfirmModal,
} from "@earlybird/ui";

import { Header } from "@components/layouts";
import Meta from "@components/layouts/Meta";

export const Retry = () => {
  const confirmModal = useConfirmModal();

  const handleRetryApplication = () => {
    confirmModal.openConfirmModal({ id: modalCode.ERROR_RETRY_SERVICE_APPLY });
  };

  return (
    <>
      <Meta
        title={"이페이론 | 서비스 신청"}
        name={"심사 결과 | 신용 거절 (24시간 이후)"}
      />
      <Header hasLeftButton={false} />
      <PageContainer style={{ alignItems: "center" }}>
        <Text typo={"subtitle-1"} bold center>
          {"신용에 좋은 변화가 생겼다면\n다시 신청해보세요!"}
        </Text>
        <Box center width={"100%"}>
          <LottieAnimation src={"possible"} width={240} height={240} />
        </Box>
        <Text color={"content-secondary"} center>
          {
            "처음 가입하셨을 때보다 신용점수가 올랐거나,\n연체 또는 미납, 압류, 파산 등의 기록이\n삭제되었다면 언제든지 다시 신청할 수 있어요."
          }
        </Text>
      </PageContainer>

      <ActionButton
        buttonType={"single"}
        primaryButtonLabel={"다시 신청하기"}
        onClickPrimaryButton={handleRetryApplication}
        primaryButtonProperty={{
          description: "신용 거절 24시간 후 | 다시 신청하기 버튼",
        }}
      />
    </>
  );
};

export default Retry;
