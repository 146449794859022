import React from "react";
import MainLayout from "@routes/layouts/MainLayout";
import ProtectedMainRoute from "@routes/utils/ProtectedMainRoute";
import InitializeServiceRoute from "@routes/utils/InitializeServiceRoute";

const MainRouteGuard: React.FC = () => {
  return (
    <InitializeServiceRoute>
      <ProtectedMainRoute>
        <MainLayout />
      </ProtectedMainRoute>
    </InitializeServiceRoute>
  );
};

export default MainRouteGuard;
