import { instance } from "@/apis";

/**
 * 리디렉트에 필요한 JWT 토큰을 발급하는 API 함수입니다.
 */
export const getUsersToken = async <T>(userId: number): Promise<T | null> => {
  const { data } = await instance({
    method: "get",
    url: `v2/users/${userId}/token`,
    params: { userId },
  });

  return data;
};
