import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./AgreementList.module.scss";
import { AgreementListProps } from "./AgreementList.types";
import "@ui/styles/index.scss";
import { Icon, Text, VStack } from "@ui/components/atoms";
import { termsNames } from "@earlypay/shared/typings";
import { ChevronRightIcon } from "@earlybird/icons";

/**
 * `AgreementList` 는 사진 촬영 또는 불러오기를 실행하고 첨부 가이드를 같이 제공하여 필요한 이미지를 첨부하도록 유도하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <AgreementList
 * />
 * ```
 */
export const AgreementList = forwardRef<HTMLElement, AgreementListProps>(
  function AgreementList(
    {
      className,
      as,
      terms,
      title,
      handleClickTerm,
      ...rest
    }: AgreementListProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.AgreementList,
          "earlybird-agreement-list",
          className,
        )}
      >
        <VStack spacing={3}>
          <Text typo={"body-3"} color={"content-quaternary"}>
            {title}
          </Text>

          {terms.map((code, index) => (
            <button
              key={index}
              className={classNames(styles.AgreementBox)}
              onClick={() => handleClickTerm(code)}
            >
              <Text typo={"body-3"} color={"content-secondary"}>
                {termsNames[code]}
              </Text>
              <Icon icon={ChevronRightIcon} color={"content-secondary"} />
            </button>
          ))}
        </VStack>
      </BaseComponent>
    );
  },
);

export default AgreementList;
