import React from "react";
import styled from "styled-components";
import { BottomSheet, HStack, Image, Text, VStack } from "@earlybird/ui";
import { guides } from "@components/organisms/KnBankMobileCopyGuide/dataSetArray";

interface KnBankMobileCopyGuideProps {
  onClose: () => void;
  visible: boolean;
}

export const KnBankMobileCopyGuide = ({
  onClose,
  visible,
}: KnBankMobileCopyGuideProps) => {
  return (
    <BottomSheet
      headerType={"headline"}
      title={"모바일 통장 사본 이렇게 받으세요!"}
      onClose={onClose}
      visible={visible}
      buttonType={"single"}
      primaryButtonLabel={"확인했어요"}
      onClickPrimaryButton={onClose}
    >
      <VStack spacing={16}>
        {guides.map(
          (
            { title, image }: { title: string; image: string },
            index: number,
          ) => (
            <VStack spacing={8} key={index}>
              <HStack spacing={4} align={"center"}>
                <Circle>
                  <Text typo={"body-1"} bold color={"content-tertiary"}>
                    {index + 1}
                  </Text>
                </Circle>
                <Text typo={"body-1"} bold color={"content-secondary"}>
                  {title}
                </Text>
              </HStack>
              <Image src={image} width={260} height={224} />
            </VStack>
          ),
        )}
      </VStack>
    </BottomSheet>
  );
};

export default KnBankMobileCopyGuide;

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--earlybird-color-bg-tertiary);
`;
