import React from "react";

import Landing from "@pages/Landing";
import { Forbidden, NotFound, SystemError } from "@pages/errors";

import PublicRouteGuard from "../guards/PublicRouteGuard";

export const publicRouters = {
  path: "/",
  errorElement: <SystemError />,
  element: <PublicRouteGuard />,
  children: [
    {
      path: "landing",
      element: <Landing />,
    },
    // Error boundary
    {
      path: "403",
      element: <Forbidden />,
    },
    {
      path: "500",
      element: <SystemError />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};
