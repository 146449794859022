import React from "react";

import { appStatusState } from "@recoil/appStatus/atoms";
import { applicationState } from "@recoil/applications/atoms";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Container, ErrorScreen, PageContainer } from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

const NotFound = () => {
  const application = useRecoilValue(applicationState);
  const appStatus = useRecoilValue(appStatusState);
  const navigate = useNavigate();
  const status = !application?.id
    ? null
    : application.status === "APPROVED"
    ? "early-paid"
    : "service-apply";

  const handleGoToPrevious = () => {
    navigate(-1);
  };

  const handleClickActionButton = () => {
    navigate("/");
  };

  return (
    <Container>
      <Meta title={"NotFound"} />
      <Header hasLeftButton={false} hasRightButton={appStatus.isLoggedIn} />
      <PageContainer>
        <ErrorScreen
          isLoggedIn={!!application?.id}
          status={status}
          type={"404"}
          handleGoToPrevious={handleGoToPrevious}
          handleClickActionButton={handleClickActionButton}
        />
      </PageContainer>
    </Container>
  );
};

export default NotFound;
