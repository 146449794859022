import { Outlet } from "react-router-dom";
import React from "react";
import { Container, ToastProvider, ConfirmModalProvider } from "@earlybird/ui";

export const MainLayout: React.FC = () => {
  return (
    <Container>
      <ToastProvider>
        <ConfirmModalProvider>
          <Outlet />
        </ConfirmModalProvider>
      </ToastProvider>
    </Container>
  );
};

export default MainLayout;
