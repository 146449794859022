const getTag = (value: unknown): string =>
  Object.prototype.toString.call(value);

export function isNil(value: unknown): value is null | undefined {
  return value === null;
}

export function isArray(value: unknown): value is any[] {
  return Array.isArray(value);
}

export function isFunction(value: unknown): value is (...args: any[]) => any {
  return typeof value === "function";
}

export function isObject(value: unknown): value is object {
  const type = typeof value;
  return value != null && (type === "object" || type === "function");
}

export function isNumber(value: unknown): value is number {
  return (
    typeof value === "number" ||
    (isObject(value) && getTag(value) === "[object Number]")
  );
}

export function isSymbol(value: unknown): value is symbol {
  return (
    typeof value === "symbol" ||
    (isObject(value) && getTag(value) === "[object Symbol]")
  );
}

export function isEmpty(value: unknown): boolean {
  if (value === null) {
    return true;
  }

  if (Array.isArray(value) || typeof value === "string") {
    return value.length === 0;
  }

  if (value && typeof value === "object") {
    return Object.keys(value).length === 0;
  }

  return false;
}

export function getPixelValue(value: unknown): string {
  return typeof value === "string" ? value : `${value}px`;
}

export function findKeyByValue(obj: unknown, value: unknown) {
  const index = Object.values(obj).indexOf(value);
  return index !== -1 ? Object.keys(obj)[index] : null;
}

export function noop() {}
