import { deleteCookie, resetMixpanel } from "@earlypay/shared";

import useNavigateWithParams from "@hooks/useNavigateWithParams";

import { changeToken } from "@utils/changeToken";

export const useLogin = () => {
  const { navigateWithParams } = useNavigateWithParams();

  const generateRedirectUrl = (next?: string): string => {
    const searchParams = new URLSearchParams(location.search);
    const baseUrl = import.meta.env.VITE_EARLY_BASE_URL;
    const nextUrl = searchParams.get("nextUrl") ?? "/";

    searchParams.delete("nextUrl");

    const additionalParams = searchParams.toString();

    let link = `${baseUrl}${nextUrl}`;

    const queryParams: string[] = [];
    if (additionalParams) queryParams.push(additionalParams);

    if (queryParams.length > 0) {
      link += `?${queryParams.join("&")}`;
    }

    return `redirectUrl=${link}`;
  };

  const getKakaoLoginUrl = (nextUrl?: string) => {
    const apiUrl = import.meta.env.VITE_EARLY_API;
    const url = new URL("https://kauth.kakao.com/oauth/authorize");
    const queryParams: Record<string, string> = {
      client_id: `${import.meta.env.VITE_KAKAO_CLIENT_KEY}`,
      redirect_uri: `${apiUrl}/v2/users/kakao-login`,
      response_type: "code",
      prompt: "select_account",
      state: `${generateRedirectUrl(nextUrl)}`,
    };
    Object.keys(queryParams).forEach(key =>
      url.searchParams.append(key, queryParams[key]),
    );

    return url.toString();
  };

  const clearLoginInfo = () => {
    localStorage.clear();
    changeToken("");
    deleteCookie("EARLYPAY_STORE_ID", "/", ".epayloan.kr");
    deleteCookie("kakao-token", "/", ".epayloan.kr");
    deleteCookie("earlypay-token", "/", ".epayloan.kr");
    deleteCookie("earlypay-token", "/", ".earlypay.kr");
    deleteCookie("users-id", "/", ".epayloan.kr");
  };

  const handleLogout = () => {
    clearLoginInfo();
    resetMixpanel();
    window.location.href = `${import.meta.env.VITE_EARLYPAY_BASE_URL}/logout`;
  };

  return { getKakaoLoginUrl, handleLogout, clearLoginInfo };
};
