import { ErrorResponse, ServerError } from "../typings";
import { AxiosError } from "axios";

export const getErrorResponse = (error: ServerError) => {
  const errorData = error?.response?.data;
  const errorResponse =
    typeof errorData === "string" ? undefined : (errorData as ErrorResponse);

  return errorResponse;
};

/**
 * 얼리페이 에러메시지를 핸들링하는 함수입니다.
 * 에러 객체를 받아서 내부 개발자가 확인할 수 있는 에러 메세지로 반환합니다.
 * @param error 에러 객체
 * @returns 해당에러 상세메시지
 */
export const handleServerErrorMessage = (error: AxiosError): string => {
  const errorData = error?.response?.data;
  const errorResponse =
    typeof errorData === "string" ? undefined : (errorData as ErrorResponse);

  if (!errorResponse) {
    return error.message;
  }

  if ("ok" in errorResponse && errorResponse.ok === false) {
    if ("error" in errorResponse) {
      return errorResponse.error.message;
    } else {
      return errorResponse.message;
    }
  }

  if (
    "errors" in errorResponse &&
    "status_code" in errorResponse &&
    Array.isArray(errorResponse.errors.non_field_errors)
  ) {
    return errorResponse.errors.non_field_errors[0];
  }

  if (
    "errors" in errorResponse &&
    "status" in errorResponse &&
    Array.isArray(errorResponse.errors)
  ) {
    return errorResponse.errors[0].message;
  }

  return error.message;
};

/**
 * 얼리페이 에러코드를 핸들링하는 함수입니다.
 * 에러 객체를 받아서 내부 개발자가 확인할 수 있는 커스텀 에러 코드를 반환합니다.
 * @param error 에러 객체
 * @returns 해당 에러 코드
 */
export const handleServerErrorCode = (error: AxiosError): string => {
  const errorData = error?.response?.data;
  const errorResponse =
    typeof errorData === "string" ? undefined : (errorData as ErrorResponse);

  if (!errorResponse) {
    return "unknown";
  }

  if ("ok" in errorResponse && errorResponse.ok === false) {
    if ("error" in errorResponse) {
      return errorResponse.error.code;
    } else {
      return errorResponse.code;
    }
  }

  return "unknown";
};
