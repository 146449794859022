export const guides = [
  {
    title: "경남은행 앱 홈에서 점 3개 버튼 클릭",
    image: "/service/kn-bank-guide-1.png",
  },
  { title: "[통장사본보기] 클릭", image: "/service/kn-bank-guide-2.png" },
  {
    title: "[저장] 클릭 후, 사진 불러오기로 첨부",
    image: "/service/kn-bank-guide-3.png",
  },
];
