import { ADMIN_SERVICE_APPLICATION } from "@shared/common";
import { SalesTypeCodeTypes, SalesTypeNames } from "@shared/typings";
import { UserGroups } from "@shared/configs/services/Slack/userGroups";

export const getRetryServiceApplicationMessage = (
  id: number,
  name: string,
  store: string,
  salesType: SalesTypeCodeTypes,
  utmSource: string,
) => {
  const isProduction = import.meta.env.VITE_ENV === "production";

  const data = {
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*<${ADMIN_SERVICE_APPLICATION}/${id}/change/|재신청 요청이 접수되었습니다.>* ${isProduction ? `<!subteam^${UserGroups["OPERATION"]}>` : `(테스트)`}`,
        },
      },
      {
        type: "section",
        fields: [
          {
            type: "mrkdwn",
            text: `*이름*\n${name}`,
          },
          {
            type: "mrkdwn",
            text: `*상호*\n${store}`,
          },
          {
            type: "mrkdwn",
            text: `*매출 유형*\n${SalesTypeNames[salesType]}`,
          },
          {
            type: "mrkdwn",
            text: `*유입*\n${utmSource}`,
          },
        ],
      },
    ],
  };

  return data;
};
