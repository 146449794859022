import { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./ActionButton.module.scss";
import { ActionButtonProps } from "./ActionButton.types";
import "@ui/styles/index.scss";
import {
  BoxButton,
  BoxButtonHierarchyTypes,
  HStack,
  VStack,
} from "@ui/components/atoms";
import { useWindowSize } from "@earlypay/shared/hooks";
import { MAX_CONTENT_WIDTH } from "@earlypay/shared/common";

/**
 * `ActionButton` 는 다양한 레이아웃과 스타일 속성을 쉽게 적용하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <ActionButton
 *   buttonType="single"
 *   primaryButtonLabel="Click Me"
 *   onClickPrimaryButton={onClick}
 *   loading={false}
 * />
 * ```
 */
export const ActionButton = forwardRef<HTMLElement, ActionButtonProps>(
  function ActionButton(
    {
      className,
      as,
      buttonType = "single",
      primaryButtonLabel,
      disabledPrimaryButton,
      onClickPrimaryButton,
      secondaryButtonLabel,
      disabledSecondaryButton,
      onClickSecondaryButton,
      loading,
      accent = false,
      type = "submit",
      bg = "bg-primary",
      primaryButtonProperty,
      secondaryButtonProperty,
      visible = true,
      ...rest
    }: ActionButtonProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";
    const windowSize = useWindowSize();

    const PrimaryButton = () => (
      <BoxButton
        type={type}
        size={"lg"}
        disabled={disabledPrimaryButton}
        onClick={onClickPrimaryButton}
        property={primaryButtonProperty}
      >
        {primaryButtonLabel}
      </BoxButton>
    );

    const SecondaryButton = ({
      hierarchy,
    }: {
      hierarchy: BoxButtonHierarchyTypes;
    }) => (
      <BoxButton
        size={"lg"}
        hierarchy={hierarchy}
        disabled={disabledSecondaryButton}
        onClick={onClickSecondaryButton}
        property={secondaryButtonProperty}
      >
        {secondaryButtonLabel}
      </BoxButton>
    );

    const RenderedButton = () => {
      if (buttonType === "duo-horizontal") {
        return (
          <HStack spacing={4}>
            <SecondaryButton hierarchy={"secondary"} />
            <PrimaryButton />
          </HStack>
        );
      }

      if (buttonType === "duo-vertical") {
        return (
          <VStack spacing={accent ? 4 : 0}>
            <PrimaryButton />
            <SecondaryButton hierarchy={accent ? "secondary" : "ghost"} />
          </VStack>
        );
      }

      return <PrimaryButton />;
    };

    const RenderedLoadingButton = () => {
      return <BoxButton size={"lg"} loading></BoxButton>;
    };

    return (
      <div
        className={classNames(
          styles.Container,
          visible ? styles[`visible`] : styles["invisible"],
        )}
      >
        <div
          className={classNames(
            styles.Phantom,
            styles[buttonType],
            visible ? styles[`visible`] : styles["invisible"],
            className,
          )}
          style={{
            width:
              windowSize.width > MAX_CONTENT_WIDTH
                ? MAX_CONTENT_WIDTH
                : windowSize.width,
          }}
        />
        <BaseComponent
          {...rest}
          ref={forwardedRef}
          className={classNames(
            styles.ActionButton,
            visible ? styles[`visible`] : styles["invisible"],
            "earlybird-action-button",
            className,
          )}
        >
          {loading ? <RenderedLoadingButton /> : <RenderedButton />}
        </BaseComponent>
      </div>
    );
  },
);

export default ActionButton;
