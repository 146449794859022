import React, { ForwardedRef, forwardRef } from "react";
import { BottomSheet, HStack, Icon, Text, VStack } from "@ui/components/atoms";
import classNames from "classnames";
import styles from "./ImageMethodSelector.module.scss";
import { CameraIcon, PictureIcon } from "@earlybird/icons";
import { ImageMethodSelectorProps } from "@ui/components/organisms/ImageMethodSelector/ImageMethodSelector.types";

/**
 * `ImageMethodSelector` 는 이미지 첨부 방법을 촬영 또는 불러오기로 선택할 수 있는 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <ImageMethodSelector
 *   visible={true}
 *   onClose={onClose}
 *   handleClickMethod={handleClickMethod}
 * />
 * ```
 */
export const ImageMethodSelector = forwardRef<
  HTMLElement,
  ImageMethodSelectorProps
>(function ImageMethodSelector(
  { visible, onClose, handleClickMethod }: ImageMethodSelectorProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  return (
    <BottomSheet
      ref={forwardedRef}
      headerType={"headline"}
      title={"첨부 방법 선택"}
      visible={visible}
      onClose={onClose}
    >
      <VStack spacing={8}>
        <HStack spacing={8}>
          <button
            className={classNames(styles.ImageMethodSelector)}
            onClick={() => handleClickMethod("camera")}
          >
            <Icon icon={CameraIcon} size={"3xl"} color={"content-secondary"} />
            <Text color={"content-secondary"} bold>
              사진 촬영
            </Text>
          </button>
          <button
            className={classNames(styles.ImageMethodSelector)}
            onClick={() => handleClickMethod("gallery")}
          >
            <Icon icon={PictureIcon} size={"3xl"} color={"content-secondary"} />
            <Text color={"content-secondary"} bold>
              사진 불러오기
            </Text>
          </button>
        </HStack>
      </VStack>
    </BottomSheet>
  );
});

export default ImageMethodSelector;
