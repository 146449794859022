import React from "react";

import { SystemError } from "@pages/errors";
import { Gateway } from "@pages/serviceApply";
import { HanaCapitalPrivateRoute } from "@routes/guards/HanaCapitalPrivateRoute";
import MainRouteGuard from "@routes/guards/ProtectedRouteGaurd";
import ServiceApplyRouteGuard from "@routes/guards/ServiceApplyRouteGuard";
import { hanacapitalRouters } from "@routes/routers/protectedRouters/hanacapitalRouters";
import { serviceApplyRouters } from "@routes/routers/protectedRouters/serviceApplyRouters";

import Admin from "src/pages/Admin";

export const protectedRouters = {
  path: "/",
  element: <MainRouteGuard />,
  errorElement: <SystemError />,
  children: [
    {
      index: true,
      element: <Gateway />,
    },
    {
      path: "service-apply",
      element: <ServiceApplyRouteGuard />,
      children: serviceApplyRouters,
    },
    {
      path: "hana-capital",
      element: <HanaCapitalPrivateRoute />,
      children: hanacapitalRouters,
    },
    {
      path: "admin",
      Component: Admin,
    },
  ],
};
