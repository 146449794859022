import { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./PageModal.module.scss";
import { PageModalProps } from "./PageModal.types";
import "@ui/styles/index.scss";

/**
 * `PageModal` 는 페이지 형태의 화면을 채우는 모달 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <PageModal
 *   visible={visible}
 *   onClose={onClose}
 * >
 * 타이틀
 * </PageModal>
 * ```
 */
export const PageModal = forwardRef<HTMLElement, PageModalProps>(
  function PageModal(
    {
      className,
      as,
      children,
      visible,
      align = "start",
      justify = "start",
      center,
      style,
      ...rest
    }: PageModalProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";

    const defaultStyle = {
      justifyContent: center ? "center" : justify,
      alignItems: center ? "center" : align,
    };

    if (!visible) {
      return null;
    }

    return (
      <BaseComponent
        ref={forwardedRef}
        className={classNames(
          styles.PageModal,
          visible ? styles["modal-open"] : styles["modal-close"],
          "earlybird-page-modal",
          className,
        )}
        style={{ ...style, ...defaultStyle }}
        {...rest}
      >
        {children}
      </BaseComponent>
    );
  },
);

export default PageModal;
