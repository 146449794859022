import React, { useEffect, useState } from "react";

import { trackEventMixpanel, trackPageViewMixpanel } from "@earlypay/shared";
import {
  changeFileToBase64,
  checkBrowser,
  createFormDataWithImage,
  extractTextFromImage,
} from "@earlypay/shared/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  BoxButton,
  Image,
  ImageUploader,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";
import KnBankBookGuide from "@components/organisms/KnBankBookGuide";
import KnBankMobileCopyGuide from "@components/organisms/KnBankMobileCopyGuide";

import { useNavigateWithParams } from "@hooks/useNavigateWithParams";

import { useUpdateDocument } from "@apis/hooks/applications/mutations";

import { applicationState } from "@/recoil/applications/atoms";

export const WithdrawalAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const application = useRecoilValue(applicationState);
  const [visibleImageUploader, setVisibleImageUploader] = useState(false);
  const [mobileCopyGuideVisible, setMobileCopyGuideVisible] = useState(false);
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const currentQuery = location.search;

  const mutation = useUpdateDocument(application.id);

  const extractAccount = async (imageUrl: string) => {
    if (checkBrowser() === "safari") return;
    const text = await extractTextFromImage(imageUrl);
    const ocrBankNumber = text?.match(/\d{3}-\d{4}-\d{4}-\d{2}/) ?? [];

    if (ocrBankNumber.length > 0) {
      trackEventMixpanel("ocr_withdrawal_account", {
        description: "회수 계좌 OCR 성공",
      });
      setAccount(ocrBankNumber[0]?.replace(/-/g, "") ?? null);
    } else {
      trackEventMixpanel("fail_ocr_withdrawal_account", {
        description: "회수 계좌 OCR 실패",
      });
    }
  };

  /** 모바일 통장 사본 모달 띄우는 함수를 호출합니다. */
  const openMobileBankbookGuide = () => {
    trackEventMixpanel("click_mobile_bnk_bank_guide", {
      description: "회수 계좌 모바일 통장 사본 첨부 가이드 클릭",
    });
    setMobileCopyGuideVisible(true);
  };

  /** 경남은행 통장 이미지를 서버로 전송합니다. */
  const handleUploadImage = async (image: File) => {
    setLoading(true);
    const imageBase64 = await changeFileToBase64(image);
    await extractAccount(imageBase64);
    const formData = createFormDataWithImage(image, "image_knbank_account");
    mutation.mutate(formData);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      if (currentQuery) {
        return navigate(
          `/service-apply/withdrawal-account/verify${currentQuery}&withdrawalAccount=${account}`,
        );
      }

      return navigate(
        `/service-apply/withdrawal-account/verify?withdrawalAccount=${account}`,
      );
    }
  }, [mutation.isSuccess]);

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"회수계좌 등록"} />
      <Header previous={"/service-apply/withdrawal-account-guide"} />
      <PageContainer spacing={20}>
        <VStack spacing={4}>
          <Text typo={"subtitle-1"} bold>
            경남은행 통장을 첨부해주세요
          </Text>
          <Text color={"content-secondary"}>
            정산계좌를 경남은행으로 변경하기 위해 필요해요. 이 통장은 얼리페이가
            미리 드린 금액을 회수하는 데 사용될 거예요.
          </Text>
        </VStack>

        <VStack spacing={2} center>
          <Image src={"/service/bnk-kn-bank.svg"} width={180} height={180} />
          <BoxButton
            hierarchy={"tertiary"}
            state={"fit"}
            size={"sm"}
            onClick={openMobileBankbookGuide}
          >
            모바일 통장 사본은 어떻게 받나요?
          </BoxButton>
        </VStack>

        <ImageUploader
          visible={visibleImageUploader}
          onClose={() => setVisibleImageUploader(false)}
          hasGuide={false}
          handleSaveImage={handleUploadImage}
        />

        {/* 모바일 통장 사본 가이드 */}
        {mobileCopyGuideVisible && (
          <KnBankMobileCopyGuide
            visible={mobileCopyGuideVisible}
            onClose={() => setMobileCopyGuideVisible(false)}
          />
        )}
      </PageContainer>

      <ActionButton
        buttonType={"single"}
        primaryButtonLabel={"사본 첨부하기"}
        onClickPrimaryButton={() => setVisibleImageUploader(true)}
        loading={mutation.isPending || loading}
        accent
        primaryButtonProperty={{
          description: "경남은행 이미지 첨부 버튼",
        }}
      />
    </>
  );
};

export default WithdrawalAccount;
