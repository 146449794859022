import React from "react";
import HanaCapitalTermsLayout from "@routes/layouts/HanaCapitalTermsLayout";
import styled from "styled-components";
import { Text, LottieAnimation } from "@earlybird/ui";

const ReconsentResult: React.FC = () => {
  return (
    <HanaCapitalTermsLayout>
      <Wrapper>
        <Text bold center typo={"subtitle-1"}>
          {"재동의 완료!\n선정산이 예정대로 진행될 거예요"}
        </Text>
        <LottieAnimation
          src={"checking-application"}
          width={240}
          height={240}
        />
        <Text center color={"content-secondary"}>
          {
            "2달 마다 재동의를 진행하고 있어요.\n원활한 자금 조달을 위해 계속해서 빠른 재동의 부탁드려요."
          }
        </Text>
      </Wrapper>
    </HanaCapitalTermsLayout>
  );
};

export default ReconsentResult;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
