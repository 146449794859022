import { DepositorCode } from "./depositors";

export const SalesTagCode = {
  CANCEL: "CANCEL",
  MEET_CARD: "MEET_CARD",
};
export type SalesTagCodeTypes =
  (typeof SalesTagCode)[keyof typeof SalesTagCode];

export const SalesTagNames: { [key in SalesTagCodeTypes]: string } = {
  [SalesTagCode.CANCEL]: "취소",
  [SalesTagCode.MEET_CARD]: "만나서 카드결제",
};
export type SalesTagNamesTypes =
  (typeof SalesTagNames)[keyof typeof SalesTagNames];

export const SalesCode = {
  SALES: "SALES",
  BAEMIN_MEET_PAYMENT: "BAEMIN_MEET_PAYMENT",
  YOGIYO_MEET_PAYMENT: "YOGIYO_MEET_PAYMENT",
  MEET_CASH_PAYMENT: "MEET_CASH_PAYMENT",
  DELIVERY_AGENCY_FEE: "DELIVERY_AGENCY_FEE",
  AD_FEE: "AD_FEE",
  CEO_FEE: "CEO_FEE",
  FEE: "FEE",
};
export type SalesCodeTypes = (typeof SalesCode)[keyof typeof SalesCode];

export const SalesNames: {
  [key in DepositorCode]?: {
    [key in SalesCodeTypes]?: string;
  };
} = {
  [DepositorCode.CARD]: {
    [SalesCode.SALES]: "카드 총 매출",
    [SalesCode.BAEMIN_MEET_PAYMENT]: "배달의민족 만나서 카드매출",
    [SalesCode.YOGIYO_MEET_PAYMENT]: "요기요 만나서 카드매출",
    [SalesCode.FEE]: "카드 수수료",
  },
  [DepositorCode.BAEMIN]: {
    [SalesCode.SALES]: "배달의민족 총 매출",
    [SalesCode.BAEMIN_MEET_PAYMENT]: "만나서 카드매출",
    [SalesCode.MEET_CASH_PAYMENT]: "만나서 현금매출",
    [SalesCode.CEO_FEE]: "사장님 자체할인",
    [SalesCode.FEE]: "배달의민족 수수료",
  },
  [DepositorCode.YOGIYO]: {
    [SalesCode.SALES]: "요기요 총 매출",
    [SalesCode.YOGIYO_MEET_PAYMENT]: "만나서 카드매출",
    [SalesCode.MEET_CASH_PAYMENT]: "만나서 현금매출",
    [SalesCode.DELIVERY_AGENCY_FEE]: "요기요 배달 대행료",
    [SalesCode.FEE]: "요기요 수수료",
  },
  [DepositorCode.COUPANG]: {
    [SalesCode.SALES]: "쿠팡이츠 총 매출",
    [SalesCode.CEO_FEE]: "사장님 자체할인",
    [SalesCode.AD_FEE]: "광고비",
    [SalesCode.FEE]: "쿠팡이츠 수수료",
  },
};
export type SalesNamesTypes = (typeof SalesNames)[keyof typeof SalesNames];
