import React, { createContext, useRef, useState } from "react";
import { Toast } from "./Toast";
import { Toasts, ToastState } from "./Toast.types";

export interface ToastVisibleContextType {
  visibleToast: boolean;
}

export interface ToastContextType {
  addToast: (message: string, state?: ToastState) => void;
  remove: () => void;
}

export const ToastVisibleContext = createContext<
  ToastVisibleContextType | undefined
>(undefined);

export const ToastContext = createContext<ToastContextType | undefined>(
  undefined,
);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toast, setToast] = useState<Toasts>(null);
  const [visibleToast, setVisibleToast] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const addToast = (message: string, state?: ToastState) => {
    const toastState = state ?? "neutral";

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setToast({ message, state: toastState });
    setVisibleToast(true);

    timeoutRef.current = setTimeout(() => {
      setVisibleToast(false);
      timeoutRef.current = null;
    }, 4000);
  };

  const remove = () => {
    setVisibleToast(false);
  };

  return (
    <ToastContext.Provider value={{ addToast, remove }}>
      <ToastVisibleContext.Provider value={{ visibleToast }}>
        {children}
        <Toast {...toast} />
      </ToastVisibleContext.Provider>
    </ToastContext.Provider>
  );
};
