const getCookie = (cookieName: string): string | null => {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    if (cookie[0] === cookieName) {
      return decodeURIComponent(cookie[1]);
    }
  }
  return null;
};

const setCookie = (
  cookieName: string,
  value: string,
  days: number,
  domain?: string
) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + days);

  document.cookie =
    cookieName +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    exdate.toUTCString() +
    `; path=/; domain=${domain ?? ".earlypay.kr"}`;

  console.log("cookieName ", cookieName);
  console.log(
    "document.cookie ",
    cookieName +
      "=" +
      encodeURIComponent(value) +
      "; expires=" +
      exdate.toUTCString() +
      `; path=/; domain=${domain ?? ".earlypay.kr"}`
  );
};

const setCookieFullTime = (cookieName: string, value: string, time: string) => {
  document.cookie =
    cookieName +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    time +
    "; path=/";
};

const setCoolDownCookie = (cookieName: string, maxCount = 5): boolean => {
  const exdate = new Date();
  exdate.setMinutes(exdate.getMinutes() + 3);

  const limiteCount = JSON.parse(getCookie(cookieName))?.value ?? 1;

  if (maxCount > limiteCount) {
    setCookieFullTime(
      cookieName,
      JSON.stringify({
        value: limiteCount + 1,
        exp: exdate.toUTCString(),
      }),
      exdate.toUTCString()
    );
    return false;
  }

  return true;
};

const deleteCookie = (cookieName: string, path?: string, domain?: string) => {
  document.cookie =
    cookieName +
    `=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=${path ?? "/"}; domain=${
      domain ?? ".earlypay.kr"
    }`;
};

export {
  deleteCookie,
  getCookie,
  setCookie,
  setCookieFullTime,
  setCoolDownCookie,
};
