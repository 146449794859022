import React, { useState } from "react";

import HanaCapitalTermsLayout from "@routes/layouts/HanaCapitalTermsLayout";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Text } from "@earlybird/ui";

/** TODO: 디자인 시스템을 구축한 뒤, 재사용이 가능한 컴포넌트로 대체하는 리팩토링 작업이 필요합니다. */
export const Terms: React.FC = () => {
  const navigate = useNavigate();
  const [isExpandAccordion, setIsExpandAccordion] = useState(false);

  const handleClickTerms = () => {
    navigate("/hana-capital/terms-detail");
  };

  const handleClickAccordionHeader = () => {
    setIsExpandAccordion(!isExpandAccordion);
  };

  const Tag = ({ label }: { label: string }) => {
    return (
      <TagWrapper>
        <Text typo={"caption-1"} color={"content-tertiary"}>
          {label}
        </Text>
      </TagWrapper>
    );
  };

  return (
    <HanaCapitalTermsLayout>
      {/** 서비스 동의 체크박스 */}
      <TermListWrapper>
        <AccordionOuterWrapper isExpandAccordion={isExpandAccordion}>
          <CheckboxStack onClick={handleClickAccordionHeader}>
            <Text color={"content-secondary"}>이용약관 필수동의</Text>
            <AccordionIcon
              isExpandAccordion={isExpandAccordion}
            ></AccordionIcon>
          </CheckboxStack>

          <AccordionInnerWrapper isExpandAccordion={isExpandAccordion}>
            <CheckboxStack onClick={handleClickTerms}>
              <TermStack>
                <Text color={"content-tertiary"}>개인(신용)정보 조회 동의</Text>
                <Tag label={"하나캐피탈(주)"} />
              </TermStack>
            </CheckboxStack>
            <CheckboxStack onClick={handleClickTerms}>
              <TermStack>
                <Text color={"content-tertiary"}>채권재양도 확인서</Text>
                <Tag label={"하나캐피탈(주)"} />
              </TermStack>
            </CheckboxStack>
          </AccordionInnerWrapper>
        </AccordionOuterWrapper>

        <CheckboxStack onClick={handleClickTerms}>
          <TermStack>
            <Text color={"content-secondary"}>개인(신용)정보 조회 동의</Text>
            <Tag label={"하나캐피탈(주)"} />
          </TermStack>
        </CheckboxStack>
      </TermListWrapper>
    </HanaCapitalTermsLayout>
  );
};

export default Terms;

const CheckboxStack = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: transparent;
`;

export const TermStack = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

export const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 0px 8px;
  border-radius: 6px;
`;

export const TermListWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  gap: 16px;
  flex-direction: column;
`;

export const AccordionOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-out;
  height: ${({ isExpandAccordion }: { isExpandAccordion: boolean }) =>
    isExpandAccordion ? "94px" : "24px"};
`;

export const AccordionInnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  margin-top: 12px;
  gap: 10px;
  transition: all 0.3s ease-out;
  opacity: ${({ isExpandAccordion }: { isExpandAccordion: boolean }) =>
    isExpandAccordion ? "1" : "0"};
`;

export const AccordionIcon = styled.div`
  width: fit-content;
  height: fit-content;
  transform: ${({ isExpandAccordion }: { isExpandAccordion: boolean }) =>
    isExpandAccordion ? "rotate(180deg)" : "rotate(0)"};
  transition: all 0.2s linear;
`;
