import React, { useEffect, useRef, useState } from "react";

import {
  KN_BANK_APP_ANDROID,
  KN_BANK_APP_IOS,
  checkMobile,
  trackEventMixpanel,
} from "@earlypay/shared";
import {
  SafetyGuideTypes,
  safetyGuide,
} from "@pages/serviceApply/WithdrawalAccountGuide/contents.maps";
import { applicationState } from "@recoil/applications/atoms";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import {
  ActionButton,
  Box,
  HStack,
  Image,
  LottieAnimation,
  PageContainer,
  Stack,
  Text,
  VStack,
  useToast,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

export const WithdrawalAccountGuide = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [isFloatActionButton, setIsFloatActionButton] =
    useState<boolean>(false);
  const currentQuery = location.search;
  const application = useRecoilValue(applicationState);
  const name = application.user.name;

  const guideRef = useRef<HTMLDivElement | null>(null);

  const handleGoToKnBankApp = () => {
    switch (checkMobile()) {
      case "android":
        trackEventMixpanel("go_to_bnk_bank_app", {
          description: "경남은행 앱으로 이동",
        });
        return window.open(KN_BANK_APP_ANDROID);
      case "iphone":
        trackEventMixpanel("go_to_bnk_bank_app", {
          description: "경남은행 앱으로 이동",
        });
        return window.open(KN_BANK_APP_IOS);
      default:
        return toast.addToast("모바일에서 이용해주세요.");
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isFloatActionButton && !entry.isIntersecting) {
          setIsFloatActionButton(true);
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: "-44px 0px 0px 0px",
      },
    );

    if (guideRef.current) {
      observer.observe(guideRef.current);
    }

    return () => {
      if (guideRef.current) {
        observer.unobserve(guideRef.current);
      }
    };
  }, []);

  const handleGoToNext = () => {
    trackEventMixpanel("complete_withdrawal_account_guide", {
      description: "회수 계좌 가이드 단계 완료",
    });
    navigate(`/service-apply/withdrawal-account${currentQuery}`);
  };

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"회수계좌 등록 가이드"} />
      <Header previous={"/service-apply/deposit-account"}></Header>

      <PageContainer padding={0}>
        {/** 가이드 1 */}
        <VStack padding={20} spacing={7}>
          <VStack spacing={4}>
            <Text typo={"subtitle-1"} bold>
              {`${name} 사장님,\n`}
              {"경남은행 통장이 "}
              <Text typo={"subtitle-1"} color={"content-highlight"} bold>
                꼭
              </Text>
              {" 필요해요!"}
            </Text>
            <Text color="content-secondary">
              {
                "서비스 이용을 위해 방금 입력하신 입금계좌 외에\n경남은행 통장이 추가로 필요해요."
              }
            </Text>
          </VStack>

          <Stack center>
            <Image width={180} src={"/service/bnk-kn-bank.svg"} />
          </Stack>
        </VStack>

        <div ref={guideRef}>
          <Line />
        </div>

        {/** 가이드 2 */}
        <VStack padding={20} spacing={9}>
          <VStack spacing={4}>
            <Text typo={"subtitle-1"} bold>
              {`입금은 원하는 통장으로,\n상환은 `}
              <Text typo={"subtitle-1"} color={"content-highlight"} bold>
                경남은행
              </Text>
              {"으로 간편하게!"}
            </Text>
            <Text color="content-secondary">
              {
                "선정산 입금은 원하는 계좌로,\n상환은 경남은행 계좌로만 가능해요."
              }
            </Text>
          </VStack>

          <LottieAnimation
            width={"100%"}
            src={"earlypay-service-process"}
            loop
          />
        </VStack>
        <Line />

        {/** 가이드 3 */}
        <VStack padding={20} spacing={9}>
          <VStack spacing={4}>
            <Text typo={"subtitle-1"} bold>
              {`1금융권 경남은행과 함께\n안전한 서비스를 제공해요`}
            </Text>
            <Text color="content-secondary">
              {
                "안심하고 사용하세요. 얼리페이는 믿을 수 있는\n1금융권 BNK경남은행과 함께해요."
              }
            </Text>
          </VStack>
          <VStack spacing={5}>
            {Object.values(safetyGuide).map(
              ({ title, subtitle, image }: SafetyGuideTypes, index: number) => (
                <Box
                  key={index}
                  bg={"bg-secondary"}
                  width={"100%"}
                  padding={"16px 20px"}
                  radius={"3xl"}
                >
                  <HStack align={"center"} spacing={7}>
                    <Image width={24} height={24} src={image} />
                    <VStack>
                      <Text typo={"body-3"} color={"content-secondary"}>
                        {subtitle}
                      </Text>
                      <Text typo={"body-1"} bold>
                        {title}
                      </Text>
                    </VStack>
                  </HStack>
                </Box>
              ),
            )}
          </VStack>
        </VStack>
        <Line />

        {/** 가이드 4 */}
        <VStack padding={"28px 20px 160px 20px"}>
          <Box
            width={"100%"}
            bg={"bg-secondary"}
            radius={"2xl"}
            padding={"40px 32px"}
            style={{ position: "relative" }}
          >
            <ImageWrapper>
              <Image src={"/service/emoji-sunglass.svg"} width={56} />
            </ImageWrapper>
            <VStack center>
              <Text
                typo={"body-1"}
                bold
              >{`궁금증이 해결되셨다면\n계좌 개설을 하러 가볼까요?`}</Text>
              <Text color={"content-secondary"} center>
                {`경남은행 앱으로 5분 만에 계좌 개설하고\n365일 오늘 매출 내일 받으세요.`}
              </Text>
            </VStack>
          </Box>
        </VStack>
      </PageContainer>

      <ActionButton
        buttonType={"duo-vertical"}
        primaryButtonLabel={"5분 만에 경남은행 개설하기"}
        secondaryButtonLabel={"이미 통장이 있어요"}
        onClickPrimaryButton={handleGoToKnBankApp}
        onClickSecondaryButton={handleGoToNext}
        accent={true}
        visible={isFloatActionButton}
      />
    </>
  );
};

export default WithdrawalAccountGuide;

const Line = styled.div`
  width: 100%;
  height: 16px;
  background-color: var(--earlybird-color-border-tertiary);
  margin-top: 22px;
  margin-bottom: 22px;
  flex-shrink: 0;
`;

const ImageWrapper = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const ActionButtonWrapper = styled.div<{ visible: boolean }>`
  bottom: ${({ visible }: { visible: boolean }) => (visible ? 0 : -100)};
  opacity: ${({ visible }: { visible: boolean }) => (visible ? 1 : 0)};
  transition: all 0.3s ease-out;
`;
