import React from "react";
import CameraView from "../../elements/CameraView";
import styled from "styled-components";
import Webcam from "react-webcam";
import { Text, Icon } from "@earlybird/ui";
import { XIcon } from "@earlybird/icons";

interface IdentificationCameraViewProps {
  onClose: () => void;
  onShooting: () => void;
  webcamRef: React.RefObject<Webcam>;
}

const IdentificationCameraView: React.FC<IdentificationCameraViewProps> = ({
  onClose,
  onShooting,
  webcamRef,
}) => {
  return (
    <ModalWrapper>
      <BackgroundView>
        <OverlayWrapper>
          <CameraWrapper />
        </OverlayWrapper>
        <CustomHeader>
          <Text color="content-on-color">신분증 촬영</Text>
          <ClosedMark onClick={onClose}>
            <Icon icon={XIcon} color={"content-on-color"} size={"xl"} />
          </ClosedMark>
        </CustomHeader>
        <ModalInnerWrapper>
          <Text bold center color={"content-on-color"}>
            {"어두운 배경에서 사각형에 맞게\n신분증을 놓고 촬영해주세요."}
          </Text>
        </ModalInnerWrapper>

        <ShootingButton onClick={onShooting} />

        <CameraView
          webcamRef={webcamRef}
          height={window.innerHeight}
          width={window.innerWidth > 500 ? 500 : window.innerWidth}
        />
      </BackgroundView>
    </ModalWrapper>
  );
};

export default IdentificationCameraView;

const ModalWrapper = styled.div`
  width: 100%;
  max-width: var(--earlybird-breakpoint-md);
  height: 100%;
  overflow: hidden;
  z-index: 100;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
`;

const BackgroundView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  video {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 500px;
  }
`;

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const CustomHeader = styled.div`
  background: transparent;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
`;

const ClosedMark = styled.button`
  background: transparent;
  position: absolute;
  right: 12px;
  top: 10px;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 14vh;
  z-index: 10;
`;

const CameraWrapper = styled.div`
  border-radius: 20px;
  height: 192px;
  width: 291px;
  margin-top: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid white;
  z-index: 10;
  box-shadow: 0 0 0 2000px rgba(1, 1, 1, 0.5);
  margin-bottom: 14vh;
`;

const ShootingButton = styled.button`
  border-radius: 100%;
  width: 80px;
  height: 80px;
  background-color: white;
  border: solid #e0e0e0 4px;
  position: absolute;
  bottom: 16vh;
  z-index: 10;

  &:active {
    opacity: 70%;
    transition: opacity 0.2s ease;
  }
`;
