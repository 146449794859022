import { Forbidden, NotFound, SystemError } from "@pages/errors";
import { guestRouters } from "@routes/routers/guestRouters";
import { protectedRouters } from "@routes/routers/protectedRouters";
import { publicRouters } from "@routes/routers/publicRouters";
import { RouteObject, createBrowserRouter } from "react-router-dom";

export const routers: ReturnType<typeof createBrowserRouter> =
  createBrowserRouter([
    // 로그인 해야만 접속할 수 있는 페이지
    { ...protectedRouters },
    // 로그인이 안된 상태에서만 접속할 수 있는 페이지
    { ...guestRouters },
    // 로그인 여부 상관없이 접속 가능한 퍼블릭 페이지
    { ...publicRouters },
  ]);
