import { getMonth, getYear } from "@earlypay/shared";
import { Box } from "@ui/components/atoms";
import "@ui/styles/index.scss";
import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";
import { CalendarDates } from "../CalendarDates";
import { DatePickerHeader } from "../DatePickerHeader";
import styles from "./Calendar.module.scss";
import { CalendarProps } from "./Calendar.types";

/**
 * `Calendar` 는 날짜를 캘린더 형태로 보여준는 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Calendar
 *  displayMode={"month"}
 *  startDate={startDate}
 *  endDate={endDate}
 * />
 * ```
 */
export const Calendar = forwardRef<HTMLElement, CalendarProps>(
  function Calendar(
    {
      children,
      className,
      displayMode = "month",
      startDate,
      endDate,
      dailyContentsType,
      dailyAmountContents,
      selectedDate,
      currentDate,
      loading,
      handleNavigateDateHeader,
      handleNavigateDate,
      as,
      ...rest
    }: CalendarProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(styles.Calendar, "earlybird-calendar", className)}
      >
        <Box padding={"16px 16px 0 16px"}>
          <DatePickerHeader
            displayMode={displayMode}
            handleNavigateDate={handleNavigateDateHeader}
            currentDate={currentDate}
            startDate={startDate}
            endDate={endDate}
          />
        </Box>
        <Box width={"100%"} padding={"8px 16px"}>
          {children}
        </Box>
        <CalendarDates
          year={getYear(currentDate)}
          month={getMonth(currentDate)}
          dailyContentsType={dailyContentsType}
          dailyAmountContents={dailyAmountContents}
          selectedDate={selectedDate}
          startDate={startDate}
          endDate={endDate}
          loading={loading}
          handleNavigateDate={handleNavigateDate}
        />
      </BaseComponent>
    );
  },
);

export default Calendar;
