import { PlatformAccountRequests } from "@earlypay/shared/src/typings/apis/requests";

import { instance } from "@/apis/instance";

/**
 * 신청서에 등록된 배달앱 계정 목록을 조회하는 API 함수입니다.
 */
export const getPlatformAccounts = async <T>(
  applicationId: number,
): Promise<T | null> => {
  const { data } = await instance({
    method: "get",
    url: `v2/applications/${applicationId}/platform-accounts`,
  });

  return data;
};

/**
 * 신청서에 등록된 배달앱 계정을 등록하는 API 함수입니다.
 */
export const postPlatformAccount = async <T>(
  applicationId: number,
  payload: PlatformAccountRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "post",
    url: `v2/applications/${applicationId}/platform-account`,
    data: { ...payload },
  });

  return data;
};

/**
 * 신청서에 등록된 배달앱 계정을 수정하는 API 함수입니다.
 */
export const patchPlatformAccounts = async <T>(
  applicationId: number,
  accountId: number,
  payload: PlatformAccountRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "patch",
    url: `v2/applications/${applicationId}/platform-accounts/${accountId}`,
    data: { ...payload },
  });

  return data;
};

/**
 * 신청서에 등록된 배달앱 계정을 삭제하는 API 함수입니다.
 */
export const deletePlatformAccounts = async <T>(
  applicationId: number,
  accountId: number,
): Promise<T | null> => {
  const { data } = await instance({
    method: "delete",
    url: `v2/applications/${applicationId}/platform-accounts/${accountId}`,
  });

  return data;
};
