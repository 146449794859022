/**
 * number 를 1,000원 이상은 천, 10,000원 이상은 만, 100,000,000원 이상은 억 단위로 표시합니다.
 * 필요한 경우 소수점 첫째 자리까지 표기합니다. 백만~억 단위는 소수점을 표기하지 않습니다.
 * @param amount
 * @returns {string}
 */
function changeNumberToAmountKR(amount: number) {
  if (amount >= 100000000) {
    const formattedAmount = Math.floor(amount / 100000000);
    return `${formattedAmount}억`;
  } else if (amount >= 10000) {
    const formattedAmount = Math.floor(amount / 1000) / 10;
    const length = Math.floor(formattedAmount).toString().length;
    return `${formattedAmount.toFixed(length >= 3 ? 0 : 1)}만`;
  } else if (amount >= 1000) {
    const formattedAmount = Math.floor(amount / 100) / 10;
    return `${formattedAmount}천`;
  } else {
    return `${amount.toLocaleString()}원`;
  }
}

export { changeNumberToAmountKR };
