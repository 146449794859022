import React from "react";

import PublicLayout from "@routes/layouts/PublicLayout";
import InitializeServiceRoute from "@routes/utils/InitializeServiceRoute";

import { ConfirmModalProvider, ToastProvider } from "@earlybird/ui";

const PublicRouteGuard: React.FC = () => {
  return (
    <ToastProvider>
      <ConfirmModalProvider>
        <InitializeServiceRoute>
          <PublicLayout />
        </InitializeServiceRoute>
      </ConfirmModalProvider>
    </ToastProvider>
  );
};

export default PublicRouteGuard;
