import classNames from "classnames";
import { forwardRef, ForwardedRef } from "react";

import React from "react";
import "@ui/styles/index.scss";
import styles from "./Icon.module.scss";
import { IconProps } from "./Icon.types";
import { changeColorToClassName } from "@ui/utils/className";

/**
 * `Icon` 은 작고 간결한 시각적 요소로, 특정한 의미나 기능을 나타내는 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Icon
 *    icon={CheckIcon}
 *    color="content-secondary"
 *    size="md"
 * />
 * ```
 */
export const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon(
  {
    className,
    children,
    as,
    icon: SourceElement,
    size = "md",
    color = "content-primary",
    style,
    ...rest
  }: IconProps,
  forwardedRef: ForwardedRef<SVGSVGElement>,
) {
  return (
    <SourceElement
      {...rest}
      ref={forwardedRef}
      className={classNames(
        styles.Icon,
        styles[`size-${size}`],
        changeColorToClassName(color),
        "earlybird-icon",
        className,
      )}
      style={{ ...style }}
    />
  );
});

export default Icon;
