import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";

import { trackEventMixpanel } from "@earlypay/shared/configs";
import { debounce } from "@earlypay/shared/utils";
import { HStack, Icon, Spinner, Text } from "@ui/components/atoms";
import {
  changeSizeToFontSize,
  changeTypeToTextColor,
} from "@ui/components/atoms/BoxButton/utils";
import "@ui/styles/index.scss";
import styles from "./BoxButton.module.scss";
import { BoxButtonProps } from "./BoxButton.types";

/**
 * `BoxButton` 은 형태의 일반적인 버튼으로 스크린의 컨텐츠 영역 또는 하단에 배치하여 스크린 내 주요한 행동을 나타내기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <BoxButton
 *   state="fit"
 *   hierarchy="secondary"
 *   size="md"
 *   onClick={onClick}
 * >
 *   Earlypay
 * </BoxButton>
 * ```
 */
export const BoxButton = forwardRef<HTMLElement, BoxButtonProps>(
  function Button(
    {
      className,
      children = "레이블",
      as,
      type = "button",
      disabled = false,
      loading = false,
      fixed = false,
      hierarchy = "primary",
      size = "md",
      state = "full",
      width,
      icon,
      onClick,
      property,
      ...rest
    }: BoxButtonProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "button";
    const disabledStatus = disabled || loading;

    const handleClickButton = debounce(async () => {
      trackEventMixpanel("click_button", { label: children, ...property });
      onClick && onClick();
    }, 300);

    const RenderedBoxButton = () => {
      return (
        <BaseComponent
          {...rest}
          ref={forwardedRef}
          className={classNames(
            styles.BoxButton,
            disabled && styles.disabled,
            loading && styles.loading,
            fixed && styles.fixed,
            hierarchy && styles[`hierarchy-${hierarchy}`],
            size && styles[`size-${size}`],
            state && styles[`state-${state}`],
            "earlybird-box-button",
            className,
          )}
          type={type}
          onClick={handleClickButton}
          disabled={disabledStatus}
        >
          {!loading && (
            <HStack center spacing={4} width={"fit-content"}>
              {icon && (
                <Icon
                  icon={icon}
                  color={
                    disabled
                      ? "content-disabled"
                      : changeTypeToTextColor(hierarchy)
                  }
                />
              )}
              <Text
                typo={changeSizeToFontSize(size)}
                color={
                  disabled
                    ? "content-disabled"
                    : changeTypeToTextColor(hierarchy)
                }
                bold={size === "lg" || size === "xs"}
              >
                {children}
              </Text>
            </HStack>
          )}
          {loading && <Spinner size={size} />}
        </BaseComponent>
      );
    };

    return <RenderedBoxButton />;
  },
);

export default BoxButton;
