import React from "react";

import { modalCode, trackPageViewMixpanel } from "@earlypay/shared";

import {
  ActionButton,
  Box,
  InfoBox,
  LottieAnimation,
  PageContainer,
  Text,
  VStack,
  useConfirmModal,
} from "@earlybird/ui";

import { Header } from "@components/layouts";
import Meta from "@components/layouts/Meta";

export const AdminRejected = () => {
  const confirmModal = useConfirmModal();

  const handleRetryApplication = () => {
    confirmModal.openConfirmModal({
      id: modalCode.ERROR_RETRY_SERVICE_APPLY,
    });
  };

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"심사 결과 | 운영 거절"} />
      <Header hasLeftButton={false} />
      <PageContainer style={{ justifyContent: "space-between" }}>
        <VStack center>
          <Text typo={"subtitle-1"} bold center>
            {`아쉽게도 이번에는 선정산\n서비스를 제공해 드리기 어려워요`}
          </Text>
          <Box center width={"100%"}>
            <LottieAnimation src={"warning"} width={240} height={240} />
          </Box>
          <Text color={"content-secondary"} center>
            {
              "신용 상태를 제외한 다른 이유로 서비스\n이용이 어려워요. 자세한 사유가 궁금하시다면,\n언제든지 고객센터로 문의해주세요."
            }
          </Text>
        </VStack>

        <InfoBox
          title={"다른 매장도 함께 운영 중이신가요?"}
          message={
            "다른 매장이 있으시다면 해당 매장으로 다시 한번 선정산 서비스를 신청해 보세요. 사업장별로 가능 여부가 다를 수 있으니 확인해 보시는 걸 추천드려요."
          }
          state={"success"}
        />
      </PageContainer>

      <ActionButton
        primaryButtonLabel={"다른 매장으로 신청하기"}
        onClickPrimaryButton={handleRetryApplication}
        primaryButtonProperty={{
          description: "운영 거절 | 다른 매장으로 신청 버튼",
        }}
      />
    </>
  );
};

export default AdminRejected;
