import { sentryHook } from "@earlypay/shared/configs";
import { PlatformAccountRequests } from "@earlypay/shared/src/typings/apis/requests";
import {
  DeliveryCodeTypes,
  DeliveryNames,
  ServerError,
  modalCode,
} from "@earlypay/shared/typings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";

import { useConfirmModal, useToast } from "@earlybird/ui";

import { applicationsQueryKeys } from "@/apis";
import {
  deletePlatformAccounts,
  patchPlatformAccounts,
  postPlatformAccount,
} from "@/apis/endpoints";
import { platformsQueryKeys } from "@/apis/hooks/platforms/queryKeys";
import { applicationState } from "@/recoil/applications/atoms";

export const useAddPlatformAccount = (
  applicationId: number,
  platform: DeliveryCodeTypes,
) => {
  const application = useRecoilValue(applicationState);
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();
  const toast = useToast();

  return useMutation({
    mutationKey: platformsQueryKeys.addPlatformsAccount(applicationId).queryKey,
    mutationFn: async (payload: PlatformAccountRequests) => {
      await postPlatformAccount(applicationId, payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey:
          platformsQueryKeys.fetchPlatformsAccount(applicationId).queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });

      toast.addToast(
        `${DeliveryNames[platform]} 계정이 추가되었습니다.`,
        "success",
      );
    },
    onError: (error: ServerError) => {
      sentryHook({
        application: application,
        title: "배달앱 계정 등록에 실패하였습니다.",
        error: error,
      });

      confirmModal.openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
    },
    retry: 0,
  });
};

export const useUpdatePlatformAccount = (
  platform: DeliveryCodeTypes,
  applicationId: number,
  accountId: number,
) => {
  const application = useRecoilValue(applicationState);
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();
  const toast = useToast();

  return useMutation({
    mutationKey:
      platformsQueryKeys.updatePlatformsAccount(applicationId).queryKey,
    mutationFn: async (payload: PlatformAccountRequests) => {
      await patchPlatformAccounts(applicationId, accountId, payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey:
          platformsQueryKeys.fetchPlatformsAccount(applicationId).queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });

      toast.addToast(
        `${DeliveryNames[platform]} 계정이 변경되었습니다.`,
        "success",
      );
    },
    onError: (error: ServerError) => {
      sentryHook({
        application: application,
        title: "배달앱 계정 수정에 실패하였습니다.",
        error: error,
      });

      confirmModal.openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
    },
    retry: 0,
  });
};

export const useDeletePlatformAccount = (
  platform: string,
  applicationId: number,
  accountId: number,
) => {
  const application = useRecoilValue(applicationState);
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationKey:
      platformsQueryKeys.deletePlatformsAccount(applicationId).queryKey,
    mutationFn: async () => {
      await deletePlatformAccounts(applicationId, accountId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey:
          platformsQueryKeys.fetchPlatformsAccount(applicationId).queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });

      toast.addToast(
        `${DeliveryNames[platform]} 계정이 삭제되었습니다.`,
        "success",
      );
    },
    onError: (error: ServerError) => {
      sentryHook({
        application: application,
        title: "배달앱 계정 삭제에 실패하였습니다.",
        error: error,
      });
      toast.addToast("배달앱 계정 삭제에 실패하였습니다.", "negative");
    },
    retry: 0,
  });
};
