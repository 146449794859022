import { DeliveryAgencyRequests } from "@earlypay/shared/src/typings/apis/requests";

import { instance } from "@/apis";

/**
 * 신청서에 등록된 배달 대행 업체 목록을 조회하는 API 함수입니다.
 */
export const getDeliveryAgents = async <T>(
  applicationId: number,
): Promise<T | null> => {
  const { data } = await instance({
    method: "get",
    url: `v2/applications/${applicationId}/delivery-agents`,
  });

  return data;
};

/**
 * 신청서에 등록된 배달 대행 업체 목록을 수정하는 API 함수입니다.
 */
export const putDeliveryAgents = async <T>(
  applicationId: number,
  payload: DeliveryAgencyRequests[],
): Promise<T | null> => {
  const { data } = await instance({
    method: "put",
    url: `v2/applications/${applicationId}/delivery-agents`,
    data: payload,
  });

  return data;
};
