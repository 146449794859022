import { useWindowSize } from "@earlypay/shared/hooks";
import { getPixelValue } from "@earlypay/shared/utils/types";
import "@ui/styles/index.scss";
import { changeColorToClassName } from "@ui/utils/className";
import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";
import styles from "./Container.module.scss";
import { ContainerProps } from "./Container.types";

/**
 * `Container` 는 컴포넌트는 화면의 크기에 따라 콘텐츠의 레이아웃을 조정하여, 사용자에게 최적화된 시각적 경험을 제공하는 역할을 합니다.
 * @example
 *
 * ```tsx
 * <Container>
 *  {...children}
 * </Container>
 * ```
 */
export const Container = forwardRef<HTMLElement, ContainerProps>(
  function Container(
    {
      className,
      as,
      children,
      display = "flex",
      direction = "col",
      center = false,
      width,
      height,
      padding,
      margin,
      bg = "bg-primary",
      maxWidth = "var(--earlybird-breakpoint-md)",
      ...rest
    }: ContainerProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";
    const windowSize = useWindowSize();

    const p = getPixelValue(padding);
    const m = getPixelValue(margin);
    const w = getPixelValue(width ?? windowSize.width);
    const h = getPixelValue(height ?? windowSize.height);

    const style = {
      width: w,
      height: h,
      margin: m,
      padding: p,
      "--max-width": getPixelValue(maxWidth),
    };

    return (
      <BaseComponent
        ref={forwardedRef}
        className={classNames(
          styles.Container,
          styles[`direction-${direction}`],
          styles[`display-${display}`],
          center && styles.center,
          "earlybird-container",
          bg && changeColorToClassName(bg),
          className,
        )}
        style={style}
        {...rest}
      >
        {children}
      </BaseComponent>
    );
  },
);

export default Container;
