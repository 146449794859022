import { useWindowSize } from "@earlypay/shared/hooks";
import { BoxButton } from "@ui/components/atoms";
import { PageHeader } from "@ui/components/layouts";
import { TermsPage } from "@ui/components/organisms";
import { SignaturePad } from "@ui/components/organisms/SignaturePad";
import { generateTermsPdfFile } from "@ui/components/templates/AgreementDetails/utils";
import "@ui/styles/index.scss";
import classNames from "classnames";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import styles from "./AgreementDetails.module.scss";
import { AgreementDetailsProps } from "./AgreementDetails.types";

/**
 * `AgreementDetails` 는 사진 촬영 또는 불러오기를 실행하고 첨부 가이드를 같이 제공하여 필요한 이미지를 첨부하도록 유도하기 위한 컴포넌트입니다.
 * 이름, 서명 날짜를 약관 파일 pdf 로 저장하기 위해서는 username, date 값이 필요합니다.
 * @example
 *
 * ```tsx
 * <AgreementDetails
 *   visible={visible}
 *   onClose={onClose}
 *   username={username}
 *   date={date}
 *   businessLicense={businessLicense}
 *   state={"check"}
 * />
 * ```
 */

export const AgreementDetails = forwardRef<HTMLElement, AgreementDetailsProps>(
  function AgreementDetails(
    {
      className,
      as,
      terms,
      sum,
      visible,
      onClose,
      hasSignature,
      username,
      businessLicense,
      date,
      state = "check",
      handleSaveTerms,
      ...rest
    }: AgreementDetailsProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";
    const buttonLabel = state === "check" ? "확인" : "모두 이해했어요";
    const windowSize = useWindowSize();

    const [visibleSignaturePad, setVisibleSignaturePad] = useState(false);
    const [signature, setSignature] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClickButton = () => {
      if (state === "check") {
        return onClose();
      }

      setVisibleSignaturePad(true);
    };

    /** 서명 이미지를 받아서 약관 html 에 저장하고 pdf 파일로 합쳐 저장합니다.  */
    const handleSaveSignature = async (image: string) => {
      setLoading(true);
      setSignature(image);
    };

    const handleGenerateTermsFile = async () => {
      if (signature) {
        const file = sum
          ? await generateTermsPdfFile(sum)
          : await generateTermsPdfFile(terms);
        handleSaveTerms(file);
      }
    };

    useEffect(() => {
      handleGenerateTermsFile();
    }, [signature]);

    if (!visible) {
      return null;
    }

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.AgreementDetails,
          "earlybird-agreement-details",
          className,
        )}
        style={{ width: windowSize.width, height: windowSize.height }}
      >
        <PageHeader hasRightButton={false} handleClickLeftButton={onClose} />

        <div className={classNames(styles.TermsContainer)}>
          {terms.map((code, index) => (
            <div key={index} className={classNames(styles.A4Page)}>
              <TermsPage
                filename={code}
                username={username}
                businessLicense={businessLicense}
                date={date}
                signature={signature}
                sum={sum ? sum[0] : null}
              />
            </div>
          ))}
        </div>

        <SignaturePad
          visible={visibleSignaturePad}
          onClose={() => setVisibleSignaturePad(false)}
          handleSaveSignature={handleSaveSignature}
        />

        <div className={classNames(styles.ButtonContainer)}>
          <BoxButton size={"lg"} loading={loading} onClick={handleClickButton}>
            {buttonLabel}
          </BoxButton>
        </div>
      </BaseComponent>
    );
  },
);

export default AgreementDetails;
