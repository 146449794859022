export const kebabToUpperSnakeCase = (value: string) => {
  return value
    .split("-")
    .map(word => word.toUpperCase())
    .join("_");
};

export const upperSnakeCaseToKebab = (value: string) => {
  return value
    .split("_")
    .map(word => word.toLowerCase())
    .join("-");
};
