import React from "react";

import { SLACK_WEBHOOK_SERVICE_APPLY } from "@earlypay/shared";
import { getRetryServiceApplicationMessage } from "@earlypay/shared/src/configs/services/Slack/getSlackMessages";
import { applicationState } from "@recoil/applications/atoms";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  Box,
  Image,
  PageContainer,
  Text,
  useToast,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

import { sendSlackMessage } from "@apis/endpoints/commons";

export const Restart = () => {
  const application = useRecoilValue(applicationState);
  const toast = useToast();

  const handleRetryApplication = async () => {
    try {
      await sendSlackMessage({
        url: SLACK_WEBHOOK_SERVICE_APPLY,
        message: getRetryServiceApplicationMessage(
          application.id,
          application.user.name,
          application.store.title,
          application.serviceType,
          application.user.inflowPath,
        ),
      });
      toast.addToast(
        "요청이 완료되었어요.\n고객센터에서 곧 연락드릴게요!",
        "success",
      );
    } catch (error) {
      toast.addToast(
        "일시적인 오류입니다.\n고객센터로 문의해주세요.",
        "negative",
      );
    }
  };

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"심사 결과 | 취소"} />
      <Header hasLeftButton={false} />
      <PageContainer align={"center"}>
        <Text typo={"subtitle-1"} bold center>
          {`가입을 원하시면\n재신청을 요청해 주세요!`}
        </Text>
        <Box center width={"100%"}>
          <Image
            src={"/service/document-with-pencil.svg"}
            width={240}
            height={240}
          />
        </Box>
        <Text color={"content-secondary"} center>
          {
            "보안상 기존 정보를 삭제했어요.\n재신청을 요청하시면 영업일 기준 1일 이내로\n고객센터(1522-6911)에서 안내해 드릴게요."
          }
        </Text>
      </PageContainer>

      <ActionButton
        buttonType={"single"}
        primaryButtonLabel={"재신청 요청하기"}
        onClickPrimaryButton={handleRetryApplication}
        primaryButtonProperty={{
          description: "사용자 취소 | 다시 신청하기 버튼",
        }}
      />
    </>
  );
};

export default Restart;
