import React, { useCallback, useEffect } from "react";
import { BankSelector, Input, VStack } from "@earlybird/ui";
import { Controller, useFormContext } from "react-hook-form";
import { debounce } from "@earlypay/shared/utils";

interface AccountFormProps {
  accountName: string;
  bankName: string;
}

export const AccountForm = ({ accountName, bankName }: AccountFormProps) => {
  const { trigger, control, watch } = useFormContext();

  const handleTrigger = useCallback(
    debounce(async () => {
      await trigger([accountName, bankName]);
    }, 500),
    [],
  );

  useEffect(() => {
    handleTrigger();
  }, [handleTrigger, watch(accountName), watch(bankName)]);

  return (
    <VStack spacing={4}>
      <Controller
        control={control}
        name={bankName}
        render={({ field, formState }) => (
          <BankSelector
            name={bankName}
            value={field.value}
            onBlur={field.onBlur}
            onChange={field.onChange}
            errorMessage={formState.errors[accountName]?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={accountName}
        render={({ field, formState }) => (
          <Input
            type={"number"}
            name={accountName}
            placeholder={"계좌번호 입력"}
            inputMode="numeric"
            maxLength={14}
            required
            value={field.value}
            onBlur={field.onBlur}
            onChange={field.onChange}
            errorMessage={formState.errors[accountName]?.message}
          />
        )}
      />
    </VStack>
  );
};

export default AccountForm;
