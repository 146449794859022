import ServiceApplyLayout from "@routes/layouts/ServiceApplyLayout";
import ProtectedServiceApplyRoute from "@routes/utils/ProtectedServiceApplyRoute";

const ServiceApplyRouteGuard = () => {
  return (
    <ProtectedServiceApplyRoute>
      <ServiceApplyLayout />
    </ProtectedServiceApplyRoute>
  );
};
export default ServiceApplyRouteGuard;
