import classNames from "classnames";
import { forwardRef, ForwardedRef } from "react";

import "@ui/styles/index.scss";
import styles from "./Spinner.module.scss";
import { SpinnerProps } from "./Spinner.types";
import React from "react";

/**
 * `Spinner` 은 형태의 일반적인 버튼으로 스크린의 컨텐츠 영역 또는 하단에 배치하여 스크린 내 주요한 행동을 나타내기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Spinner
 *   size="md"
 * />
 * ```
 */
export const Spinner = forwardRef<HTMLElement, SpinnerProps>(function Spinner(
  { className, as, size = "md", ...rest }: SpinnerProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "div";

  return (
    <BaseComponent
      {...rest}
      ref={forwardedRef}
      className={classNames(
        styles.Spinner,
        size && styles[`size-${size}`],
        "earlybird-spinner",
        className,
      )}
    />
  );
});

export default Spinner;
