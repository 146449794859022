import React, { useEffect } from "react";

import { trackEventMixpanel, trackPageViewMixpanel } from "@earlypay/shared";
import { depositAccountSchema } from "@earlypay/shared/common";
import {
  ServiceApplyCode,
  ServiceApplyFormValues,
  ServiceApplyIndex,
} from "@earlypay/shared/typings";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import {
  ActionButton,
  InfoBox,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";
import AccountForm from "@components/organisms/AccountForm";

import { useNavigateWithParams } from "@hooks/useNavigateWithParams";

import { useUpdateDepositAccount } from "@apis/hooks/applications/mutations";

import { applicationState } from "@/recoil/applications/atoms";

export const DepositAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentQuery = location.search;
  const application = useRecoilValue(applicationState);
  const methods = useForm({
    resolver: yupResolver(depositAccountSchema),
    defaultValues: {
      depositBank: application.depositAccount.bank ?? "",
      depositAccount: application.depositAccount.account ?? "",
    },
    mode: "onChange",
  });
  const isIdentification =
    ServiceApplyIndex[ServiceApplyCode.ID_CARD] <
    ServiceApplyIndex[application.stage];

  const mutation = useUpdateDepositAccount(application.id);

  const onSubmit = (values: ServiceApplyFormValues) => {
    mutation.mutate({
      account: values.depositAccount,
      bank: values.depositBank,
    });
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      trackEventMixpanel("complete_deposit_account", {
        description: "입금 계좌 단계 완료",
      });

      navigate(`/service-apply/withdrawal-account-guide${currentQuery}`);
    }

    if (mutation.isError) {
      methods.setError("depositAccount", {
        type: "custom",
        message: "계좌 정보가 맞는지 확인 후 다시 입력해주세요.",
      });
      methods.setError("depositBank", {
        type: "custom",
        message: "계좌 정보가 맞는지 확인 후 다시 입력해주세요.",
      });
    }
  }, [mutation.isSuccess, mutation.isError]);

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"입금계좌 등록"} />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Header
            previous={
              isIdentification
                ? "/service-apply/service-agreement"
                : "/service-apply/identification"
            }
          ></Header>

          <PageContainer padding={"20px 20px 0 20px"} spacing={16}>
            <VStack spacing={16}>
              <VStack spacing={4}>
                <Text typo={"subtitle-1"} bold>
                  {"매일 입금되는 선정산금\n어디로 받으시겠어요?"}
                </Text>
                <Text color={"content-secondary"}>
                  {"사장님 명의의 계좌로만 보내드릴 수 있어요."}
                </Text>
              </VStack>

              <AccountForm
                accountName={"depositAccount"}
                bankName={"depositBank"}
              />
            </VStack>

            <InfoBox
              message={
                "사장님 명의의 계좌라면 일반 입출금 계좌, 한도 제한 계좌, 인터넷뱅킹(토스뱅크, 카카오뱅크, 케이뱅크) 등 어떤 계좌로든 입금 받으실 수 있어요."
              }
            ></InfoBox>
          </PageContainer>

          <ActionButton
            primaryButtonLabel={"완료"}
            disabledPrimaryButton={!methods.formState.isValid}
            loading={mutation.isPending}
            primaryButtonProperty={{
              description: "입금 계좌 등록 완료 버튼",
            }}
          />
        </Form>
      </FormProvider>
    </>
  );
};

export default DepositAccount;

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
