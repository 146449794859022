/**
 * 랜덤한 2자리 난수를 만드는 함수입니다.
 * @returns 00 ~ 99
 */
export const createRandomNumber = (): string => {
  let ranNumber = String(Math.floor(Math.random() * 100));
  if (Number(ranNumber) < 10) {
    ranNumber = [0, ranNumber].join("");
  } else {
    ranNumber = String(ranNumber);
  }
  return ranNumber;
};
