import { getFormattedDateWithDash } from "../../../utils";
import ChannelTok from "./ChannelService";
import { ApplicationTypes } from "../../../typings";
import { trackEventMixpanel } from "../../../configs";

export const updateChannelIOUser = (application?: ApplicationTypes) => {
  const date = application.user.birthdate;
  ChannelTok.updateUser({
    profile: {
      name: application.user.name,
      phoneNumber: application.user.phone,
      business: application.businessRegistrationNumber,
      birthdate: getFormattedDateWithDash(date),
      status: application.status,
      stage: application.stage,
      store: application.store.title,
      serviceType: application.serviceType,
    },
  });
};

// 채널톡 상담하기를 호출합니다.
export const openChannelIOChat = () => {
  trackEventMixpanel("open_chat", {
    description: "채널톡 상담하기 연결",
  });
  ChannelTok.openChat();
};

// 채널톡을 호출합니다.
export const bootChannelIO = () => {
  ChannelTok.boot({ hidePopup: true, hideChannelButtonOnBoot: true });
};

export const showChannelIOButton = () => {
  ChannelTok.showChannelButton();
};
