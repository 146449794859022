import { ChevronLeftSolidIcon, ChevronRightSolidIcon } from "@earlybird/icons";
import { getDay, getMonth, getYear } from "@earlypay/shared/utils";
import { BottomSheet, IconButton, Text, VStack } from "@ui/components/atoms";
import "@ui/styles/index.scss";
import classNames from "classnames";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import Picker, { PickerValue } from "react-mobile-picker";
import styles from "./DatePickerHeader.module.scss";
import { DatePickerHeaderProps } from "./DatePickerHeader.types";
import {
  checkGoToDatePossible,
  getDateButtonLabel,
  getDateLabel,
  getDateList,
  getDisabledMoveButton,
  getMaxDays,
  getNavigateDate,
  getTitle,
} from "./utils";

/**
 * `DatePickerHeader` 는 날짜 지정을 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <DatePickerHeader
 *   displayMode={"month"}
 *   startDate={startDate}
 *   endDate={endDate}
 *   handleNavigateDate={handleNavigateDate}
 * />
 * ```
 */
export const DatePickerHeader = forwardRef<HTMLElement, DatePickerHeaderProps>(
  function DatePickerHeader(
    {
      className,
      displayMode,
      startDate,
      endDate,
      currentDate,
      handleNavigateDate,
      as,
      ...rest
    }: DatePickerHeaderProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";
    const [dayOptions, setDayOptions] = useState(
      Array.from({ length: 31 }, (v, i) => `${i + 1}`),
    );
    const [visible, setVisible] = useState(false);
    const [pickerValue, setPickerValue] = useState({
      year: getYear(currentDate).toString(),
      month: getMonth(currentDate).toString(),
      day: getDay(currentDate).toString(),
    });
    const selectedDate = new Date(
      `${pickerValue.year}-${pickerValue.month}-${pickerValue.day}`,
    );
    const selectedDateLabel = getDateButtonLabel(selectedDate, displayMode);

    const dateSelections = getDateList(
      displayMode,
      startDate,
      endDate,
      dayOptions,
      selectedDate,
    ); // 날짜 선택 범위

    const isDisabledNextButton = getDisabledMoveButton(
      "next",
      displayMode,
      currentDate,
      endDate,
    );
    const isDisabledPreviousButton = getDisabledMoveButton(
      "previous",
      displayMode,
      currentDate,
      startDate,
    );

    const handleOpenBottomSheet = () => {
      setVisible(true);
    };

    const handleClickNavigateButton = (mode: "previous" | "next") => {
      const date = getNavigateDate(currentDate, displayMode, mode);
      const isPossibleNavigate = checkGoToDatePossible(
        startDate,
        endDate,
        date,
        displayMode,
        mode,
      );
      if (isPossibleNavigate) {
        handleNavigateDate(date);
      }
    };

    const handleSelectDate = () => {
      handleNavigateDate(selectedDate);
      setVisible(false);
    };

    const handleChange = (value: PickerValue, key: string) => {
      setPickerValue(
        value as {
          year: string;
          month: string;
          day: string;
        },
      );
    };

    useEffect(() => {
      const maxDays = getMaxDays(
        Number(pickerValue.year),
        Number(pickerValue.month),
      );
      setDayOptions(Array.from({ length: maxDays }, (v, i) => `${i + 1}`));

      // 선택된 day가 현재 달의 최대 일수를 넘으면 day를 최대값으로 설정
      if (Number(pickerValue.day) > maxDays) {
        setPickerValue((prevValue) => ({
          ...prevValue,
          day: maxDays.toString(),
        }));
      }
    }, [pickerValue.year, pickerValue.month]);

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.DatePickerHeader,
          "earlybird-date-picker-header",
          className,
        )}
      >
        {/** 날짜 헤더 타이틀 */}
        <IconButton
          icon={ChevronLeftSolidIcon}
          disabled={isDisabledPreviousButton}
          color={
            isDisabledPreviousButton
              ? "content-disabled-light"
              : "content-quaternary"
          }
          onClick={() => handleClickNavigateButton("previous")}
        />
        <button
          onClick={handleOpenBottomSheet}
          className={classNames(styles.DatePickerTitleButton)}
        >
          <Text underline bold>
            {getDateLabel(currentDate, displayMode)}
          </Text>
        </button>
        <IconButton
          icon={ChevronRightSolidIcon}
          disabled={isDisabledNextButton}
          color={
            isDisabledNextButton
              ? "content-disabled-light"
              : "content-quaternary"
          }
          onClick={() => handleClickNavigateButton("next")}
        />

        {/** 날짜 선택하는 바텀 시트 */}
        <BottomSheet
          visible={visible}
          onClose={() => setVisible(false)}
          buttonType="single"
          headerType="headline"
          title={getTitle(displayMode)}
          primaryButtonLabel={selectedDateLabel}
          onClickPrimaryButton={handleSelectDate}
        >
          <VStack center>
            <Picker
              value={pickerValue}
              onChange={handleChange}
              wheelMode={"normal"}
              className={classNames(
                "picker-wrapper",
                styles.PickerWrapper,
                styles[`type-${displayMode}`],
              )}
              height={228}
              itemHeight={52}
            >
              {Object.keys(dateSelections).map((name: string) => (
                <Picker.Column
                  key={name}
                  name={name}
                  className={classNames(
                    "picker-column-box",
                    styles.PickerColumnBox,
                  )}
                >
                  {dateSelections[name as "year" | "month" | "day"].map(
                    (option: string) => {
                      const label =
                        name === "year"
                          ? `${option}년`
                          : name === "month"
                            ? `${option}월`
                            : `${option}일`;

                      return (
                        <Picker.Item
                          key={option}
                          value={option}
                          className={classNames(
                            "picker-component-box",
                            styles.PickerComponentBox,
                          )}
                        >
                          {({ selected }) => {
                            return (
                              <Text
                                bold={selected}
                                typo={selected ? "subtitle-2" : "body-1"}
                                color={
                                  selected
                                    ? "content-primary"
                                    : "content-tertiary"
                                }
                              >
                                {label}
                              </Text>
                            );
                          }}
                        </Picker.Item>
                      );
                    },
                  )}
                </Picker.Column>
              ))}
            </Picker>
          </VStack>
        </BottomSheet>
      </BaseComponent>
    );
  },
);

export default DatePickerHeader;
