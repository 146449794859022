import { DepositorCode } from "./depositors";
export const EarlypaysCode = {
  TOTAL_SALES: "TOTAL_SALES",
  UNDER_DEPOSIT: "UNDER_DEPOSIT",
  SERVICE_FEE: "SERVICE_FEE",
  FINANCIAL_INTEREST: "FINANCIAL_INTEREST",
  ADDITIONAL_DEPOSIT: "ADDITIONAL_DEPOSIT",
  ETC_UNDER_DEPOSIT: "ETC_UNDER_DEPOSIT",
  CARD_SALES: "CARD_SALES",
};
export type EarlypaysCodeTypes =
  (typeof EarlypaysCode)[keyof typeof EarlypaysCode];

export const EarlypaysEtcCode = {
  SERVICE_FEE: "SERVICE_FEE",
  FINANCIAL_INTEREST: "FINANCIAL_INTEREST",
  ADDITIONAL_DEPOSIT: "ADDITIONAL_DEPOSIT",
  ETC_UNDER_DEPOSIT: "ETC_UNDER_DEPOSIT",
};
export type EarlypaysEtcCodeTypes =
  (typeof EarlypaysEtcCode)[keyof typeof EarlypaysEtcCode];

export const EtcAmountCode = {
  ORIG_SERVICE_FEE: "ORIG_SERVICE_FEE",
  FINANCIAL_INTEREST: "FINANCIAL_INTEREST",
  ADDITIONAL_EARLYPAY: "ADDITIONAL_EARLYPAY",
  ETC_UNDER_DEPOSIT: "ETC_UNDER_DEPOSIT",
  VAT: "VAT",
  ETC: "ETC",
};
export type EtcAmountCodeTypes =
  (typeof EtcAmountCode)[keyof typeof EtcAmountCode];

export const EtcAmountNames = {
  [EtcAmountCode.ORIG_SERVICE_FEE]: "얼리페이 이용료",
  [EtcAmountCode.FINANCIAL_INTEREST]: "선정산 이자",
  [EtcAmountCode.ADDITIONAL_EARLYPAY]: "추가 선정산",
  [EtcAmountCode.ETC_UNDER_DEPOSIT]: "기타 차감금",
  [EtcAmountCode.ETC]: "기타 추가입금",
  [EtcAmountCode.VAT]: "부가세",
};
export type EtcAmountNamesTypes =
  (typeof EtcAmountNames)[keyof typeof EtcAmountNames];

export const AdditionalDepositCode = {
  ADDITIONAL_EARLYPAY: "ADDITIONAL_EARLYPAY",
  ETC: "ETC",
};
export type AdditionalDepositCodeTypes =
  (typeof AdditionalDepositCode)[keyof typeof AdditionalDepositCode];

export const AdditionalDepositDetailNames = {
  [AdditionalDepositCode.ADDITIONAL_EARLYPAY]: "추가 선정산",
  [AdditionalDepositCode.ETC]: "기타 추가입금",
};
export type AdditionalDepositDetailNamesTypes =
  (typeof AdditionalDepositDetailNames)[keyof typeof AdditionalDepositDetailNames];

export const PlatformSalesSummaryCode = {
  SALES: "SALES",
  COMMISSION: "COMMISSION",
  EXCLUDE_SALES: "EXCLUDE_SALES",
  CANCEL_SALES: "CANCEL_SALES",
};
export type PlatformSalesSummaryCodeTypes =
  (typeof PlatformSalesSummaryCode)[keyof typeof PlatformSalesSummaryCode];

export const PlatformSalesSummaryNames = {
  [PlatformSalesSummaryCode.SALES]: "총 매출",
  [PlatformSalesSummaryCode.COMMISSION]: "수수료",
  [PlatformSalesSummaryCode.EXCLUDE_SALES]: "제외 매출",
  [PlatformSalesSummaryCode.CANCEL_SALES]: "취소 매출",
};
export type PlatformSalesSummaryNamesTypes =
  (typeof PlatformSalesSummaryNames)[keyof typeof PlatformSalesSummaryNames];

export const PlatformSalesFilterCode = {
  ALL: "ALL",
  CARD: "CARD",
  DELIVERY: "DELIVERY",
  CANCELED: "CANCELED",
};
export type PlatformSalesFilterCodeTypes =
  (typeof PlatformSalesFilterCode)[keyof typeof PlatformSalesFilterCode];

export const PlatformSalesFilterNames = {
  [PlatformSalesFilterCode.ALL]: "전체",
  [PlatformSalesFilterCode.CARD]: "카드",
  [PlatformSalesFilterCode.DELIVERY]: "배달",
  [PlatformSalesFilterCode.CANCELED]: "취소",
};
export type PlatformSalesFilterNamesTypes =
  (typeof PlatformSalesFilterNames)[keyof typeof PlatformSalesFilterNames];

export const PlatformSalesTagsCode = {
  CANCEL: "CANCEL",
  MEET_PAYMENT: "MEET_PAYMENT",
  EXCLUDE: "EXCLUDE",
  DISCONNECT: "DISCONNECT",
};
export type PlatformSalesTagsCodeTypes =
  (typeof PlatformSalesTagsCode)[keyof typeof PlatformSalesTagsCode];

export const PlatformSalesTagsNames = {
  [PlatformSalesTagsCode.CANCEL]: "승인취소",
  [PlatformSalesTagsCode.MEET_PAYMENT]: "만나서결제",
  [PlatformSalesTagsCode.EXCLUDE]: "제외매출",
  [PlatformSalesTagsCode.DISCONNECT]: "미연동매출",
};
export type PlatformSalesTagsNamesTypes =
  (typeof PlatformSalesTagsNames)[keyof typeof PlatformSalesTagsNames];

export const BillingCode: {
  [key in DepositorCode]: { [key in EarlypaysCodeTypes]?: string };
} = {
  [DepositorCode.CARD]: {
    TOTAL_SALES: "C001",
    UNDER_DEPOSIT: "C002",
  },
  [DepositorCode.BAEMIN]: {
    TOTAL_SALES: "B001",
    UNDER_DEPOSIT: "B002",
    CARD_SALES: "B003",
  },
  [DepositorCode.YOGIYO]: {
    TOTAL_SALES: "Y001",
    UNDER_DEPOSIT: "Y002",
    CARD_SALES: "Y003",
  },
  [DepositorCode.COUPANG]: {
    TOTAL_SALES: "CE001",
    UNDER_DEPOSIT: "CE002",
  },
  [DepositorCode.ETC]: {
    SERVICE_FEE: "E006",
    FINANCIAL_INTEREST: "E007",
    ADDITIONAL_DEPOSIT: "E008",
    ETC_UNDER_DEPOSIT: "E009",
  },
};
export type BillingCodeTypes = (typeof BillingCode)[keyof typeof BillingCode];

export const BillingNames = {
  [BillingCode.CARD.TOTAL_SALES]: "카드 매출",
  [BillingCode.CARD.UNDER_DEPOSIT]: "카드 차감금",
  [BillingCode.BAEMIN.TOTAL_SALES]: "배달의민족 매출",
  [BillingCode.BAEMIN.UNDER_DEPOSIT]: "배달의민족 차감금",
  [BillingCode.BAEMIN.CARD_SALES]: "카드 매출",
  [BillingCode.YOGIYO.TOTAL_SALES]: "요기요 매출",
  [BillingCode.YOGIYO.UNDER_DEPOSIT]: "요기요 차감금",
  [BillingCode.YOGIYO.CARD_SALES]: "카드 매출",
  [BillingCode.COUPANG.TOTAL_SALES]: "쿠팡이츠 매출",
  [BillingCode.COUPANG.UNDER_DEPOSIT]: "쿠팡이츠 차감금",
  [BillingCode.ETC.SERVICE_FEE]: "얼리페이 이용료",
  [BillingCode.ETC.FINANCIAL_INTEREST]: "선정산 이자",
  [BillingCode.ETC.ADDITIONAL_DEPOSIT]: "추가입금",
  [BillingCode.ETC.ETC_UNDER_DEPOSIT]: "기타 차감금",
};
export type BillingNamesTypes =
  (typeof BillingNames)[keyof typeof BillingNames];
