import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./ErrorScreen.module.scss";
import { ErrorScreenProps } from "./ErrorScreen.types";
import "@ui/styles/index.scss";
import { BoxButton, Icon, Text, VStack } from "@ui/components/atoms";
import { errorScreenData } from "@ui/components/templates/ErrorScreen/errorScreen.maps";
import { TriangleExclamationMarkSolidIcon } from "@earlybird/icons";

/**
 * `ErrorScreen` 는 사진 촬영 또는 불러오기를 실행하고 첨부 가이드를 같이 제공하여 필요한 이미지를 첨부하도록 유도하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <ErrorScreen
 * />
 * ```
 */
export const ErrorScreen = forwardRef<HTMLElement, ErrorScreenProps>(
  function ErrorScreen(
    {
      className,
      as,
      type = "404",
      status,
      isLoggedIn,
      title,
      message,
      handleGoToPrevious,
      handleClickActionButton,
      ...rest
    }: ErrorScreenProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";
    const errorScreenObject =
      type === "custom"
        ? { title, message, type }
        : errorScreenData.filter((item) => item.type === type)[0];
    const actionButtonLabel =
      status === "service-apply" && isLoggedIn
        ? "서비스 신청 단계로 돌아가기"
        : "홈으로 돌아가기";

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.ErrorScreen,
          "earlybird-error-screen",
          className,
        )}
      >
        <VStack spacing={6} align={"center"}>
          <Icon
            icon={TriangleExclamationMarkSolidIcon}
            color={"content-warning"}
            size={"3xl"}
          />
          <VStack spacing={4} align={"center"} width={335}>
            <Text typo={"subtitle-1"} center bold>
              {errorScreenObject.title}
            </Text>
            <Text color={"content-secondary"} center>
              {errorScreenObject.message}
            </Text>
          </VStack>
        </VStack>

        <VStack center spacing={4}>
          {/** 404 페이지일 때, 유저의 상태에 따라서 특정 페이지로 이동할 수 있는 액션 버튼을 보여줍니다. */}
          {type === "404" && (
            <BoxButton
              state={"fit"}
              size={"lg"}
              onClick={handleClickActionButton}
            >
              {actionButtonLabel}
            </BoxButton>
          )}

          <BoxButton
            state={"fit"}
            hierarchy={
              errorScreenObject.type === "404" ? "ghost" : "quaternary"
            }
            size={"lg"}
            onClick={handleGoToPrevious}
          >
            이전 화면으로
          </BoxButton>
        </VStack>
      </BaseComponent>
    );
  },
);

export default ErrorScreen;
