// 쿠키 불러오기
const getCookie = (cookieName: string): string | null => {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=');
    if (cookie[0] === cookieName) {
      return decodeURIComponent(cookie[1]);
    }
  }
  return null;
};

// 쿠키저장
const setCookie = (cookieName: string, value: string, days: number) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + days);

  document.cookie =
    cookieName +
    '=' +
    encodeURIComponent(value) +
    '; expires=' +
    exdate.toUTCString() +
    '; path=/; domain=.epayloan.kr;';
};

const deleteCookie = (cookieName: string) => {
  document.cookie = cookieName + '=; Max-Age=-99999999; path=/; domain=.epayloan.kr;';
};

export { getCookie, setCookie, deleteCookie };
