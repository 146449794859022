import React from "react";

import GuestLayout from "@routes/layouts/GuestLayout";
import InitializeServiceRoute from "@routes/utils/InitializeServiceRoute";
import { Navigate, useLocation } from "react-router-dom";

import { getCookie } from "@utils/cookie";

const GuestRouteGuard: React.FC = () => {
  const location = useLocation();
  const currentQuery = new URLSearchParams(location.search);
  const accessToken = getCookie("earlypay-token");

  if (accessToken) {
    return (
      <Navigate
        to={`/?${currentQuery.toString()}`}
        replace
        state={{ from: location }}
      />
    );
  }

  return (
    <InitializeServiceRoute>
      <GuestLayout />
    </InitializeServiceRoute>
  );
};

export default GuestRouteGuard;
