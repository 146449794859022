import type React from "react";
import { useCallback, useRef } from "react";

export const COMMON_IME_CONTROL_KEYS = [
  "Enter",
  "Escape",
  "Tab",
  " ",
  "ArrowUp",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
];

type HandlerCache<TargetElement extends HTMLElement = HTMLInputElement> = Map<
  React.KeyboardEventHandler<TargetElement>,
  React.KeyboardEventHandler<TargetElement>
>;
interface UseKeyboardActionLockerWhileComposingProps<
  TargetElement extends HTMLElement = HTMLInputElement
> {
  keysToLock?: string[];
  onKeyDown?: React.KeyboardEventHandler<TargetElement>;
  onKeyUp?: React.KeyboardEventHandler<TargetElement>;
}

const isSafari = () =>
  window.navigator.userAgent.search("Safari") >= 0 &&
  window.navigator.userAgent.search("Chrome") < 0;

export function useKeyboardActionLockerWhileComposing<
  TargetElement extends HTMLElement = HTMLInputElement
>({
  keysToLock,
  onKeyDown,
  onKeyUp,
}: UseKeyboardActionLockerWhileComposingProps<TargetElement>) {
  const handlerCache = useRef<HandlerCache<TargetElement>>(new Map());

  const wrapHandler = useCallback(
    (handler?: React.KeyboardEventHandler<TargetElement>) => {
      if (!handler) {
        return undefined;
      }
      if (handlerCache.current.has(handler)) {
        return handlerCache.current.get(handler);
      }

      const wrappedHandler = (event: React.KeyboardEvent<TargetElement>) => {
        // NOTE: If keysToLock is not provided, lock all keys.
        const isKeyLocked =
          event.nativeEvent.isComposing &&
          (!keysToLock ||
            keysToLock.some((controlKey) => event.key === controlKey));

        const isSafariKeydownWhileComposing =
          isSafari() && event.type === "keydown" && event.keyCode === 229;

        if (isKeyLocked || isSafariKeydownWhileComposing) {
          event.stopPropagation();
          return;
        }
        handler?.(event);
      };

      handlerCache.current.set(handler, wrappedHandler);
      return wrappedHandler;
    },
    [keysToLock]
  );

  return {
    handleKeyDown: wrapHandler(onKeyDown),
    handleKeyUp: wrapHandler(onKeyUp),
  };
}
