import ReactGA from "react-ga4";

export const initGA = () => {
  ReactGA.initialize(import.meta.env.VITE_GA_ID);
};

export const PageViewGA = (page: string) => {
  ReactGA.set({ page: page });
  ReactGA.send("pageview");
};
