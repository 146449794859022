import { getPixelValue } from "@earlypay/shared/utils/types";
import { HStack, Icon, Text } from "@ui/components/atoms";
import "@ui/styles/index.scss";
import classNames from "classnames";
import { ForwardedRef, forwardRef, useContext } from "react";
import { ToastContext, ToastVisibleContext } from "./Toast.context";
import styles from "./Toast.module.scss";
import { ToastProps } from "./Toast.types";
import { changeStateToIcon, changeStateToIconColor } from "./utils";

/**
 * `Toast` 는 다양한 레이아웃과 스타일 속성을 쉽게 적용하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <BoxButton
 *   onClick={() => toast.addToast("메세지", "success")}
 * >
 * </BoxButton>
 * ```
 */
export const Toast = forwardRef<HTMLElement, ToastProps>(function Toast(
  {
    className,
    as,
    width,
    hasAction,
    placement = "top",
    state = "neutral",
    message,
    ...rest
  }: ToastProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const toast = useToast();

  const BaseComponent = as ?? "div";
  const w = getPixelValue(width);

  const style = {
    width: w,
  };

  return (
    <div
      className={classNames(
        styles.ToastWrapper,
        styles[`placement-${placement}`],
        "earlybird-toast-wrapper",
      )}
    >
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.Toast,
          !!toast.visible ? styles["toast--fadeIn"] : styles["toast--fadeOut"],
          className,
        )}
        style={style}
      >
        <HStack spacing={4} align={"center"}>
          {state !== "neutral" && (
            <Icon
              icon={changeStateToIcon(state)}
              color={changeStateToIconColor(state)}
            />
          )}
          <Text type={"body-3"} color={"content-on-color"} bold>
            {message}
          </Text>
        </HStack>
      </BaseComponent>
    </div>
  );
});

export const useToast = () => {
  const context = useContext(ToastContext);
  const visibleContext = useContext(ToastVisibleContext);

  if (!context) {
  }
  return { ...context, visible: visibleContext?.visibleToast };
};

export default Toast;
