import {
  ColorsTypes,
  RadiusTypes,
  SpacingTypes,
  TypographyTypes,
} from "@ui/typings/tokens";

export function changeTypographyToClassName(type?: TypographyTypes) {
  return `earlybird-text--${type}`;
}

export function changeColorToClassName(type?: ColorsTypes) {
  return `earlybird-color--${type}`;
}

export function changeRadiusToClassName(type?: RadiusTypes) {
  return `earlybird-radius--${type}`;
}

export function changeSpacingToClassName(type?: SpacingTypes) {
  return `earlybird-spacing--${type}`;
}
