import { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./AccordionMenu.module.scss";
import { AccordionMenuProps } from "./AccordionMenu.types";
import "@ui/styles/index.scss";
import { Icon, Text } from "@ui/components/atoms";
import { ChevronRightIcon } from "@earlybird/icons";

/**
 * `AccordionMenu` 는 메뉴 리스트를 표현하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <AccordionMenu
 *   title={"고객센터"}
 *   onClick={onClick}
 * />
 * ```
 */
export const AccordionMenu = forwardRef<HTMLElement, AccordionMenuProps>(
  function AccordionMenu(
    {
      className,
      as,
      title,
      onClick,
      underline,
      bold,
      link,
      target = "_blank",
      icon = ChevronRightIcon,
      ...rest
    }: AccordionMenuProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ? as : link ? "a" : "button";

    return (
      <BaseComponent
        ref={forwardedRef}
        className={classNames(
          styles.AccordionMenu,
          "earlybird-accordion-menu",
          className,
        )}
        href={link}
        target={target}
        type={"button"}
        onClick={onClick}
        {...rest}
      >
        <Text underline={underline} bold={bold}>
          {title}
        </Text>
        <Icon icon={ChevronRightIcon} />
      </BaseComponent>
    );
  },
);

export default AccordionMenu;
