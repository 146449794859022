export const trackKakaoPixelPageView = () => {
  window.kakaoPixel(import.meta.env.VITE_KAKAO_PIXEL_TOKEN).pageView();
};

// 회원가입
export const trackKakaoPixelCompleteRegistration = () => {
  window
    .kakaoPixel(import.meta.env.VITE_KAKAO_PIXEL_TOKEN)
    .completeRegistration();
};

// 잠재고객 이벤트 (신용심사 승인 완료)
export const trackKakaoPixelParticipation = () => {
  window.kakaoPixel(import.meta.env.VITE_KAKAO_PIXEL_TOKEN).participation();
};

// 서비스신청완료 (추가서류 제출 완료 단계)
export const trackKakaoPixelSignUp = () => {
  window.kakaoPixel(import.meta.env.VITE_KAKAO_PIXEL_TOKEN).signUp();
};
