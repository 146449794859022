/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';

export interface HanaCapitalTermsLayoutProps {
  children: React.ReactNode;
}

export const HanaCapitalTermsLayout: React.FC<HanaCapitalTermsLayoutProps> = ({ children }) => {
  const windowHeight = window.innerHeight;

  return <Wrapper height={windowHeight}>{children}</Wrapper>;
};

export default HanaCapitalTermsLayout;

const Wrapper = styled.div`
  max-width: 600px;
  border-left: 1px solid rgb(224, 224, 224);
  border-right: 1px solid rgb(224, 224, 224);
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 0 auto;
  height: ${(props: { [key: string]: string }) =>
    props.height !== null
      ? typeof props.height === 'string'
        ? props.height
        : props.height + 'px'
      : '100%'};
  position: relative;
  overflow: scroll;
`;
