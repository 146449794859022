import { BoxButtonSize, BoxButtonHierarchyTypes } from "./BoxButton.types";

export const changeSizeToFontSize = (size: BoxButtonSize) => {
  switch (size) {
    case "lg":
      return "body-1";
    case "md":
      return "body-2";
    case "sm":
      return "body-3";
    case "xs":
      return "body-3";
    default:
      return "body-2";
  }
};

export const changeTypeToTextColor = (type: BoxButtonHierarchyTypes) => {
  switch (type) {
    case "primary":
      return "content-on-color";
    case "secondary":
      return "content-highlight";
    case "tertiary":
      return "content-secondary";
    case "quaternary":
      return "content-secondary";
    case "delete":
      return "content-on-color";
    case "ghost":
      return "content-tertiary";
    default:
      return "content-secondary";
  }
};
