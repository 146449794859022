import React, { useLayoutEffect } from "react";

import { ApplicationStatusCode, getCookie } from "@earlypay/shared";
import { applicationState } from "@recoil/applications/atoms";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useRedirectUsers } from "@apis/hooks/users";

/** 얼리페이 대부에서 로그인이 필요한 페이지 전체를 가드하는 Route 컴포넌트입니다. */
const ProtectedMainRoute = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentQuery = location.search;
  const accessToken = getCookie("earlypay-token");
  const application = useRecoilValue(applicationState);
  const mutation = useRedirectUsers(application.user.id);

  const status = application.status; // 서비스 신청서 상태
  const currentPath = location.pathname;

  /** accessToken 이 없는 경우, 로그인 또는 랜딩 페이지로 이동합니다. */
  if (!accessToken) {
    return (
      <Navigate
        to={`/login${currentQuery}`}
        replace
        state={{ from: location }}
      />
    );
  }

  const checkApplicationStatus = () => {
    if (location.pathname === "/") {
      navigate(`/service-apply${currentQuery}`);
    }

    if (
      status === ApplicationStatusCode.WAITING_REVIEW &&
      !currentPath.endsWith("/submit")
    ) {
      return navigate(`/service-apply/submit${currentQuery}`, {
        state: { from: location },
      });
    }

    if (
      status === ApplicationStatusCode.NEED_COMPLEMENT &&
      !currentPath.endsWith("/incomplete")
    ) {
      return navigate(`/service-apply/screening/incomplete${currentQuery}`, {
        state: { from: location },
      });
    }

    if (
      status === ApplicationStatusCode.CANCELLED &&
      !currentPath.endsWith("/restart")
    ) {
      return navigate(`/service-apply/screening/restart${currentQuery}`, {
        state: { from: location },
      });
    }

    if (
      status === ApplicationStatusCode.EXPIRED &&
      !currentPath.endsWith("/expired")
    ) {
      return navigate(`/service-apply/screening/expired${currentQuery}`, {
        state: { from: location },
      });
    }

    if (
      status === ApplicationStatusCode.REJECTED_CREDIT &&
      (!currentPath.endsWith("/retry") ||
        !currentPath.endsWith("/credit-rejected"))
    ) {
      const now = new Date();
      const past = new Date(application.statusChangedAt);
      const timeDiff = now.getTime() - past.getTime();
      const hoursPassed = timeDiff / (1000 * 60 * 60);

      // 취소한 지 24시간이 지났을 경우, retry 페이지로 이동합니다.
      if (hoursPassed >= 24) {
        return navigate(`/service-apply/screening/retry${currentQuery}`, {
          state: { from: location },
        });
      }

      return navigate(
        `/service-apply/screening/credit-rejected${currentQuery}`,
        {
          state: { from: location },
        },
      );
    }

    if (
      status === ApplicationStatusCode.REJECTED_OPERATION &&
      !currentPath.endsWith("/admin-rejected")
    ) {
      return navigate(
        `/service-apply/screening/admin-rejected${currentQuery}`,
        {
          state: { from: location },
        },
      );
    }

    return;
  };

  useLayoutEffect(() => {
    if (application.id) {
      // 신청서가 승인된 경우, 대부에서 페이(사장님 페이지)로 이동합니다.
      if (application.status === ApplicationStatusCode.APPROVED) {
        return mutation.mutate(`/${currentQuery}`);
      }

      // 서비스 신청서 상태를 확인하고 심사 결과 페이지로 이동 여부를 결정합니다.
      checkApplicationStatus();
    }
  }, [application]);

  return <>{children}</>;
};

export default ProtectedMainRoute;
