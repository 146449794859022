import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { applicationState } from "@recoil/applications/atoms";

export const HanaCapitalPrivateRoute = (): React.ReactElement => {
  const navigate = useNavigate();
  const application = useRecoilValue(applicationState);

  useEffect(() => {
    if (!application.isHanaCapital) {
      navigate("/403");
    }
  }, [application]);

  return <Outlet />;
};
