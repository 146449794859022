import { ToastState } from "@ui/components/atoms/Toast/Toast.types";
import {
  CircleCheckSolidIcon,
  TriangleExclamationMarkSolidIcon,
} from "@earlybird/icons";

export const changeStateToIcon = (state: ToastState) => {
  switch (state) {
    case "success":
      return CircleCheckSolidIcon;
    case "warning":
      return TriangleExclamationMarkSolidIcon;
    case "negative":
      return TriangleExclamationMarkSolidIcon;
    default:
      return CircleCheckSolidIcon;
  }
};

export const changeStateToIconColor = (state: ToastState) => {
  switch (state) {
    case "success":
      return "content-positive";
    case "warning":
      return "content-warning";
    case "negative":
      return "content-negative";
    default:
      return "content-positive";
  }
};
