import { StateType } from "@ui/components/atoms/InfoBox/InfoBox.types";
import {
  CircleCheckSolidIcon,
  CircleISolidIcon,
  TriangleExclamationMarkSolidIcon,
} from "@earlybird/icons";

export const changeStateToColor = (state: StateType) => {
  switch (state) {
    case "success":
      return "content-highlight";
    case "warning":
      return "content-negative";
    case "info":
      return "content-secondary";
    default:
      return "content-highlight";
  }
};

export const changeStateToIcon = (state: StateType) => {
  switch (state) {
    case "success":
      return CircleCheckSolidIcon;
    case "warning":
      return TriangleExclamationMarkSolidIcon;
    case "info":
      return CircleISolidIcon;
    default:
      return CircleCheckSolidIcon;
  }
};
