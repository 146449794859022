import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./ImageUploadGuide.module.scss";
import { ImageUploadGuideProps } from "./ImageUploadGuide.types";
import "@ui/styles/index.scss";
import { ActionButton, Container, PageHeader } from "@ui/components/layouts";
import { Box, Image, Text, VStack } from "@ui/components/atoms";
import { guideData } from "@ui/components/organisms/ImageUploadGuide/dataSetArray";

/**
 * `ImageUploadGuide` 는 사진 촬영 또는 불러오기를 실행하고 첨부 가이드를 같이 제공하여 필요한 이미지를 첨부하도록 유도하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <ImageUploadGuide
 *   guide="identification"
 *   visible={true}
 *   onClose={onClose}
 * />
 * ```
 */
export const ImageUploadGuide = forwardRef<HTMLElement, ImageUploadGuideProps>(
  function ImageUploadGuide(
    {
      className,
      as,
      visible,
      onClose,
      handleClickImageButton,
      guide,
      loading,
      ...rest
    }: ImageUploadGuideProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const data = guideData[guide];

    const GuideCard = ({
      image,
      title,
      isOne = false,
    }: {
      image: string;
      title: string;
      isOne?: boolean;
    }) => (
      <VStack
        className={classNames(isOne ? styles.GuideCardOne : styles.GuideCard)}
      >
        <Image
          className={classNames(styles.GuideImage)}
          src={image}
          width={"100%"}
        />
        <Box
          center
          height={52}
          width={"100%"}
          bg={"bg-secondary"}
          className={classNames(styles.GuideTextBox)}
        >
          <Text typo={"body-3"} bold center>
            {title}
          </Text>
        </Box>
      </VStack>
    );

    return (
      <div className={classNames(styles.ImageUploadGuide)}>
        <Container>
          <PageHeader
            hasRightButton={false}
            handleClickLeftButton={onClose}
          ></PageHeader>
          <VStack padding={20} height={"100%"} width={"100%"} spacing={16}>
            <Text typo={"subtitle-1"} bold>
              사진 첨부 전 꼭 확인해주세요
            </Text>
            <Box
              display={"grid"}
              width={"100%"}
              className={classNames(
                data.length === 1 ? styles.NoGuideBox : styles.GuideBox,
                "earlybird-image-upload-guide",
              )}
            >
              {data.map(
                (
                  { image, title }: { image: string; title: string },
                  index: number,
                ) => {
                  if (data.length === 1) {
                    return (
                      <GuideCard
                        key={index}
                        image={image}
                        title={title}
                        isOne={true}
                      />
                    );
                  }

                  return <GuideCard key={index} image={image} title={title} />;
                },
              )}
            </Box>
          </VStack>

          <ActionButton
            primaryButtonLabel={"확인했어요"}
            onClickPrimaryButton={handleClickImageButton}
            loading={loading}
          />
        </Container>
      </div>
    );
  },
);

export default ImageUploadGuide;
