import { PlacementTypes } from "@ui/components/atoms/Tooltip/Tooltip.types";

const POINTER_MARGIN = 6;
const POINTER_WIDTH = 10;
const POINTER_HEIGHT = 4;

export const changePlacementWrapperToStyle = (
  placement: PlacementTypes,
  offset: number,
) => {
  switch (placement) {
    case "top-left":
      return {
        top: offset + -POINTER_MARGIN,
        left: 0,
        transform: "translate(0%, -100%)",
        flexDirection: "column",
      };
    case "top-center":
      return {
        top: offset + -POINTER_MARGIN,
        left: "50%",
        transform: "translate(-50%, -100%)",
        alignItems: "center",
        flexDirection: "column",
      };
    case "top-right":
      return {
        top: offset + -POINTER_MARGIN,
        right: 0,
        transform: "translate(0%, -100%)",
        alignItems: "end",
        flexDirection: "column",
      };
    case "right-top":
      return {
        top: 0,
        right: offset + -POINTER_MARGIN,
        transform: "translate(100%, 0%)",
        alignItems: "center",
        flexDirection: "row",
      };
    case "right-center":
      return {
        top: 0,
        bottom: 0,
        right: offset + -POINTER_MARGIN,
        transform: "translate(100%, 0%)",
        alignItems: "center",
        flexDirection: "row",
      };
    case "right-bottom":
      return {
        bottom: 0,
        right: offset + -POINTER_MARGIN,
        transform: "translate(100%, 0%)",
        alignItems: "center",
        flexDirection: "row",
      };
    case "bottom-left":
      return {
        bottom: offset + -POINTER_MARGIN,
        transform: "translate(0%, 100%)",
        flexDirection: "column-reverse",
      };
    case "bottom-center":
      return {
        bottom: offset + -POINTER_MARGIN,
        left: POINTER_WIDTH,
        transform: "translate(50%, 100%)",
        flexDirection: "column-reverse",
        alignItems: "center",
      };
    case "bottom-right":
      return {
        bottom: offset + -POINTER_MARGIN,
        right: 0,
        transform: "translate(0%, 100%)",
        flexDirection: "column-reverse",
        alignItems: "end",
      };
    case "left-top":
      return {
        top: 0,
        left: offset + -POINTER_MARGIN,
        transform: "translate(-100%, 0%)",
        flexDirection: "row-reverse",
        alignItems: "center",
      };
    case "left-center":
      return {
        top: 0,
        bottom: 0,
        left: offset + -POINTER_MARGIN,
        transform: "translate(-100%, 0%)",
        flexDirection: "row-reverse",
        alignItems: "center",
      };
    case "left-bottom":
      return {
        bottom: 0,
        left: offset + -POINTER_MARGIN,
        transform: "translate(-100%, 0%)",
        flexDirection: "row-reverse",
        alignItems: "center",
      };
    default:
      return null;
  }
};

export const changePlacementPointerToStyle = (
  placement: PlacementTypes,
  offset: number,
) => {
  const offsetPointer = offset ? offset - 3 : 0;

  switch (placement) {
    case "top-left":
      return {
        top: offsetPointer + POINTER_HEIGHT,
        transform: "translate(100%, 100%)",
      };
    case "top-center":
      return {
        top: offsetPointer + POINTER_HEIGHT,
        left: "50%",
        transform: "translate(-50%, 100%)",
      };
    case "top-right":
      return {
        top: offsetPointer + POINTER_HEIGHT,
        transform: "translate(-100%, 100%)",
      };
    case "right-top":
      return {
        left: offsetPointer + POINTER_HEIGHT,
        transform: "translate(-100%, 0%) rotate(90deg)",
      };
    case "right-center":
      return {
        left: offsetPointer + POINTER_HEIGHT,
        transform: "translate(-100%, 0%) rotate(90deg)",
      };
    case "right-bottom":
      return {
        left: offsetPointer + POINTER_HEIGHT,
        transform: "translate(-100%, 0%) rotate(90deg)",
      };
    case "bottom-left":
      return {
        bottom: offsetPointer + POINTER_HEIGHT,
        transform: "translate(100%, -100%) rotate(180deg)",
      };
    case "bottom-center":
      return {
        bottom: offsetPointer + POINTER_HEIGHT,
        transform: "translate(0%, -100%) rotate(180deg)",
      };
    case "bottom-right":
      return {
        bottom: offsetPointer + POINTER_HEIGHT,
        transform: "translate(-100%, -100%) rotate(180deg)",
      };
    case "left-top":
      return {
        right: offsetPointer + POINTER_HEIGHT,
        transform: "translate(100%, 0%) rotate(270deg)",
      };
    case "left-center":
      return {
        right: offsetPointer + POINTER_HEIGHT,
        transform: "translate(100%, 0%) rotate(270deg)",
      };
    case "left-bottom":
      return {
        right: offsetPointer + POINTER_HEIGHT,
        transform: "translate(100%, 0%) rotate(270deg)",
      };
    default:
      return null;
  }
};
