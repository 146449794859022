import React, { useState, useLayoutEffect } from "react";

const useIsVisibleElement = <T extends HTMLElement>(
  elementRef: React.RefObject<T>,
  options?: IntersectionObserverInit
): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    if (!elementRef.current) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry) {
        setIsVisible(entry.isIntersecting);
      }
    }, options);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      return observer.disconnect();
    };
  }, [elementRef, options]);

  return isVisible;
};

export default useIsVisibleElement;
