import React, { useEffect } from "react";

import { trackEventMixpanel } from "@earlypay/shared";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  LottieAnimation,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

export const Gateway = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentQuery = location.search;

  useEffect(() => {
    const interval = setInterval(() => {
      trackEventMixpanel("complete_gateway", {
        description: "대부 페이지 진입 단계 완료",
      });
      navigate(`/service-apply/credit-agreement${currentQuery}`);
    }, 1800);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"이페이론 진입 페이지"} />
      <Header hasLeftButton={false}></Header>
      <PageContainer padding={20} spacing={16}>
        <VStack spacing={4}>
          <Text color={"content-secondary"}>얼리페이 대부로 이동 중이에요</Text>
          <Text typo={"subtitle-1"} bold>
            신청서 작성을 계속하기 위해 잠시 이동할게요!
          </Text>
        </VStack>

        <Box width={"100%"} center>
          <LottieAnimation
            src={"paper-plane-with-logo"}
            width={240}
            height={240}
          />
        </Box>
      </PageContainer>
    </>
  );
};

export default Gateway;
