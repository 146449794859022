import { ChangeEvent, useState } from "react";

import {
  DeliveryCode,
  DeliveryCodeTypes,
  deliveryAccountSchema,
  trackEventMixpanel,
} from "@earlypay/shared";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import {
  ActionButton,
  DeliveryAccountModal,
  DeliveryAccountViewList,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { Header } from "@components/layouts";

import { useServiceApplyStageMessage } from "@/apis";
import {
  useAddPlatformAccount,
  useDeletePlatformAccount,
  useFetchPlatformAccounts,
  useUpdatePlatformAccount,
} from "@/apis/hooks/platforms";
import { applicationState } from "@/recoil/applications/atoms";

import Meta from "../../../components/layouts/Meta";

const DeliveryAccount = () => {
  const application = useRecoilValue(applicationState);
  const navigate = useNavigate();
  const location = useLocation();
  const currentQuery = location.search;
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(DeliveryCode.BAEMIN);

  const { data: platformAccounts } = useFetchPlatformAccounts(application.id);
  const requiredPlatforms = Object.values(DeliveryCode);
  const transformedList = requiredPlatforms?.map(platform => {
    const item = platformAccounts?.find(item => item.platform === platform);
    return {
      code: platform,
      value: item ? item.identification : "",
      status: "VALID",
    };
  });
  const selectedAccount = platformAccounts?.filter(
    item => item.platform === selectedPlatform,
  )[0];

  const eventMutation = useServiceApplyStageMessage(application.id, {
    stage: "DELIVERY_ACCOUNT",
  });
  const addMutation = useAddPlatformAccount(application.id, selectedPlatform);
  const updateMutation = useUpdatePlatformAccount(
    selectedPlatform,
    application.id,
    selectedAccount?.id,
  );
  const deleteMutation = useDeletePlatformAccount(
    selectedPlatform,
    application.id,
    selectedAccount?.id,
  );

  const methods = useForm({
    resolver: yupResolver(deliveryAccountSchema),
    defaultValues: {
      deliveryAccount: { id: "", password: "" },
    },
    mode: "onChange",
  });

  const handleGoToNext = () => {
    eventMutation.mutate(); // 믹스패널 및 슬랙 알림 이벤트를 호출합니다.
    trackEventMixpanel("complete_delivery_agent");
    navigate(`/service-apply/delivery-agency${currentQuery}`);
  };

  const handleAddDeliveryAccount = (code: DeliveryCodeTypes) => {
    addMutation.mutate({
      platform: code,
      identification: methods.getValues("deliveryAccount").id,
      password: methods.getValues("deliveryAccount").password,
    });
    setVisibleModal(false);
  };

  const handleEditDeliveryAccount = () => {
    updateMutation.mutate({
      identification: methods.getValues("deliveryAccount").id,
      password: methods.getValues("deliveryAccount").password,
    });
    setVisibleModal(false);
  };

  const handleDeleteDeliveryAccount = () => {
    deleteMutation.mutate();
    setVisibleModal(false);
  };

  const handleOpenDeliveryAccountModal = (code: DeliveryCodeTypes) => {
    methods.reset();
    setVisibleModal(true);
    setSelectedPlatform(code);
  };

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"배달앱 계정 등록"} />
      <FormProvider {...methods}>
        <Form noValidate>
          <Header
            previous={
              application.withdrawalAccount.account
                ? "/service-apply/withdrawal-account/verify"
                : "/service-apply/withdrawal-account"
            }
          ></Header>

          <PageContainer spacing={16}>
            <VStack spacing={4}>
              <Text typo={"subtitle-1"} bold>
                {"매출 연동을 위해 배달앱\n계정을 등록해주세요"}
              </Text>
              <Text color={"content-secondary"}>
                선정산 받을 배달사 모두를 등록해 주시면 돼요.
              </Text>
            </VStack>

            <DeliveryAccountViewList
              platformList={transformedList}
              handleClickPlatform={handleOpenDeliveryAccountModal}
            />

            <Controller
              control={methods.control}
              name={"deliveryAccount"}
              render={({ field }) => {
                const loading = addMutation.isPending;
                const disabled =
                  !field.value.id.length || !field.value.password.length;
                const oldAccount = transformedList.filter(
                  item => item.code === selectedPlatform,
                )[0];

                const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
                  const { name, value } = e.target;

                  field.onChange({
                    ...field.value,
                    [name]: value,
                  });
                };

                return (
                  <DeliveryAccountModal
                    name={"deliveryAccount"}
                    visible={visibleModal}
                    onClose={() => setVisibleModal(false)}
                    platform={selectedPlatform}
                    handleChange={handleChange}
                    oldId={oldAccount.value}
                    value={{
                      id: field.value.id,
                      password: field.value.password,
                    }}
                    disabled={disabled}
                    loading={loading}
                    handleAddDeliveryAccount={handleAddDeliveryAccount}
                    handleEditDeliveryAccount={handleEditDeliveryAccount}
                    handleDeleteDeliveryAccount={handleDeleteDeliveryAccount}
                  />
                );
              }}
            />
          </PageContainer>

          <ActionButton
            type={"button"}
            buttonType="single"
            primaryButtonLabel="다음"
            onClickPrimaryButton={handleGoToNext}
            disabledPrimaryButton={!platformAccounts?.length}
            loading={
              deleteMutation.isPending ||
              updateMutation.isPending ||
              addMutation.isPending
            }
            primaryButtonProperty={{
              description: "배달앱 계정 등록 버튼",
            }}
          />
        </Form>
      </FormProvider>
    </>
  );
};

export default DeliveryAccount;

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
