import { checkSameDay, getMonth, getYear } from "@earlypay/shared/utils";
import { Box, Text, VStack } from "@ui/components/atoms";
import "@ui/styles/index.scss";
import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";
import styles from "./CalendarDates.module.scss";
import { CalendarDatesProps } from "./CalendarDates.types";
import DailyContents from "./DailyContents";

/**
 * `CalendarDates` 는 날짜를 캘린더 형태로 보여준는 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <CalendarDates
 *   year={2024}
 *   month={8}
 *   loading={loading}
 *   selectedDate={selectedDate}
 *   startDate={startDate}
 *   endDate={endDate}
 *   handleNavigateDate={handleNavigateDate}
 * />
 * ```
 */
export const CalendarDates = forwardRef<HTMLElement, CalendarDatesProps>(
  function CalendarDates(
    {
      className,
      displayMode,
      year = getYear(),
      month = getMonth(),
      dailyContentsType = "none",
      dailyAmountContents,
      loading = false,
      startDate,
      endDate,
      selectedDate = new Date(),
      handleNavigateDate,
      as,
      ...rest
    }: CalendarDatesProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";

    const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];

    // 현재 월의 첫 번째 날
    const firstDay = new Date(year, month - 1, 1);
    const startDay = firstDay.getDay();

    // 해당 월의 총 일 수 계산
    const daysInMonth = new Date(year, month, 0).getDate();

    // 날짜 배열 생성
    const dates = Array.from({ length: startDay + daysInMonth }, (_, index) => {
      if (index < startDay) {
        return null; // 빈 공간
      } else {
        return index - startDay + 1; // 실제 날짜 값
      }
    });

    const handleClickDate = (date: Date | null) => {
      if (!date || isNaN(date.getTime())) return;
      if (date > endDate || date < startDate) return;

      handleNavigateDate(date);
    };

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.CalendarDates,
          "earlybird-calendar-dates",
          className,
        )}
      >
        {/* 요일 헤더 */}
        <div className={classNames(styles.CalendarDaysRow)}>
          {daysOfWeek.map((day, index) => (
            <Box width={"100%"} center key={index} padding={"4px 0"}>
              <Text typo={"body-3"} color={"content-tertiary"}>
                {day}
              </Text>
            </Box>
          ))}
        </div>

        {/* 날짜 그리드 */}
        <Box width={"100%"} className={classNames(styles.CalendarGrid)}>
          {dates.map((date: number, index: number) => {
            const currentDate = !date ? null : new Date(year, month - 1, date);

            const isSelected = date && checkSameDay(selectedDate, currentDate);
            const isActive =
              date && currentDate <= endDate && currentDate >= startDate;

            const amountContents =
              dailyAmountContents?.filter((item) =>
                checkSameDay(item.date, currentDate),
              ) || null;

            return (
              <Box
                className={classNames(
                  styles.CalendarDate,
                  isActive && styles["active"],
                )}
                key={index}
                width={"100%"}
                align={"center"}
                onClick={() => handleClickDate(currentDate)}
              >
                <VStack align={"center"}>
                  <Box
                    center
                    bg={isSelected ? "bg-informative" : "transparent"}
                    radius={"circle"}
                    width={24}
                    height={24}
                    padding={"1px 0 0 0"}
                  >
                    <Text
                      color={
                        !isActive
                          ? "content-disabled"
                          : isSelected
                            ? "content-on-color"
                            : "content-secondary"
                      }
                      bold
                    >
                      {date || ""}
                    </Text>
                  </Box>
                  {isActive && (
                    <DailyContents
                      loading={loading}
                      type={dailyContentsType}
                      amounts={amountContents[0]?.amounts || null}
                    />
                  )}
                </VStack>
              </Box>
            );
          })}
        </Box>
      </BaseComponent>
    );
  },
);

export default CalendarDates;
