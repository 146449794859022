import { useQuery } from "@tanstack/react-query";
import { platformsQueryKeys } from "./queryKeys";
import { getCookie } from "@shared/utils/cookies";
import { PlatformAccountsTypes } from "@shared/typings";
import { getPlatformAccounts } from "@/apis";

export const useFetchPlatformAccounts = (applicationId: number) => {
  const accessToken = getCookie("earlypay-token");

  return useQuery({
    queryKey: platformsQueryKeys.fetchPlatformsAccount(applicationId).queryKey,
    queryFn: () => getPlatformAccounts(applicationId),
    select: (data: {
      ok: boolean;
      platformAccounts: PlatformAccountsTypes[];
    }) => data.platformAccounts,
    enabled: !!accessToken && !!applicationId,
    retry: 0,
  });
};
