export const EARLYPAY_SERVICE_GUIDE = "https://bit.ly/3M0PyWk";
export const KN_BANK_APP_ANDROID =
  "https://play.google.com/store/apps/details?id=com.knb.psb";

export const KN_BANK_APP_IOS =
  "https://apps.apple.com/kr/app/bnk%EA%B2%BD%EB%82%A8%EC%9D%80%ED%96%89-%EB%AA%A8%EB%B0%94%EC%9D%BC%EB%B1%85%ED%82%B9/id678852685";

export const TERMS_PRIVACY_POLICY =
  "https://app.catchsecu.com/document/P/702da9c1780f2dc";

export const TERMS_SERVICE_AGREEMENT = "https://earlypay.kr/terms/EP02";

export const BAEMIN_FIND_ACCOUNT =
  "https://biz-member.baemin.com/find-account?returnUrl=https%3A%2F%2Fceo.baemin.com%2F";
export const YOGIYO_FIND_ID = "https://ceo.yogiyo.co.kr/forgot-id/";
export const YOGIYO_FIND_PW =
  "https://ceo.yogiyo.co.kr/forgot-password/authentication";
export const COUPANG_FIND_ID =
  "https://store.coupangeats.com/merchant/pwreset/findid";
export const COUPANG_FIND_PW = "https://store.coupangeats.com/merchant/pwreset";

export const NOTION_FAQ_GUIDE =
  "https://www.notion.so/earlypay/31941937da0f4d6db09a22c1f16d66b5";

export const ADMIN_SERVICE_APPLICATION =
  "https://admin.earlypay.co.kr/admin/applications/application";

export const SLACK_WEBHOOK_TEST =
  "https://hooks.slack.com/services/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";

export const SLACK_WEBHOOK_SERVICE_APPLY =
  "https://hooks.slack.com/services/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";
