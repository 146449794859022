export const getIconSize = (size: string) => {
  switch (size) {
    case "lg":
      return "xl";
    case "md":
      return "lg";
    case "sm":
      return "md";
    case "xs":
      return "sm";
  }
};
