import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";

import "@ui/styles/index.scss";
import {
  changeColorToClassName,
  changeTypographyToClassName,
} from "@ui/utils/className";
import styles from "./Text.module.scss";
import { type TextProps } from "./Text.types";

/**
 * `Text` 는 TypographyTypes 를 표현하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Text
 *   typo={"body-2"}
 *   color={"content-primary"}
 * >
 *   Hello, Earlypay!
 * </Text>
 * ```
 */
export const Text = forwardRef<HTMLElement, TextProps>(function Text(
  {
    className,
    children = "레이블",
    as,
    typo = "body-2",
    color = "content-primary",
    weight = "regular",
    bold,
    light,
    italic,
    underline = false,
    center = false,
    truncated,
    align = "left",
    style,
    ...rest
  }: TextProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "span";

  return (
    <BaseComponent
      ref={forwardedRef}
      className={classNames(
        styles.Text,
        weight && styles[`font-weight-${weight}`],
        bold && styles.bold,
        light && styles.light,
        center && styles.center,
        italic && styles.italic,
        underline && styles.underline,
        truncated && styles.truncated,
        align && styles[`align-${align}`],
        center && styles[`align-center`],
        changeTypographyToClassName(typo),
        changeColorToClassName(color),
        "earlybird-text",
        className,
      )}
      role={"text-box"}
      style={{ ...style }}
      {...rest}
    >
      {children}
    </BaseComponent>
  );
});

export default Text;
