export const errorScreenData = [
  {
    type: "500",
    title: "오류가 발생했어요\n잠시 후 다시 시도해주세요",
    message:
      "빠르게 해결하려고 노력 중이에요.\n잠시 후 다시 시도해보시고, 지속적으로 오류가\n발생하면 고객센터로 문의해주세요.",
  },
  {
    type: "404",
    title: "페이지의 주소가 정확한지\n다시 한번 확인해주세요",
    message:
      "페이지의 주소가 잘못 입력되었거나\n변경 혹은 삭제되어 찾을 수 없어요.",
  },
  {
    type: "403",
    title: "접근 권한이 없어요",
    message: "궁금한 점이 있으시다면 고객센터로 문의해주세요.",
  },
];
