import { createWorker } from "tesseract.js";

/**
 * base64 로 인코딩 된 이미지를 받아 string 값을 추출합니다.
 * @param imageUrl base64 image file
 * @returns string
 */
export const extractTextFromImage = async (
  imageUrl: string
): Promise<string> => {
  const worker = await createWorker();
  await worker.load();
  await worker.loadLanguage("eng");
  await worker.initialize("eng");
  const {
    data: { text },
  } = await worker.recognize(imageUrl);
  await worker.terminate();

  return text;
};
