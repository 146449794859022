import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";

import { Text, VStack } from "@earlybird/ui";

import Meta from "../../components/layouts/Meta";

const Landing = () => {
  return (
    <>
      <Meta title={"이페이론 | 랜딩페이지"} name={"랜딩페이지"} />
      <VStack spacing={4}>
        <Text>대부업등록번호 : 2021-서울서초-00499(대부업)</Text>
        <Text>대부중개업등록번호 : 2021-서울서초-0050(대부중개업)</Text>
      </VStack>
    </>
  );
};

export default Landing;
