import { createQueryKeys } from "@lukemorales/query-key-factory";

export const deliveryQueryKeys: ReturnType<typeof createQueryKeys> =
  createQueryKeys("delivery", {
    fetchDeliveryAgents: applicationId => [
      "fetchDeliveryAgents",
      applicationId,
    ],
    updateDeliveryAgents: applicationId => [
      "updateDeliveryAgents",
      applicationId,
    ],
  });
