export const getS3TermsFile = (fileKey: string): string => {
  return `${import.meta.env.VITE_S3_URI}/terms/current/${fileKey}`;
};

export const addUserInfoToTerms = (
  username: string,
  date: string,
  signature: string,
  businessLicense: number,
  htmlContent: string,
) => {
  let updatedHtmlContent = htmlContent;

  if (username && !updatedHtmlContent.includes(`신청자 : ${username}`)) {
    updatedHtmlContent = updatedHtmlContent.replace(
      /신청자\s*:/g,
      `신청자 : ${username}`,
    );
  }

  if (date && !updatedHtmlContent.includes(`신청일 : ${date}`)) {
    updatedHtmlContent = updatedHtmlContent.replace(
      /신청일\s*:/g,
      `신청일 : ${date}`,
    );
  }

  if (
    businessLicense &&
    !updatedHtmlContent.includes(`사업자 등록번호 : ${businessLicense}`)
  ) {
    const businessLicenseNumber = businessLicense.toString();
    const customBusinessLicense = `${businessLicenseNumber.slice(0, 2)}-${businessLicenseNumber.slice(2, 5)}-${businessLicenseNumber.slice(5)}`;
    updatedHtmlContent = updatedHtmlContent.replace(
      /사업자 등록번호\s*:/g,
      `사업자 등록번호 : ${customBusinessLicense}`,
    );
  }

  if (signature && !updatedHtmlContent.includes(`id="earlypay-signature"`)) {
    updatedHtmlContent = updatedHtmlContent.replace(
      /\(인\)/g,
      (match, offset, string) => {
        // "장 환 성 (인)"인지 확인하기 위해 (인) 앞의 텍스트를 검사
        const precedingText = string.slice(Math.max(0, offset - 10), offset);

        // "장 환 성"과 같이 특정 텍스트가 앞에 있을 때는 교체하지 않음
        if (precedingText.includes("장 환 성")) {
          return match; // "(인)" 그대로 반환
        } else {
          return `
      <span id="earlypay-signature" style="position: relative; display: inline-block; vertical-align: middle;">
        (인)
        <img src="${signature}" alt="signature" style="display: inline-block; vertical-align: middle; width: 60px; height: auto; margin-left: -40px;">
      </span>`; // 유저 사인 반환
        }
      },
    );
  }

  return updatedHtmlContent;
};
