import React, { useState } from "react";

import HanaCapitalTermsLayout from "@routes/layouts/HanaCapitalTermsLayout";
import "react-pdf/dist/Page/AnnotationLayer.css";
import styled from "styled-components";

import { LottieAnimation, Text } from "@earlybird/ui";

// TODO: 여러 약관이 추가되어도 사용 가능하도록 재사용 가능한 컴포넌트로 수정이 필요합니다. NEW 회원가입 과정 중 약관 정리 과정에서 변경될 예정입니다.
const TermsDetail: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState("");

  return (
    <HanaCapitalTermsLayout>
      <Wrapper>
        <FloatingButton loading={loading || img}>
          {loading || img ? (
            <LottieAnimation src={"eds-loader"} width={240} height={240} />
          ) : (
            <Text color={"content-on-color"}>이해했어요</Text>
          )}
        </FloatingButton>
      </Wrapper>
    </HanaCapitalTermsLayout>
  );
};

export default TermsDetail;

const Wrapper = styled.div`
  width: 100%;
  max-height: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 6px 8px 38px 8px;
  position: relative;
`;

const FloatingButton = styled.button`
  width: calc(100% - 40px);
  max-width: calc(600px - 40px);
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: fixed;
  bottom: 30px;
`;
