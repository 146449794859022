import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";

import { Box, LottieAnimation, PageContainer, Text } from "@earlybird/ui";

import { Header } from "@components/layouts";
import Meta from "@components/layouts/Meta";

export const Incomplete = () => {
  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"심사 결과 | 보완 필요"} />
      <Header hasLeftButton={false} />
      <PageContainer align={"center"}>
        <Text typo={"subtitle-1"} bold center>
          {`제출하신 서류를\n빠르게 재검토 하고 있어요`}
        </Text>
        <Box width={"100%"} center>
          <LottieAnimation src={"screening"} width={240} height={240} />
        </Box>
        <Text color={"content-secondary"} center>
          {"서류 재검토는 영업일\n기준 2일 이내로 완료될 예정이에요."}
        </Text>
      </PageContainer>
    </>
  );
};

export default Incomplete;
