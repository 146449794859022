import { trackEventMixpanel } from "@earlypay/shared";
import { EARLYPAY_SERVICE_GUIDE } from "@earlypay/shared/common";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  Box,
  LottieAnimation,
  PageContainer,
  Text,
} from "@earlybird/ui";

import { Header } from "@components/layouts";
import Meta from "@components/layouts/Meta";

import { applicationState } from "@/recoil/applications/atoms";

export const Submit = () => {
  const application = useRecoilValue(applicationState);
  const name = application.user.name;

  return (
    <>
      <Meta
        title={"이페이론 | 서비스 신청"}
        name={"심사 결과 | 매출 연동 중"}
      />
      <Header hasLeftButton={false} />
      <PageContainer align={"center"}>
        <Text typo={"subtitle-1"} bold center>
          {`${name} 사장님의\n서류 제출이 완료되었어요.`}
        </Text>
        <Box width={"100%"} center>
          <LottieAnimation src={"completed"} width={240} height={240} />
        </Box>
        <Text color={"content-secondary"} center>
          {
            "현재 신청자가 많아 접수 순차적으로\n전화 드릴 예정이에요.\n최대한 빠르게 연락드릴게요!\n1522-6911의 연락을 꼭 받아주세요."
          }
        </Text>
      </PageContainer>

      <ActionButton
        as={"a"}
        href={EARLYPAY_SERVICE_GUIDE}
        target={"_blank"}
        onClickPrimaryButton={() => {
          trackEventMixpanel("open_earlypay_preview_guide", {
            description: "얼리페이 미리 알아보기 가이드 클릭",
          });
        }}
        primaryButtonLabel={"얼리페이 서비스 미리 알아보기"}
      />
    </>
  );
};

export default Submit;
