import { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./InfoBox.module.scss";
import { InfoBoxProps } from "./InfoBox.types";
import "@ui/styles/index.scss";
import { Box, Icon, Stack, Text, VStack } from "@ui/components/atoms";
import {
  changeStateToColor,
  changeStateToIcon,
} from "@ui/components/atoms/InfoBox/utils";

/**
 * `InfoBox` 는 특정 정보나 메시지를 빠르고 쉽게 이해할 수 있도록 시각적으로 강조된 정보를 제공하는 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <InfoBox
 *   title="타이틀"
 *   message="메세지입니다."
 * />
 * ```
 */
export const InfoBox = forwardRef<HTMLElement, InfoBoxProps>(function InfoBox(
  {
    className,
    as,
    state = "success",
    title,
    emphasis,
    message,
    style,
    ...rest
  }: InfoBoxProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "div";

  return (
    <BaseComponent
      {...rest}
      ref={forwardedRef}
      className={classNames(
        styles.InfoBox,
        styles[`state-${state}`],
        "earlybird-info-Box",
        className,
      )}
      style={{ ...style }}
    >
      <Box padding={2}>
        <Icon
          size={"xs"}
          icon={changeStateToIcon(state)}
          color={changeStateToColor(state)}
        />
      </Box>
      <VStack height={"fit-content"}>
        {title && (
          <Text typo={"body-3"} bold color={changeStateToColor(state)}>
            {title}
          </Text>
        )}
        {message && (
          <Text typo={"body-3"} color={"content-secondary"}>
            {message}
          </Text>
        )}
      </VStack>
    </BaseComponent>
  );
});

export default InfoBox;
