import React, { ForwardedRef, forwardRef, useState } from "react";
import classNames from "classnames";
import styles from "./ArsCertification.module.scss";
import { ArsCertificationProps } from "./ArsCertification.types";
import "@ui/styles/index.scss";
import { ActionButton, PageHeader } from "@ui/components/layouts";
import {
  BottomSheet,
  BoxButton,
  HStack,
  InfoBox,
  Text,
  useToast,
  VStack,
} from "@ui/components/atoms";
import { useWindowSize } from "@earlypay/shared/hooks";

/**
 * `ArsCertification` 는 사진 촬영 또는 불러오기를 실행하고 첨부 가이드를 같이 제공하여 필요한 이미지를 첨부하도록 유도하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <ArsCertification
 *   visible={true}
 *   onClose={onClose}
 *   authCode={35}
 *   handleRetryCertification={handleRetryCertification}
 *   handleCertificateAuthCode={handleCertificateAuthCode}
 * />
 * ```
 */
export const ArsCertification = forwardRef<HTMLElement, ArsCertificationProps>(
  function ArsCertification(
    {
      className,
      as,
      visible,
      onClose,
      authCode,
      handleRetryCertification,
      handleCertificateAuthCode,
      loading,
      disabled,
      ...rest
    }: ArsCertificationProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const toast = useToast();
    const windowSize = useWindowSize();
    const [visibleGuide, setVisibleGuide] = useState(false);

    const BaseComponent = as ?? "div";
    const digits = String(authCode).split("");

    const handleRetryAuth = () => {
      toast.addToast("인증 전화 요청이 완료되었어요.", "success");

      handleRetryCertification();
    };

    if (!visible) {
      return;
    }

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.ArsCertification,
          "earlybird-ars-certification",
          className,
        )}
      >
        <PageHeader
          handleClickLeftButton={onClose}
          hasRightButton={false}
        ></PageHeader>

        <VStack
          className={classNames(
            styles.Container,
            "earlybird-ars-certification-container",
          )}
          height={windowSize.height}
          width={"100%"}
          align={"center"}
          padding={20}
        >
          <VStack spacing={2}>
            <Text typo={"subtitle-1"} bold>
              {"전화를 받고 안내에 따라\n아래 번호를 입력해주세요"}
            </Text>
            <Text typo={"body-2"}>
              {"완료 후, 아래 인증 완료 버튼을 눌러주세요."}
            </Text>
          </VStack>

          <HStack spacing={3} center>
            <div className={classNames(styles.NumberCard)}>
              <Text typo={"display-3"} color={"content-highlight"} bold>
                {digits[0]}
              </Text>
            </div>
            <div className={classNames(styles.NumberCard)}>
              <Text typo={"display-3"} color={"content-highlight"} bold>
                {digits[1]}
              </Text>
            </div>
          </HStack>

          <BoxButton
            hierarchy={"tertiary"}
            size={"sm"}
            state={"fit"}
            onClick={() => setVisibleGuide(true)}
          >
            인증 전화가 오지 않아요
          </BoxButton>

          <BottomSheet
            headerType={"headline"}
            visible={visibleGuide}
            onClose={() => setVisibleGuide(false)}
            title={"아래와 같은 상황인지 확인해주세요"}
            buttonType={"single"}
            primaryButtonLabel={"확인"}
            onClickPrimaryButton={() => setVisibleGuide(false)}
          >
            <VStack spacing={7}>
              <VStack as={"ol"} margin={0} padding={"0 20px"}>
                <Text as={"li"} color={"content-secondary"}>
                  {
                    "1522-6911 을 차단했어요.\n휴대폰 설정의 번호 차단 목록에서 위 번호를 삭제해주세요."
                  }
                </Text>
                <Text as={"li"} color={"content-secondary"}>
                  {
                    "스팸 차단 앱이 깔려 있어요.\n앱 내 차단 목록에서 1522-6911 을 해제하거나, 앱을 잠시 꺼주세요."
                  }
                </Text>
              </VStack>

              <InfoBox
                state={"info"}
                message={
                  "위 상황에 해당하지 않는데도 인증 전화를 받지 못하셨다면 고객센터로 문의해주세요."
                }
              />
            </VStack>
          </BottomSheet>
        </VStack>

        <ActionButton
          buttonType={"duo-vertical"}
          primaryButtonLabel={"인증 완료"}
          secondaryButtonLabel={"전화 다시 받기"}
          onClickPrimaryButton={handleCertificateAuthCode}
          onClickSecondaryButton={handleRetryAuth}
          loading={loading}
          disabledPrimaryButton={disabled}
        />
      </BaseComponent>
    );
  },
);

export default ArsCertification;
