export const WEEKDAY = ["일", "월", "화", "수", "목", "금", "토"];

/** - 가 들어간 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {string} e.g.yyyy-mm-dd
 */
const getFormattedDateWithDash = (value?: Date | string): string => {
  const date = value ? new Date(value) : new Date();
  const currentYear = new Date(date).getFullYear().toString();
  const currentMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const currentDay = date.getDate().toString().padStart(2, "0");

  const customDate = `${currentYear}-${currentMonth}-${currentDay}`;
  return customDate;
};

/** - 가 들어간 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {string} e.g.yy-mm-dd
 */
const getFormattedDateWithDashShort = (value?: Date | string): string => {
  const date = value ? new Date(value) : new Date();
  const currentYear = new Date(date).getFullYear().toString().slice(-2);
  const currentMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const currentDay = date.getDate().toString().padStart(2, "0");

  const customDate = `${currentYear}-${currentMonth}-${currentDay}`;
  return customDate;
};

/** 특정 포맷 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {string} e.g.00.00.00
 */
const getFormattedDateWithDots = (value?: Date | string): string => {
  const date = value ? new Date(value) : new Date();
  const currentYear = new Date(date).getFullYear().toString().slice(-2);
  const currentMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const currentDay = date.getDate().toString().padStart(2, "0");

  const customDate = `${currentYear}.${currentMonth}.${currentDay}`;
  return customDate;
};

/** 특정 포맷 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @param {Date | string} length 년도 자릿수
 * @returns {string} e.g.00년 00월 00일 또는 0000년 00월 00일
 */
const getFormattedDateKR = (value: Date | string, length?: number): string => {
  const date = new Date(value);
  const currentYear = new Date(date)
    .getFullYear()
    .toString()
    .slice(length === 2 ? -2 : 0);
  const currentMonth = new Date(date).getMonth() + 1;
  const currentDay = new Date(date).getDate();

  const customDate = `${currentYear}년 ${currentMonth}월 ${currentDay}일`;
  return customDate;
};

/** 특정 포맷 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {string} e.g.00년 00월 00일 (0)
 */
const getFormattedDateFullKR = (value?: Date | string) => {
  const date = new Date(value);
  const currentYear = new Date(date).getFullYear().toString();
  const currentMonth = new Date(date).getMonth() + 1;
  const currentDay = new Date(date).getDate();
  const week = getDayOfWeek(value);

  const customDate = `${currentYear}년 ${currentMonth}월 ${currentDay}일 (${week})`;
  return customDate;
};

/** 특정 포맷 형태로  `요일`을 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {string} e.g.월
 */
const getDayOfWeek = (value?: Date | string): string => {
  const dayOfWeek = new Date(value).getDay();

  return WEEKDAY[dayOfWeek];
};

/** 오늘 또는 props의 `날짜`를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {number} e.g.27
 */
const getDay = (value?: Date | string): number => {
  const date = isNaN(new Date(value || "").getTime())
    ? new Date()
    : new Date(value || "");
  const currentDate = date.getDate();

  return currentDate;
};

/** 오늘 또는 props의 `월`을 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {number} e.g.9
 */
const getMonth = (value?: Date | string): number => {
  const date = isNaN(new Date(value || "").getTime())
    ? new Date()
    : new Date(value || "");
  const currentDate = date.getMonth() + 1;
  return currentDate;
};

/** 오늘 또는 props의 `년도`를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {number} e.g.2020
 */
const getYear = (value?: Date | string): number => {
  const date = isNaN(new Date(value || "").getTime())
    ? new Date()
    : new Date(value || "");
  const currentYear = date.getFullYear();

  return currentYear;
};

/** 날짜를 넣으면 오늘 날짜인지 체크하여 값을 반환합니다.
 * @param {Date | number | string} value 날짜 객체
 * @returns {boolean}
 */
const checkToday = (value?: Date | number | string) => {
  const today = new Date();
  const date = new Date(value);

  const result =
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate();

  return result;
};

/** 날짜를 넣으면 같은 날짜인지 체크하여 값을 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {boolean}
 */
const checkSameDay = (first: Date | string, second: Date | string) => {
  const firstDate = new Date(first);
  const secondDate = new Date(second);

  const result =
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate();

  return result;
};

/** 날짜를 넣으면 오늘 기준으로 같은 해인지 체크하여 값을 반환합니다.
 * @param {Date} value 날짜 객체
 * @returns {boolean}
 */
const checkSameYear = (date: Date): boolean => {
  const today = new Date();
  return today.getFullYear() === date.getFullYear();
};

/**
 * 날짜를 넣으면 윤년을 확인하여 값을 반환합니다.
 * @param {Date|string} date
 * @returns {boolean}
 */
const checkLeapYear = (value: Date | string) => {
  const date = value ? new Date(value) : new Date();
  const year = date.getFullYear();

  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

/**
 * 어제 날짜를 반환합니다.
 * @param {Date|string} date
 * @returns {Date}
 */
const getYesterday = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return yesterday;
};

/**
 * 특정 날짜 값을 넣으면 해당하는 월을 기준으로 시작과 끝 일자를 반환합니다.
 * @param {Date|string} date
 * @returns { startOfMonth: string; endOfMonth: string } e.g. {startDate: "2024-11-01", endDate: "2024-11-30"}
 */
const getStartAndEndOfMonth = (
  date: Date
): { startOfMonth: string; endOfMonth: string } => {
  const year = date.getFullYear();
  const month = date.getMonth();

  // 1일 날짜 구하기
  const startOfMonth = new Date(year, month, 1);
  const startOfMonthStr = `${startOfMonth.getFullYear()}-${(
    startOfMonth.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${startOfMonth.getDate().toString().padStart(2, "0")}`;

  // 해당 월의 마지막 날 구하기
  const endOfMonth = new Date(year, month + 1, 0);
  const endOfMonthStr = `${endOfMonth.getFullYear()}-${(
    endOfMonth.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${endOfMonth.getDate().toString().padStart(2, "0")}`;

  return { startOfMonth: startOfMonthStr, endOfMonth: endOfMonthStr };
};

export {
  checkLeapYear,
  checkSameDay,
  checkSameYear,
  checkToday,
  getDay,
  getDayOfWeek,
  getFormattedDateFullKR,
  getFormattedDateKR,
  getFormattedDateWithDash,
  getFormattedDateWithDashShort,
  getFormattedDateWithDots,
  getMonth,
  getStartAndEndOfMonth,
  getYear,
  getYesterday,
};
