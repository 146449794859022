// api
import { instance } from "src/apis/instance";

/**
 * axios header 에 토큰을 교체합니다.
 * @param token 얼리페이 토큰
 */
export const changeToken = (token: string) => {
  Object.assign(instance.defaults, {
    headers: { authorization: `token ${token}` },
  });
};
