import { useQuery } from "@tanstack/react-query";
import { deliveryQueryKeys } from "./queryKeys";
import { getCookie } from "@shared/utils/cookies";
import { DeliveryAgencyRequests } from "@shared/typings";
import { getDeliveryAgents } from "@apis/endpoints/delivery";

export const useFetchDeliveryAgents = (applicationId: number) => {
  const accessToken = getCookie("earlypay-token");

  return useQuery({
    queryKey: deliveryQueryKeys.fetchDeliveryAgents(applicationId).queryKey,
    queryFn: () => getDeliveryAgents(applicationId),
    select: (data: { ok: boolean; deliveryAgents: DeliveryAgencyRequests[] }) =>
      data.deliveryAgents,
    enabled: !!accessToken && !!applicationId,
    retry: 0,
  });
};
