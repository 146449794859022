/** 서비스 신청서 상태 */
export const ApplicationStatusCode = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  WAITING_REVIEW: "WAITING_REVIEW",
  NEED_COMPLEMENT: "NEED_COMPLEMENT",
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
  REJECTED_CREDIT: "REJECTED_CREDIT",
  REJECTED_OPERATION: "REJECTED_OPERATION",
};
export type ApplicationStatusCodeTypes =
  (typeof ApplicationStatusCode)[keyof typeof ApplicationStatusCode];
