import { DeliveryCode, DeliveryCodeTypes } from "@earlypay/shared/typings";

export const getPlatformImage = (platform: DeliveryCodeTypes) => {
  switch (platform) {
    case DeliveryCode.BAEMIN:
      return "/brand/wordmark-baemin.svg";
    case DeliveryCode.YOGIYO:
      return "/brand/wordmark-yogiyo.svg";
    case DeliveryCode.COUPANG:
      return "/brand/wordmark-coupang-eats.svg";
    default:
      return "/brand/wordmark-baemin.svg";
  }
};
