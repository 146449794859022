export const termsCode = {
  // ------------------- 얼리페이 -------------------
  EP01: "EP01",
  // ------------------- 얼리페이 대부 -------------------
  CD01: "CD01", // 신용정보 조회 관련
  EL01: "EL01",
  EL02: "EL02",
  EL03: "EL03",
  EL04: "EL04",
  EL05: "EL05",
  EL06: "EL06",
  EL07: "EL07",
  EL08: "EL08",
  // ------------------- 하나캐피탈 -------------------
  HC01: "HC01",
  HC02: "HC02",
  HC03: "HC03",
};
export type termsCodeTypes = (typeof termsCode)[keyof typeof termsCode];

export const termsNames = {
  [termsCode.EP01]: "[필수] 서비스 이용 약정서",
  [termsCode.EL01]: "[필수] 서비스 이용 약정서",
  [termsCode.EL02]: "[필수] 채권 양도 양수 계약",
  [termsCode.EL03]: "[필수] 서비스 이용 표준 약관",
  [termsCode.EL04]: "[필수] 서비스 이용 확약서",
  [termsCode.EL05]: "[필수] 개인(신용)정보 제3자 제공 동의",
  [termsCode.EL06]: "[필수] 개인(신용)정보 제3자 제공 동의_VAN사/배달사",
  [termsCode.EL07]: "[필수] 개인정보 수집 이용 동의",
  [termsCode.CD01]: "[필수] 개인(신용) 정보 조회 동의",
  [termsCode.HC01]: "[필수] 정산금채권 양도양수 계약서",
  [termsCode.HC02]: "[필수] 채권재양도 확인서",
  [termsCode.HC03]: "[필수] 개인(신용)정보 조회 동의",
};
