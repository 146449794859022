import { isArray, isSymbol } from "@earlypay/shared/utils/types";

export function toString(value: unknown): string {
  if (value == null) {
    return "";
  }
  if (typeof value === "string") {
    return value;
  }
  if (isArray(value)) {
    return `${value.map((other) => {
      if (other == null) {
        return other;
      }
      return toString(other);
    })}`;
  }
  if (isSymbol(value)) {
    return value.toString();
  }
  if (Object.is(-0, value)) {
    return "-0";
  }
  return `${value}`;
}
