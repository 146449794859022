import { ForwardedRef, forwardRef, useState } from "react";
import classNames from "classnames";
import styles from "./AccountInput.module.scss";
import { AccountInputProps } from "./AccountInput.types";
import "@ui/styles/index.scss";
import { Input } from "@ui/components/atoms";
import { EyeClosedIcon, EyeIcon } from "@earlybird/icons";

/**
 * `AccountInput` 는 아이디와 비밀번호를 입력할 수 있는 Input 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <AccountInput
 *   title="계정 입력"
 *   placeholder={id: "아이디 입력", password:"비밀번호 입력"}
 *   value={id: id.value, password: password.value}
 * />
 * ```
 */
export const AccountInput = forwardRef<HTMLElement, AccountInputProps>(
  function AccountInput(
    {
      className,
      as,
      title,
      name,
      placeholder,
      value,
      onBlur,
      size = "md",
      handleChange,
      ...rest
    }: AccountInputProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";
    const [visiblePassword, setVisiblePassword] = useState(false);

    return (
      <>
        <BaseComponent
          {...rest}
          ref={forwardedRef}
          className={classNames(
            styles.AccountInputContainer,
            "earlybird-account-input",
            className,
          )}
        >
          {name?.id && (
            <Input
              name={name?.id}
              title={title}
              placeholder={placeholder?.id}
              value={value?.id}
              onBlur={onBlur?.id}
              onChange={handleChange}
              size={size}
            />
          )}
          <Input
            name={name?.password}
            placeholder={placeholder?.password}
            value={value?.password}
            onBlur={onBlur?.password}
            size={size}
            title={!name?.id && title}
            type={visiblePassword ? "text" : "password"}
            trailingIcon={visiblePassword ? EyeClosedIcon : EyeIcon}
            onClickTrailingIcon={() => setVisiblePassword(!visiblePassword)}
            onChange={handleChange}
          />
        </BaseComponent>
      </>
    );
  },
);

export default AccountInput;
