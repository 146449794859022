import React from "react";

import HanaCapitalTermsLayout from "@routes/layouts/HanaCapitalTermsLayout";
import styled from "styled-components";

import { LottieAnimation, Text } from "@earlybird/ui";

export const Result: React.FC = () => {
  return (
    <HanaCapitalTermsLayout>
      <Wrapper>
        <Text bold center typo={"subtitle-1"}>
          {"가입신청 완료!\n곧 서비스 안내 연락 드릴게요"}
        </Text>
        <LottieAnimation
          src={"checking-application"}
          width={240}
          height={240}
        />
        <Text center color={"content-secondary"}>
          {"영업일 기준 1~2일 이내, 위 시간에 맞춰\n유선 연락을 드릴게요."}
        </Text>
      </Wrapper>
    </HanaCapitalTermsLayout>
  );
};

export default Result;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const Tag = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  padding: 4px 8px;
`;
