import React from "react";

import {
  NOTION_FAQ_GUIDE,
  TERMS_PRIVACY_POLICY,
  TERMS_SERVICE_AGREEMENT,
  modalCode,
  openChannelIOChat,
  trackEventMixpanel,
} from "@earlypay/shared";
import styled from "styled-components";

import {
  AccordionMenu,
  PageHeader,
  PageModal,
  VStack,
  useConfirmModal,
} from "@earlybird/ui";

import { useLogin } from "@hooks/useLogin";

interface SettingModalProps {
  visible: boolean;
  onClose: () => void;
}

export const SettingModal = ({ onClose, visible }: SettingModalProps) => {
  const { handleLogout } = useLogin();
  const { openConfirmModal } = useConfirmModal();

  const handleClickLogout = () => {
    openConfirmModal({
      id: modalCode.ERROR_LOGOUT,
      onClickPrimaryButton: handleLogout,
    });
  };

  return (
    <PageModal visible={visible}>
      <PageHeader
        handleClickLeftButton={onClose}
        hasRightButton={false}
        hasChat={false}
      >
        설정
      </PageHeader>
      <VStack>
        <AccordionMenu title={"고객센터"} onClick={openChannelIOChat} />
        <AccordionMenu
          title={"자주 묻는 질문"}
          link={NOTION_FAQ_GUIDE}
          onClick={() => {
            trackEventMixpanel("click_FAQ", {
              description: "자주묻는질문 클릭",
            });
          }}
        />
        <AccordionMenu title={"로그아웃"} onClick={handleClickLogout} />
      </VStack>
      <Line />
      <VStack>
        <AccordionMenu
          title={"서비스 이용약관"}
          link={TERMS_SERVICE_AGREEMENT}
          onClick={() => {
            trackEventMixpanel("click_terms_EP02", {
              description: "서비스이용약관 클릭",
            });
          }}
        />
        <AccordionMenu
          bold
          underline
          title={"개인정보처리방침"}
          link={TERMS_PRIVACY_POLICY}
          onClick={() => {
            trackEventMixpanel("click_terms_EP04", {
              description: "개인정보처리방침 클릭",
            });
          }}
        />
      </VStack>
    </PageModal>
  );
};

export default SettingModal;

const Line = styled.div`
  width: 100%;
  height: 8px;
  background-color: var(--earlybird-color-border-tertiary);
  flex-shrink: 0;
`;
