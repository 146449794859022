import React from "react";
import HanaCapitalTermsLayout from "@routes/layouts/HanaCapitalTermsLayout";
import styled from "styled-components";
import { Text } from "@earlybird/ui";

export const Certificate: React.FC = () => {
  const s3BaseUrl = import.meta.env.VITE_S3_URI; // TODO: 리팩토링 시 S3 주소 따로 제거 예정

  return (
    <HanaCapitalTermsLayout>
      <CertificateWrapper>
        <TextInfoWrapper>
          <Stack>
            <img
              src={`${s3BaseUrl}/image/hana-capital/hana-capital-certificate-1.svg`}
              alt={"하나캐피탈 문자 인증"}
            />
            <TextStack>
              <Text bold color={"content-secondary"}>
                1. 핸드폰에서
              </Text>
              <Text color={"content-secondary"}>
                하나캐피탈에서 온 문자 확인
              </Text>
            </TextStack>
          </Stack>
          <Stack>
            <img
              src={`${s3BaseUrl}/image/hana-capital/hana-capital-certificate-2.svg`}
              alt={"하나캐피탈 문자 인증"}
            />
            <TextStack>
              <Text bold color={"content-secondary"}>
                2. 문자 링크 접속 후
              </Text>
              <Text color={"content-secondary"}>안내에 따라 인증 진행</Text>
            </TextStack>
          </Stack>
          <Stack>
            <img
              src={`${s3BaseUrl}/image/hana-capital/hana-capital-certificate-3.svg`}
              alt={"하나캐피탈 문자 인증"}
            />
            <TextStack>
              <Text bold color={"content-secondary"}>
                3. 얼리페이로 돌아와
              </Text>
              <Text color="content-highlight">인증 완료 버튼 클릭!</Text>
            </TextStack>
          </Stack>
        </TextInfoWrapper>
      </CertificateWrapper>
    </HanaCapitalTermsLayout>
  );
};

export default Certificate;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 54px;
  width: 100%;
`;

const CertificateWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Stack = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: center;
  justify-content: start;
`;

const TextStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  width: 100%;
`;
