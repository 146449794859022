export interface SafetyGuideTypes {
  subtitle: string;
  title: string;
  image: string;
}

export const safetyGuide = {
  fds: {
    subtitle: "비정상적 거래를 실시간으로 걸러내는",
    title: "FDS 시스템",
    image: "/service/check-shield.svg",
  },
  notification: {
    subtitle: "의심스러운 앱과 행동이 감지되면",
    title: "악성앱과 피싱 감지 알림",
    image: "/service/alert-notification.svg",
  },
  compensation: {
    subtitle: "카드정보, 명의도용, 도난 등의 피해에",
    title: "피해 보상 제도",
    image: "/service/won-in-circle.svg",
  },
};
