import { ServerError } from "@shared/typings";
import { QueryCache, QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
  queryCache: new QueryCache({
    onError: (error: ServerError, query) => {
      console.error(error);

      if (query.state.data !== undefined) {
      }
    },
  }),
});
