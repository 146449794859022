import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import packageJson from "../../../package.json";
import { ApplicationTypes } from "../../typings";
import {
  changeBase64ToBlob,
  changeFileToBase64,
  changeFileToUint8Array,
  handleServerErrorCode,
  handleServerErrorMessage,
} from "../../utils";

// Sentry 초기화
export const initSentry = () => {
  Sentry.onLoad(async () => {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_URI,
      release: packageJson.version,
      environment: import.meta.env.VITE_ENV,
      debug: false,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.extraErrorDataIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: true,
          networkDetailAllowUrls: ["/api/v2/*"],
          networkRequestHeaders: ["X-Custom-Header"],
          networkResponseHeaders: ["X-Custom-Header"],
        }),
      ],
      stackParser: Sentry.defaultStackParser,
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracePropagationTargets: [
        "localhost",
        /^\//,
        /.*\.earlypay\.kr/,
        /.*\.epayloan\.kr/,
        /.*\.kakao\.com/,
        /.*\.kauth.kakao\.com/,
      ],
    });
  });
};

export const setSentryUser = (application: ApplicationTypes) => {
  Sentry.setUser({
    name: application.user.name,
    email: application.user.email,
    userId: application.user.id,
    phone: application.user.phone,
    store: application.store.title,
    status: application.status,
    stage: application.stage,
  });
  Sentry.withScope(function (scope) {
    scope.setUser({
      name: application.user.name,
      email: application.user.email,
      userId: application.user.id,
      phone: application.user.phone,
      store: application.store.title,
      status: application.status,
      stage: application.stage,
    });
  });
  Sentry.setTag("applicationId", application.id);
};

export interface SentryHookTypes {
  error: AxiosError | any;
  title: string;
  message?: string;
  level?: Sentry.SeverityLevel;
  application?: ApplicationTypes;
  api?: string;
}

/** 에러 객체를 받아서 Sentry 에 에러 정보를 전달하는 커스텀 훅 입니다. */
export const sentryHook = (props: SentryHookTypes) => {
  function formatString(input: string) {
    // 정규 표현식으로 괄호 안의 내용을 추출
    const regex = /\(([^)]+)\)/;
    const match = input.match(regex);

    if (match) {
      const fields = match[1].split(", ").map((field) => field.trim());
      const formattedFields = fields.join("\n");
      return input.replace(regex, "").trim() + "\n" + formattedFields;
    } else {
      return input;
    }
  }

  function formatErrorLevel(statusCode: string) {
    if (statusCode === "500") {
      return "error";
    }

    return "warning";
  }

  Sentry.withScope(function (scope) {
    const { error, title, message, level, application } = props;
    const customError = new Error(error);
    const user = application?.user ?? scope.getUser();
    const apiUrl = error?.config?.url;

    const customMessage = message ?? "에러가 발생했습니다.";
    const errorMessage = formatString(handleServerErrorMessage(error));
    const errorCode = handleServerErrorCode(error);
    const userName = user?.name ? `${user.name} ` : ``;
    const statusCode = `${error.response?.status ?? "unknown"}`;

    customError.name = `${userName}${title}`;
    customError.message = `[${statusCode}] ${customMessage}\nerrorCode: ${errorCode}\nmessage: ${errorMessage}`;

    scope.setTag("apiUrl", apiUrl);
    scope.setLevel(level ? level : formatErrorLevel(error.response?.status));

    Sentry.captureException(customError);
  });
};

/** 이미지 파일을 받아서 attachments 이벤트 프로세서를 추가하는 함수입니다. */
export const sentryAddAttachments = async (image: File, name: string) => {
  const base64String: string = await changeFileToBase64(image);

  const logBlob = changeBase64ToBlob(base64String);
  const logFile = new File([logBlob], `${name}.jpeg`, {
    type: "image/jpeg",
  });
  const logFileArrayBuffer = await changeFileToUint8Array(logFile);

  Sentry.addEventProcessor((event, hint) => {
    hint.attachments = [{ filename: `${name}.jpeg`, data: logFileArrayBuffer }];
    return event;
  });
};
