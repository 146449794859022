import { debounce } from "@earlypay/shared";
import "@ui/styles/index.scss";
import classNames from "classnames";
import { ForwardedRef, forwardRef, useState } from "react";
import { Box } from "../Box";
import styles from "./Switch.module.scss";
import { SwitchProps } from "./Switch.types";

/**
 * `Switch` 는 특정 설정이나 기능, 상태를 켜거나 끌 수 있는 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Switch
 *   initialState={true}
 *   onSwitch={handleSwitch}
 * />
 * ```
 */
export const Switch = forwardRef<HTMLElement, SwitchProps>(function Switch(
  {
    className,
    as,
    initialState = false,
    isDisabled,
    onSwitch,
    style,
    ...rest
  }: SwitchProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "button";
  const [isOn, setIsOn] = useState(initialState);

  const handleSwitch = debounce(() => {
    setIsOn(!isOn);

    if (onSwitch) {
      onSwitch(!isOn);
    }
  }, 300);

  return (
    <BaseComponent
      {...rest}
      ref={forwardedRef}
      className={classNames(
        styles.Track,
        isOn ? styles["switch-on"] : styles["switch-off"],
        isDisabled && styles["disabled"],
        "earlybird-switch",
        className,
      )}
      style={{ ...style }}
      onClick={handleSwitch}
      disabled={isDisabled}
    >
      <Box
        className={classNames(
          styles.Knob,
          isDisabled && styles["disabled"],
          "earlybird-switch-knob",
        )}
      />
    </BaseComponent>
  );
});

export default Switch;
