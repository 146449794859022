import { SLACK_WEBHOOK_TEST, SlackMessageRequests } from "@earlypay/shared";
import axios from "axios";

/**
 * incoming webhook 을 이용하여 슬랙으로 메세지를 보내는 API 함수입니다.
 */
export const sendSlackMessage = async ({
  message,
  url = SLACK_WEBHOOK_TEST,
}: SlackMessageRequests) => {
  const isProduction = import.meta.env.VITE_ENV === "production";
  const slackUrl = isProduction ? url : SLACK_WEBHOOK_TEST;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  try {
    await axios({
      method: "post",
      url: slackUrl,
      data: message,
      headers: headers,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
