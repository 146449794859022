import { clarity } from "react-microsoft-clarity";
import { ApplicationTypes } from "../../typings";
import mixpanel from "mixpanel-browser";

// Clarity 초기화
export const initClarity = (id: string) => {
  clarity.init(id);
};

// 사용자의 ID 및 프로필 속성을 저장합니다.
export const setClarityUser = (application: ApplicationTypes) => {
  if (!application.id) {
    clarity.identify(mixpanel.get_distinct_id(), {
      isLoggedIn: false,
    });
  }

  if (clarity.hasStarted()) {
    clarity.identify(application.user.id.toString(), {
      id: application.user.id,
      userId: application.user.id,
      storeId: application.store.id,
      email: application.user.email,
      name: application.user.name,
      phone: application.user.phone,
      gender: application.user.gender,
      birthdate: application.user.birthdate,
      isCertified: application.user.isCertified,
      created: application.user.created,
      businessNumber: application.businessRegistrationNumber,
      store: application.store.title,
      serviceType: application.serviceType,
      approvedAt: application.approvedAt,
      stage: application.stage,
      status: application.status,
      isLoggedIn: true,
    });
  }
};
