import React, { useCallback, useEffect } from "react";
import { BottomSheet, HStack, Input, Text, VStack } from "@earlybird/ui";
import { Controller, useFormContext } from "react-hook-form";
import { debounce } from "@earlypay/shared/utils";
import {
  DeliveryAgencyNamesTypes,
  DeliveryAgencyNames,
} from "@earlypay/shared/typings";
import styled from "styled-components";

interface AddDeliveryAgencyBottomSheetProps {
  visible: boolean;
  onClose: () => void;
  disabled: boolean;
}

export const AddDeliveryAgencyBottomSheet = ({
  disabled,
  visible,
  onClose,
}: AddDeliveryAgencyBottomSheetProps) => {
  const { trigger, control, watch } = useFormContext();

  const handleTrigger = useCallback(
    debounce(async () => {
      await trigger(["deliveryAgencyBrand", "deliveryAgencyContact"]);
    }, 500),
    [],
  );

  useEffect(() => {
    handleTrigger();
  }, [
    handleTrigger,
    watch("deliveryAgencyBrand"),
    watch("deliveryAgencyContact"),
  ]);

  return (
    <BottomSheet
      title=""
      buttonType="single"
      primaryButtonLabel="등록"
      disabled={disabled}
      onClose={onClose}
      visible={visible}
      type={"submit"}
    >
      <VStack spacing={16}>
        <VStack spacing={5}>
          <Text typo={"body-1"} bold>
            배달 대행사
          </Text>

          <Controller
            control={control}
            name={"deliveryAgencyBrand"}
            render={({ field, formState }) => (
              <HStack wrap spacing={4}>
                {Object.values(DeliveryAgencyNames).map(
                  (label: DeliveryAgencyNamesTypes) => (
                    <DeliveryAgencyChip
                      key={label}
                      name={"deliveryAgencyBrand"}
                      onBlur={field.onBlur}
                      onClick={() => field.onChange(label)}
                      selected={field.value === label}
                    >
                      <Text
                        typo={"body-3"}
                        bold={field.value === label}
                        color={
                          field.value === label
                            ? "content-highlight"
                            : "content-primary"
                        }
                      >
                        {label}
                      </Text>
                    </DeliveryAgencyChip>
                  ),
                )}
              </HStack>
            )}
          />
        </VStack>

        <VStack spacing={5}>
          <Text typo={"body-1"} bold>
            담당자 연락처
          </Text>

          <Controller
            control={control}
            name={"deliveryAgencyContact"}
            render={({ field, formState }) => (
              <Input
                type="number"
                name={"deliveryAgencyContact"}
                placeholder={"담당자 연락처 입력"}
                inputMode="numeric"
                maxLength={11}
                required
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
                errorMessage={
                  formState.errors["deliveryAgencyContact"]?.message
                }
              />
            )}
          />
        </VStack>
      </VStack>
    </BottomSheet>
  );
};

export default AddDeliveryAgencyBottomSheet;

const DeliveryAgencyChip = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: var(--earlybird-radius-circle);
  border: ${({ selected }: { selected: boolean }) =>
    selected
      ? `1px solid var(--earlybird-color-border-highlight)`
      : `1px solid var(--earlybird-color-border-primary)`};
  background-color: ${({ selected }: { selected: boolean }) =>
    selected
      ? `var(--earlybird-color-bg-informative-light)`
      : `var(--earlybird-color-bg-primary)`};
  padding: var(--earlybird-spacing-4) var(--earlybird-spacing-8);
  cursor: pointer;
  transition: all 0.15s ease-in-out;
`;
