import React from "react";
import styled from "styled-components";
import { useLogin } from "@hooks/useLogin";
import { getCookie } from "@utils/cookie";
import { Text } from "@earlybird/ui";

export const Intro: React.FC = () => {
  const { getKakaoLoginUrl } = useLogin();
  const accessToken = getCookie("earlypay-token");

  const getUrl = () => {
    if (accessToken) {
      return "/hana-capital/terms";
    } else {
      return getKakaoLoginUrl("/hana-capital/terms");
    }
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <Text typo={"subtitle-1"} bold>
          {"사장님! 서비스 이용을 위해\n문자 인증을 진행해 주세요"}
        </Text>
        <Image src={"/serviceApply/auth.svg"} alt={"하나캐피탈 연동"} />
      </InnerWrapper>

      <ButtonWrapper>
        <Button href={getUrl()}>
          <Text bold color={"content-on-color"}>
            다음
          </Text>
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Intro;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  margin: auto;
  background-color: white;
`;

const Image = styled.img`
  width: 240px;
  height: 240px;
  margin: auto;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 12px 0;
`;

const Button = styled.a`
  width: 100%;
  height: 56px;
  background-color: #0e5cff;
  padding: 0 12px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;
