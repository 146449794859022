import {
  checkLeapYear,
  checkSameYear,
  getDay,
  getFormattedDateKR,
  getMonth,
  getYear,
} from "@earlypay/shared/utils";

export const getTitle = (date: string) => {
  switch (date) {
    case "year":
      return "년도를 선택해 주세요";
    case "month":
      return "월을 선택해 주세요";
    case "date":
      return "날짜를 선택해 주세요";
    default:
      return "날짜를 선택해 주세요";
  }
};

export const getMonthList = (
  startDate: Date,
  endDate: Date,
  selectedDate: Date,
) => {
  const currentYear = getYear(selectedDate);
  const startYear = getYear(startDate);
  const endYear = getYear(endDate);

  if (currentYear === startYear) {
    return Array.from(
      { length: 12 - getMonth(startDate) },
      (v, i) => `${getMonth(startDate) + i}`,
    );
  }

  if (currentYear === endYear) {
    return Array.from({ length: getMonth(endDate) }, (v, i) => `${i + 1}`);
  }

  return Array.from({ length: 12 }, (v, i) => `${i + 1}`);
};

export const getDayList = (
  dayOptions: string[],
  startDate: Date,
  endDate: Date,
  selectedDate: Date,
) => {
  const currentYear = getYear(selectedDate);
  const currentMonth = getMonth(selectedDate);
  const startMonth = getMonth(startDate);
  const endMonth = getMonth(endDate);

  if (currentMonth === startMonth && currentYear === getYear(startDate)) {
    return dayOptions.filter((item) => Number(item) >= getDay(startDate));
  }

  if (currentMonth === endMonth && currentYear === getYear(endDate)) {
    return dayOptions.filter((item) => Number(item) <= getDay(endDate));
  }

  return dayOptions;
};

export const getDateList = (
  displayMode: "year" | "month" | "day",
  startDate: Date,
  endDate: Date,
  dayOptions: string[],
  selectedDate: Date,
) => {
  const y = endDate.getFullYear() - startDate.getFullYear();

  const years = Array.from(
    { length: y + 1 },
    (v, i) => `${i + Number(endDate.getFullYear()) - y}`,
  );
  const months = getMonthList(startDate, endDate, selectedDate);
  const dates = getDayList(dayOptions, startDate, endDate, selectedDate);

  return displayMode === "year"
    ? { year: years }
    : displayMode === "month"
      ? {
          year: years,
          month: months,
        }
      : {
          year: years,
          month: months,
          day: dates,
        };
};

// 각 달의 최대 일 수를 계산합니다.
export const getMaxDays = (year: number, month: number): number => {
  if (month === 2) {
    return checkLeapYear(year.toString()) ? 29 : 28;
  }
  return [4, 6, 9, 11].includes(month) ? 30 : 31;
};

export const getDateLabel = (
  selectedDate: Date,
  displayMode: "year" | "month" | "day",
) => {
  const isSameYear = checkSameYear(selectedDate);
  const year = getYear(selectedDate);
  const month = getMonth(selectedDate);
  const date = getDay(selectedDate);

  // 1. 0000년 포맷
  if (displayMode === "year") {
    return `${year}년`;
  }

  // 2. 0000년 00월 포맷
  if (displayMode === "month") {
    return isSameYear ? `${month}월` : `${year}년 ${month}월`;
  }

  // 3. 0000년 00월 00일 포맷
  if (isSameYear) {
    return `${month}월 ${date}일`;
  }

  return getFormattedDateKR(selectedDate);
};

export const getDateButtonLabel = (
  selectedDate: Date,
  displayMode: "year" | "month" | "day",
) => {
  const year = getYear(selectedDate);
  const month = getMonth(selectedDate);

  // 1. 0000년 포맷
  if (displayMode === "year") {
    return `${year}년`;
  }

  // 2. 0000년 00월 포맷
  if (displayMode === "month") {
    return `${year}년 ${month}월`;
  }

  // 3. 0000년 00월 00일 포맷
  return getFormattedDateKR(selectedDate);
};

export const getNavigateDate = (
  date: Date,
  displayMode: "year" | "month" | "day",
  mode: "previous" | "next",
) => {
  const selectedDate = new Date(date);

  if (displayMode === "year") {
    mode === "previous"
      ? selectedDate.setFullYear(selectedDate.getFullYear() - 1)
      : selectedDate.setFullYear(selectedDate.getFullYear() + 1);
  } else if (displayMode === "month") {
    mode === "previous"
      ? selectedDate.setMonth(selectedDate.getMonth() - 1)
      : selectedDate.setMonth(selectedDate.getMonth() + 1);
  } else {
    mode === "previous"
      ? selectedDate.setDate(selectedDate.getDate() - 1)
      : selectedDate.setDate(selectedDate.getDate() + 1);
  }

  return selectedDate;
};

export const checkGoToDatePossible = (
  startDate: Date,
  endDate: Date,
  selectedDate: Date,
  displayMode: "year" | "month" | "day",
  mode: "previous" | "next",
) => {
  const currentYear = getYear(selectedDate);
  const currentMonth = getMonth(selectedDate);
  const currentDay = getDay(selectedDate);

  // StartDate 보다 더 이전 날짜를 지정할 경우
  if (mode === "previous") {
    if (displayMode === "year") {
      return currentYear >= getYear(startDate);
    } else if (displayMode === "month") {
      if (currentYear === getYear(startDate)) {
        return currentMonth >= getMonth(startDate);
      }
      return currentYear >= getYear(startDate);
    } else {
      if (
        currentYear === getYear(startDate) &&
        currentMonth === getMonth(startDate)
      ) {
        return currentDay >= getDay(startDate);
      }

      return true;
    }
  }

  // StartDate 보다 더 빠른 날짜를 지정할 경우
  if (mode === "next") {
    if (displayMode === "year") {
      return currentYear <= getYear(endDate);
    } else if (displayMode === "month") {
      if (currentYear === getYear(endDate)) {
        return currentMonth <= getMonth(endDate);
      }

      return currentYear <= getYear(endDate);
    } else {
      if (
        currentYear === getYear(endDate) &&
        currentMonth === getMonth(endDate)
      ) {
        return currentDay <= getDay(endDate);
      }

      return true;
    }
  }
};

export const getDisabledMoveButton = (
  mode: "next" | "previous",
  displayMode: "year" | "month" | "day",
  currentDate: Date,
  filteredDate: Date,
) => {
  const currentYear = getYear(currentDate);
  const currentMonth = getMonth(currentDate);
  const currentDay = getDay(currentDate);

  if (mode === "previous") {
    if (displayMode === "year") {
      return currentYear <= getYear(filteredDate);
    } else if (displayMode === "month") {
      if (currentYear === getYear(filteredDate)) {
        return currentMonth <= getMonth(filteredDate);
      }
      return currentYear <= getYear(filteredDate);
    } else {
      if (
        currentYear === getYear(filteredDate) &&
        currentMonth === getMonth(filteredDate)
      ) {
        return currentDay <= getDay(filteredDate);
      }

      return false;
    }
  }

  if (mode === "next") {
    if (displayMode === "year") {
      return currentYear >= getYear(filteredDate);
    } else if (displayMode === "month") {
      if (currentYear === getYear(filteredDate)) {
        return currentMonth >= getMonth(filteredDate);
      }

      return currentYear >= getYear(filteredDate);
    } else {
      if (
        currentYear === getYear(filteredDate) &&
        currentMonth === getMonth(filteredDate)
      ) {
        return currentDay >= getDay(filteredDate);
      }

      return false;
    }
  }

  return false;
};
