import { Outlet } from "react-router-dom";
import React from "react";
import { Container, ToastProvider, ConfirmModalProvider } from "@earlybird/ui";

export const PublicLayout: React.FC = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default PublicLayout;
