import * as yup from "yup";

export const deliveryAccountSchema = yup.object().shape({
  deliveryAccount: yup.object({
    id: yup.string(),
    password: yup.string(),
  }),
});

export const deliveryAgencySchema = yup.object().shape({
  deliveryAgencyBrand: yup.string(),
  deliveryAgencyContact: yup.string().min(6, ""),
});

export const depositAccountSchema = yup.object().shape({
  depositBank: yup.string(),
  depositAccount: yup.string().min(10, ""),
});

export const withdrawalAccountSchema = yup.object().shape({
  withdrawalAccount: yup.string().min(10, ""),
});

export const businessLicenseSchema = yup.object().shape({
  address: yup.string(),
  detailAddress: yup.string(),
  postCode: yup.string(),
});
