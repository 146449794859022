import { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./ChatButton.module.scss";
import { ChatButtonProps } from "./ChatButton.types";
import "@ui/styles/index.scss";
import { Box, Icon, Text } from "@ui/components/atoms";
import { HeadphonesIcon } from "@earlybird/icons";
import { openChannelIOChat } from "@earlypay/shared/configs";

/**
 * `ChatButton` 는 다양한 레이아웃과 스타일 속성을 쉽게 적용하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <ChatButton
 *   title={"문의하기"}
 * />
 * ```
 */
export const ChatButton = forwardRef<HTMLElement, ChatButtonProps>(
  function ChatButton(
    {
      className,
      as,
      title = "상담문의",
      onClick = openChannelIOChat,
      ...rest
    }: ChatButtonProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "button";

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.ChatButton,
          "earlybird-chat-button",
          className,
        )}
        type={"button"}
        onClick={onClick}
      >
        <Icon icon={HeadphonesIcon} size={"lg"} />

        <Box
          className={classNames(styles.ChatButtonChip, "earlybird-chat-chip")}
          display={"flex"}
          center
        >
          <Text typo={"caption-2"} color={"content-on-color"} bold center>
            {title}
          </Text>
        </Box>
      </BaseComponent>
    );
  },
);

export default ChatButton;
