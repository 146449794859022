import {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import { LottieAnimationProps } from "./LottieAnimation.types";
import "@ui/styles/index.scss";
import Lottie, { LottieRefCurrentProps } from "lottie-light-react";
import { getS3TermsFile } from "@ui/components/atoms/LottieAnimation/utils";

/**
 * `LottieAnimation` 는 LottieAnimation 애니메이션을 보여주기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <LottieAnimation
 *   src={"warning"}
 *   loop={loop}
 * />
 * ```
 */
export const LottieAnimation = forwardRef<
  LottieRefCurrentProps,
  LottieAnimationProps
>(function LottieAnimation(
  {
    className,
    as,
    loop = false,
    autoplay = true,
    width,
    height,
    src,
    ...rest
  }: LottieAnimationProps,
  forwardedRef: MutableRefObject<LottieRefCurrentProps>,
) {
  const [animationData, setAnimationData] = useState(null);
  const lottieUrl = getS3TermsFile(`${src}`);
  const style = { width: width, height: height };

  const fetchAnimationData = useCallback(async () => {
    try {
      const response = await fetch(lottieUrl);
      const lottieData = await response.json();
      setAnimationData(lottieData);
    } catch (error) {
      console.error("Error fetching Lottie animation:", error);
    }
  }, [lottieUrl]);

  /** S3 버킷에서 Lottie .json 파일을 가져옵니다. */
  useEffect(() => {
    fetchAnimationData();
  }, [fetchAnimationData]);

  return (
    <Lottie
      lottieRef={forwardedRef}
      className={classNames("earlybird-lottie-animation", className)}
      animationData={animationData}
      loop={loop}
      autoplay={autoplay}
      renderer={"svg"}
      style={style}
      {...rest}
    />
  );
});

export default LottieAnimation;
