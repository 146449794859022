import React from "react";
import Webcam from "react-webcam";

interface CameraViewProps {
  webcamRef: React.RefObject<Webcam>;
  audio?: boolean;
  width?: number;
  height?: number;
  screenshotFormat?: "image/jpeg" | "image/webp" | "image/png";
}

export const CameraView: React.FC<CameraViewProps> = ({
  webcamRef,
  audio = false,
  height,
  width,
  screenshotFormat = "image/jpeg",
}) => {
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment",
  };

  return (
    <Webcam
      ref={webcamRef}
      audio={audio}
      screenshotFormat={screenshotFormat}
      width={width}
      height={height}
      videoConstraints={videoConstraints}
      screenshotQuality={1}
      style={{
        objectFit: "cover",
        minHeight: height,
        minWidth: width,
      }}
    />
  );
};

export default CameraView;
